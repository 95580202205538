<lv-loader *ngIf="isLoading"></lv-loader>

<lv-flex-box
  direction="row"
  class="lv-settings">

  <lv-flex-box
    direction="column"
    class="lv-settings-menu">

    <lv-flex-box class="lv-settings-header">
      <p class="lv-settings-menu-header-content">SETTINGS</p>
    </lv-flex-box>
    
    <lv-flex-box
      direction="column"
      class="lv-settings-menu-content">

      <lv-flex-box 
        *ngFor="let menuItem of listOfMenuItems"
        class="lv-settings-menu-content-item"
        [attr.data-cy]="getDm(menuItem)"
        [class.lv-settings-menu-content-item-selected]="isItemSelected(menuItem)"
        (click)="selectItem(menuItem)">
        {{ menuItem }}
      </lv-flex-box>

    </lv-flex-box>
    <lv-flex-box class="lv-settings-footer lv-settings-menu-footer">
      <button kendoButton
        [primary]="true"
        lvId="exit-settings-button"
        data-cy="DM-4725"
        (click)="onExitSettings()">
        Exit Settings
      </button>
    </lv-flex-box>
  </lv-flex-box>

  <lv-flex-box class="lv-settings-data">
    <lv-flex-box class="lv-settings-header">
      <p class="lv-settings-menu-header-content">{{selectedMenuItemTitle}}</p>
    </lv-flex-box>

    <lv-flex-box
      *ngIf="!isAdditionalMenuVisible"
      class="lv-settings-data-content">
      
      <ng-template *ngIf="shouldDisplaySection('Convertible')"
        [ngTemplateOutlet]="convertible">
      </ng-template>

      <ng-template *ngIf="shouldDisplaySection('Equity')"
        [ngTemplateOutlet]="equity">
      </ng-template>

      <ng-template *ngIf="shouldDisplaySection('Bond')"
        [ngTemplateOutlet]="bond">
      </ng-template>

      <ng-template *ngIf="shouldDisplaySection('Equity Option')"
        [ngTemplateOutlet]="equityOption">
      </ng-template>

      <ng-template *ngIf="shouldDisplaySection('Valuation')"
        [ngTemplateOutlet]="valuation">
      </ng-template>
        
      <ng-template *ngIf="shouldDisplaySection('Notifications')"
        [ngTemplateOutlet]="notifications">
      </ng-template>

      <ng-template *ngIf="shouldDisplaySection('Environments')"
        [ngTemplateOutlet]="environments">
      </ng-template>

      <ng-template *ngIf="shouldDisplaySection('Asset Swap')"
        [ngTemplateOutlet]="assetSwap">
      </ng-template>

      <ng-template *ngIf="shouldDisplaySection('Other')"
        [ngTemplateOutlet]="other">
      </ng-template>

      <ng-template *ngIf="shouldDisplaySection('Leversys Data Syncing')"
        [ngTemplateOutlet]="leversysDataSyncing">
      </ng-template>

    </lv-flex-box>
    <lv-flex-box
      *ngIf="isAdditionalMenuVisible"
      class="lv-settings-menu-additional">

      <lv-flex-box
        class="lv-settings-menu-additional-header">
        Instrument Type
      </lv-flex-box>

      <lv-flex-box 
        *ngFor="let instrumentType of listOfInstruments"
        class="lv-settings-data-content-item"
        [attr.data-cy]="getAdditionalMenuDm(instrumentType)"
        [class.lv-settings-data-content-item-add]="isOddInstrumentElement(instrumentType)"
        [class.lv-settings-data-content-item-evan]="!isOddInstrumentElement(instrumentType)"
        (click)="selectItem(instrumentType)">
        {{ instrumentType }}
      </lv-flex-box>
     
    </lv-flex-box>

  </lv-flex-box>

</lv-flex-box>

<ng-template #convertible>
  <kendo-tabstrip #convertibleTabstrip
    class="lv-tabstrip user-settings-tabstrip"
    [keepTabContent]="true"
    (tabSelect)="onTabSelect($event)">

    <kendo-tabstrip-tab
      selected="true"
      title="Market Data"
      cssClass="DM-1245">
      <ng-template kendoTabContent>
        <lv-market-data-settings
          [instrumentType]="'ConvertibleBond'"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-market-data-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Terms"
      cssClass="DM-1266">
      <ng-template kendoTabContent>
        <lv-terms-settings
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-terms-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Model Customization"
      cssClass="DM-1253">
      <ng-template kendoTabContent>
        <lv-model-customization-settings
          data-cy="DM-1253"
          [instrumentType]="'ConvertibleBond'"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-model-customization-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Estimates"
      cssClass="DM-1254">
      <ng-template kendoTabContent>
        <lv-estimates-settings
          data-cy="DM-1254"
          [instrumentType]="'ConvertibleBond'"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-estimates-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="New Issues"
      cssClass="DM-1255">
      <ng-template kendoTabContent>
        <lv-new-issue-settings
          data-cy="DM-1255"
          [instrumentType]="'ConvertibleBond'"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-new-issue-settings>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab
      title="ISIN"
      cssClass="DM-1265">
      <ng-template kendoTabContent>
        <lv-instrument-source-settings
          data-cy="DM-1265"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">>
        </lv-instrument-source-settings>
      </ng-template>
      </kendo-tabstrip-tab>
  </kendo-tabstrip>
</ng-template>

<ng-template #equity>
  <kendo-tabstrip #equityTabstrip
    class="lv-tabstrip"
    [keepTabContent]="true"
    (tabSelect)="onTabSelect($event)">

    <kendo-tabstrip-tab
      selected="true"
      title="Terms"
      cssClass="DM-4798">
      <ng-template kendoTabContent>
        <lv-equity-terms-settings
          data-cy="DM-4798"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-equity-terms-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Volatility"
      cssClass="DM-4799">
      <ng-template kendoTabContent>
        <lv-equity-volatility-settings
          data-cy="DM-4799"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-equity-volatility-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Dividends"
      cssClass="DM-4800">
      <ng-template kendoTabContent>
        <lv-equity-dividends-settings
          data-cy="DM-4800"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-equity-dividends-settings>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</ng-template>
<ng-template #bond>
  <kendo-tabstrip #bondTabstrip
    class="lv-tabstrip user-settings-tabstrip user-bond-tabstrip"
    [keepTabContent]="true"
    (tabSelect)="onTabSelect($event)">

    <kendo-tabstrip-tab
      selected="true"
      title="Market Data"
      cssClass="DM-4729">
      <ng-template kendoTabContent>
        <lv-market-data-bond-settings
          data-cy="DM-4729"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-market-data-bond-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Terms"
      cssClass="DM-4730">
      <ng-template kendoTabContent>
        <lv-bond-settings
        data-cy="DM-4730"
        (startLoading)="startLoading()"
        (endLoading)="endLoading()"
        (didCancel)="doCancel()">
      </lv-bond-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Model Customization"
      cssClass="DM-4731">
      <ng-template kendoTabContent>
        <lv-model-customization-bond-settings
          data-cy="DM-4731"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-model-customization-bond-settings>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</ng-template>
<ng-template #assetSwap>
  <kendo-tabstrip #assetSwapTabstrip
    class="lv-tabstrip"
    [keepTabContent]="true"
    (tabSelect)="onTabSelect($event)">

    <kendo-tabstrip-tab
      selected="true"
      title="Terms"
      cssClass="DM-4907">
      <ng-template kendoTabContent>
        <lv-asset-swap-settings 
        data-cy="DM-4907"
        (startLoading)="startLoading()"
        (endLoading)="endLoading()"
        (didCancel)="doCancel()">  
        </lv-asset-swap-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Market Data"
      cssClass="DM-1245">
      <ng-template kendoTabContent>
        <lv-market-data-settings
          [instrumentType]="'ASW'"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-market-data-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
    title="Model Customization"
    cssClass="DM-1253">
    <ng-template kendoTabContent>
      <lv-model-customization-settings
        data-cy="DM-1253"
        [instrumentType]="'ASW'"
        (startLoading)="startLoading()"
        (endLoading)="endLoading()"
        (didCancel)="doCancel()">
      </lv-model-customization-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
      title="Estimates"
      cssClass="DM-1254">
      <ng-template kendoTabContent>
        <lv-estimates-settings
          data-cy="DM-1254"
          [instrumentType]="'ASW'"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-estimates-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="New Issues"
      cssClass="DM-1255">
      <ng-template kendoTabContent>
        <lv-new-issue-settings
          data-cy="DM-1255"
          [instrumentType]="'ASW'"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-new-issue-settings>
      </ng-template>
    </kendo-tabstrip-tab>

  </kendo-tabstrip>
</ng-template>

<ng-template #equityOption>
  <kendo-tabstrip #equityOptionTabstrip
    class="lv-tabstrip user-settings-tabstrip user-bond-tabstrip"
    [keepTabContent]="true"
    (tabSelect)="onTabSelect($event)">

    <kendo-tabstrip-tab
      selected="true"
      title="Terms"
      cssClass="DM-6084">
      <ng-template kendoTabContent>
        <lv-equity-option-terms-settings
          data-cy="DM-6084"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-equity-option-terms-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Market Data"
      cssClass="DM-5117">
      <ng-template kendoTabContent>
        <lv-equity-option-market-data-settings
          data-cy="DM-5117"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-equity-option-market-data-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Model Customization"
      cssClass="DM-5118">
      <ng-template kendoTabContent>
        <lv-equity-option-model-customization-settings
          data-cy="DM-5118"
          (startLoading)="startLoading()"
          (endLoading)="endLoading()"
          (didCancel)="doCancel()">
        </lv-equity-option-model-customization-settings>
      </ng-template>
    </kendo-tabstrip-tab>
    
  </kendo-tabstrip>
</ng-template>

<ng-template #valuation>
  <lv-valuation-settings
    (startLoading)="startLoading()"
    (endLoading)="endLoading()"
    (didCancel)="doCancel()">
  </lv-valuation-settings>
</ng-template>

<ng-template #notifications>
  <lv-notifications-settings
    (didCancel)="doCancel()">
  </lv-notifications-settings>
</ng-template>

<ng-template #environments>
  <lv-environments-settings
    data-cy="DM-1276"
    (didCancel)="doCancel()">
  </lv-environments-settings>
</ng-template>

<ng-template #other>
  <lv-other-settings
    data-cy="DM-1259"
    (startLoading)="startLoading()"
    (endLoading)="endLoading()"
    (didCancel)="doCancel()">
  </lv-other-settings>
</ng-template>

<ng-template #leversysDataSyncing>
  <lv-leversys-data-syncing-settings
    data-cy="DM-4789"
    (startLoading)="startLoading()"
    (endLoading)="endLoading()"
    (didCancel)="doCancel()">
  </lv-leversys-data-syncing-settings>
</ng-template>
