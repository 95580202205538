import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IInterestRatesSectionSettings, IInterestRatesSettings } from '@lv-analytics/models';
import { IEquityOptionBorrowSectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-borrow-settings/market-data-equity-option-borrow-section-settings';
import { IEquityOptionBorrowSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-borrow-settings/market-data-equity-option-borrow-settings';
import { IEquityOptionCreditSectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-credit-settings/market-data-equity-option-credit-section-settings';
import { IEquityOptionCreditSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-credit-settings/market-data-equity-option-credit-settings';
import { IEquityOptionDividendsSectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-dividend-settings/market-data-equity-option-dividends-section-settings';
import { IEquityOptionDividendsSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-dividend-settings/market-data-equity-option-dividends-settings';
import { IMarketDataEquityOptionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-settings';
import { IEquityOptionVolatilitySectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-volatility-settings/market-data-equity-option-volatility-section-settings';
import { IEquityOptionVolatilitySettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-volatility-settings/market-data-equtiy-option-volatility-settings';
import { ISystemYieldCurvesSettingsView } from '@lv-analytics/models/market-data-settings/interest-rates/system-yield-curves-settings-view';
import { CompanyAndUserSettingsService } from '@lv-analytics/services/company-settings/company-settings.service';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import _ from 'lodash';

@Component({
  selector: 'lv-equity-option-market-data-settings',
  templateUrl: './lv-equity-option-market-data-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class LvEquityOptionMarketDataSettingsComponent implements OnInit, IDiscardable {
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  settings: IMarketDataEquityOptionSettings;
  originalSettings: IMarketDataEquityOptionSettings;
  systemYieldCurvesSettingsData: ISystemYieldCurvesSettingsView;

  constructor(private _companyAndUserSettingsService: CompanyAndUserSettingsService,
              private _errorService: LvErrorService,
              private _changeDetectorRef: ChangeDetectorRef,
              private _discardChangesService: DiscardChangesService) {
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      creditSettings: {
        systemDefaults: {} as IEquityOptionCreditSectionSettings,
        environments: []
      } as IEquityOptionCreditSettings,
      volatilitySettings: {
        systemDefaults: {} as IEquityOptionVolatilitySectionSettings,
        environments: []
      } as IEquityOptionVolatilitySettings,
      borrowSettings: {
        systemDefaults: {} as IEquityOptionBorrowSectionSettings,
        environments: []
      } as IEquityOptionBorrowSettings,
      dividendsSettings: {
        systemDefaults: {} as IEquityOptionDividendsSectionSettings,
        environments: []
      } as IEquityOptionDividendsSettings,
      interestRatesSettings: {
        systemDefaults: {
          yieldCurveSettings: []
        } as IInterestRatesSectionSettings,
        environments: []
      } as IInterestRatesSettings,
    } as IMarketDataEquityOptionSettings;

    this.originalSettings = _.cloneDeep(this.settings);
    this._discardChangesService.register('MarketData', this);
  }

  ngOnInit(): void {
    this.getMarketDataEquityOptionSettings();
  }

    /**
   * Get Market Data Equity Option Settings
   */
    async getMarketDataEquityOptionSettings () {
      try {
        this.startLoading.next();
        this.settings = await this._companyAndUserSettingsService.getMarketDataEquityOptionSettings();

        this.originalSettings = _.cloneDeep(this.settings);
        this.systemYieldCurvesSettingsData = await this._companyAndUserSettingsService.getYieldCurveSettingsData();
      }
      catch (error) {
        this._errorService.handleError(error);
      }
      finally {
        this.endLoading.next();
        this._changeDetectorRef.detectChanges();
      }
    }

  @HostBinding('class.lv-equity-option-market-data-setting')
  get isLvMarketDataEquityOptionSettingsComponent () {
    return true;
  }

  /**
   * 
   * @returns Method checks if any changes in state happened.
   */
  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  /**
   * Method discards current changes in state that are not saved.
   */
  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.next();
  }

   /**
   * Occurs on save and saves term settings.
   */
   async onSave() {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next()
     
      await this._companyAndUserSettingsService.saveMarketDataEquityOptionSettings(this.settings);
      this.originalSettings = _.cloneDeep(this.settings);

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
        {'settings_section': 'Market Data'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Resets equity settings to default.
   */
  async onResetToDefault() {
    try {
      this.startLoading.next();
      this.settings = await this._companyAndUserSettingsService.resetMarketDataEquityOptionSettings();

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
      {'settings_section': 'Market Data'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }
}
