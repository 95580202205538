import { v4 } from 'uuid';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalStorage } from '../util/local-storage';
import { LvAuthenticationError, LvConnectionError } from '../models/lv-error/auth';
import { ITokenResponse } from '../models/token-response';

export const defaultHeaders: {
  [header: string]: string | string[];
} =  {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '-1',
  'Content-Type': 'application/json',
  'X-Application-Id': environment.applicationId,
  'X-Request-Id': v4()
};

export const refreshAccessToken = async (httpClient: HttpClient): Promise<ITokenResponse> => {
  let tokenResponse = LocalStorage.getTokenResponse();
  let refreshTokenError = null;

  // Fix for open instrument from excel issue
  // https://leversys.atlassian.net/browse/SYSTEM-5304
  // In some cases after expired session exception, when user 
  // tries to do open instrument from excel, after successful login
  // needs some additional time to populate token response in
  // local storage.
  if (!tokenResponse) {
    await delay(300, tokenResponse);
  }

  if (LocalStorage.isAccessTokenExpired()) {
    try {
      tokenResponse = await httpClient.get<ITokenResponse>(`${environment.refreshTokenUrl}`, {
        headers: defaultHeaders,
        params: {
          refreshToken: tokenResponse.refreshToken
        },
        withCredentials: true
      })
      .pipe(
        timeout(environment.requestTimeout)
      )
      .toPromise();
    }
    catch (error) {
      const e: HttpErrorResponse = error;
      refreshTokenError = e.status === 401 ? new LvAuthenticationError() : new LvConnectionError();
    }

    if (!refreshTokenError) {
      LocalStorage.setTokenResponse(tokenResponse);
    }
    else {
      throw refreshTokenError;
    }
  }

  return tokenResponse;
};

async function delay(ms: number, tokenResponse: ITokenResponse): Promise<void> {
  return new Promise(() => setTimeout(() => {
    tokenResponse = LocalStorage.getTokenResponse();
  }, ms));
}