import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { LvConvertibleBondTermsError } from "@lv-convertible-bond/models";
import { HttpClientBase } from "@lv-core-ui/api";
import { LvDateService } from "@lv-core-ui/services";
import { IEquityOptionTermsSettings } from "src/app/modules/instrument/equity-option-terms/models/equity-option-terms-settings";

@Injectable({
    providedIn: 'root'
  })
  export class EquityOptionSettingsService extends HttpClientBase {

    constructor(
        @Inject(HttpClient) http: HttpClient,
        lvDateService: LvDateService,
      ) {
        super(lvDateService, http, '/instruments/equityOptionTermsSettings');
      }

  /**
   * Gets terms settings.
   * @returns IEquityOptionTermsSettings object.
   */
  async getEquityOptionTermsSettings(): Promise<IEquityOptionTermsSettings> {
    try {
        const result = await this.getAsync<IEquityOptionTermsSettings>(null, '');

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
   * Resets terms settings.
   * @returns IEquityOptionTermsSectionSettings object.
   */
  async resetEquityOptionTermsSettings(): Promise<IEquityOptionTermsSettings> {
      try {
          const result = await this.getAsync<IEquityOptionTermsSettings>(null, '/default');
          return result;
      }
      catch (error) {
        throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
      }
  }

  /**
   * Saves terms settings.
   * @param request IEquityOptionTermsSectionSettings object.
   */
  async saveEquityOptionTermsSettings(request: IEquityOptionTermsSettings): Promise<void> {
    try {
      await this.postAsync(request, '/save');
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }
}
