<lv-flex-box 
  direction="row"
  data-cy="DM-1137">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="volatilitySettingsTooltipTemplate"
    filter="div">
    Volatility
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="volatility"
      [format]="numberPctFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="settings.volatility"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>

<lv-flex-box 
  direction="row"
  data-cy="DM-1138">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="interestRatesSettingsTooltipTemplate"
    filter="div">
    Interest Rates
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="interestRates"
      [format]="numberFormat"
      [decimals]="0"
      [(ngModel)]="settings.interestRates"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">bps</span>
  </div>
</lv-flex-box>

<lv-flex-box 
  direction="row"
  data-cy="DM-1139">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="creditSpreadSettingsTooltipTemplate"
    filter="div">
    Credit Spread
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="creditSpread"
      [format]="numberFormat"
      [decimals]="0"
      [(ngModel)]="settings.creditSpread"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">bps</span>
  </div>
</lv-flex-box>

<lv-flex-box 
  direction="row"
  data-cy="DM-1140">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="stockBorrowSettingsTooltipTemplate"
    filter="div">
    Stock Borrow
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="stockBorrow"
      [format]="numberFormat"
      [decimals]="0"
      [(ngModel)]="settings.stockBorrow"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">bps</span>
  </div>
</lv-flex-box>

<lv-flex-box 
  direction="row"
  data-cy="DM-1141">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="dividendSettingsTooltipTemplate"
    filter="div">
    Dividend
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="dividend"
      [format]="numberPctFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="settings.dividend"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>

<lv-flex-box 
  direction="row"
  data-cy="DM-1142">
  
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="recoveryRateSettingsTooltipTemplate"
    filter="div">
    Recovery Rate
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="recoveryRate"
      [format]="numberPctFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="settings.recoveryRate"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>

<ng-template #volatilitySettingsTooltipTemplate>
  <lv-tooltip dm="dM-1137"></lv-tooltip>
</ng-template>
<ng-template #interestRatesSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1138"></lv-tooltip>
</ng-template>
<ng-template #creditSpreadSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1139"></lv-tooltip>
</ng-template>
<ng-template #stockBorrowSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1140"></lv-tooltip>
</ng-template>
<ng-template #dividendSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1141"></lv-tooltip>
</ng-template>
<ng-template #recoveryRateSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1142"></lv-tooltip>
</ng-template>