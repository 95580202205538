import { ChangeDetectionStrategy, Component, ViewEncapsulation, Input, OnInit, HostBinding } from '@angular/core';
import { IBaseEquityOptionTermsSettings } from '../../../models/base-equity-option-terms-settings';
import { LvLookupEnum } from '@lv-core-ui/util';
import { EquityOptionTypeDescription } from '../../../models/enums/equity-option-type';
import { EquityOptionBarrierType } from '../../../models/enums/equity-option-barrier-type';
import { Currency } from '@lv-instrument-common/index';
import { EquityOptionExerciseType } from '../../../models/enums/equity-option-exercise-type';
import { EquityOptionBarrierObservationType } from '../../../models/enums/equity-option-barrier-observation-type';
import { EquityOptionLowerBarrierDirection } from '../../../models/enums/equity-option-lower-barrier-direction';
import { EquityOptionPaymentTimeDescription } from '../../../models/enums/equity-option-payment-time';
import { EquityOptionCallPut } from '../../../models/enums/equity-option-call-put';
import { EquityOptionBarrierStatus } from '../../../models/enums/equity-option-barrier-status';
import { IEquityOptionTermsGeneralSectionSettings } from '../../../models/equity-option-terms-general-section-settings';

@Component({
  selector: 'lv-equity-option-general-section-settings',
  templateUrl: './lv-equity-option-general-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityOptionGeneralSectionSettingsComponent {
  @Input() settings: IBaseEquityOptionTermsSettings<IEquityOptionTermsGeneralSectionSettings>
  @Input() overrideDefaults: boolean;

  formatZero = 'n0';
  decimalsZero = '0';
  decimalsTwo = '2';
  formatTwo = '#,###.##';
  decimalsFour = '4';
  formatFour = '#,###.####';

  optionTypeLookup: LvLookupEnum;
  optionBarrierTypeLookup: LvLookupEnum;
  ccyLookup: LvLookupEnum;
  callPutLookup: LvLookupEnum;
  underlyingCcyLookup: LvLookupEnum;
  exerciseTypeLookup: LvLookupEnum;
  barrierObservationTypeLookup: LvLookupEnum;
  barrierStatusLookup: LvLookupEnum;
  lowerBarrierDirectionLookup: LvLookupEnum;
  paymentTimeLookup: LvLookupEnum;

  constructor() {
    this.overrideDefaults = true;
    this.optionTypeLookup = new LvLookupEnum(EquityOptionTypeDescription);
    this.optionBarrierTypeLookup = new LvLookupEnum(EquityOptionBarrierType);
    this.ccyLookup = new LvLookupEnum(Currency);
    this.callPutLookup = new LvLookupEnum(EquityOptionCallPut);
    this.underlyingCcyLookup = new LvLookupEnum(Currency);
    this.exerciseTypeLookup = new LvLookupEnum(EquityOptionExerciseType);
    this.barrierObservationTypeLookup = new LvLookupEnum(EquityOptionBarrierObservationType);
    this.lowerBarrierDirectionLookup  = new LvLookupEnum(EquityOptionLowerBarrierDirection);
    this.barrierStatusLookup = new LvLookupEnum(EquityOptionBarrierStatus);
    this.paymentTimeLookup = new LvLookupEnum(EquityOptionPaymentTimeDescription);
  }
  @HostBinding('class.lv-equity-option-terms-section-settings')
  get isLvEquityOptionTermsSectionSettingsComponent() {
    return true;
  }
}
