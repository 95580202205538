import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvPrivateInstrumentsError, ICreateDraftRequest, IUpdateDraftRequest, CustomInstrument,
         ICalculateYieldAndRedemptionRequest } from '@lv-custom-instruments/models';
import { ILvError, LvDataMaster, LvErrorType } from '@lv-core-ui/models';
import { ISaveInstrumentResponse } from '@lv-custom-instruments/models/responses/saveInstrumentResponse';
import { LvDateService } from '@lv-core-ui/services';
import { Identifiers } from '@lv-instrument-common/Identifiers';

/**
 * Custom instrument service contains methods for managing custom instruments.
 */
@Injectable()
export class CustomInstrumentsService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/instruments');
  }

  /**
   * Copies from existing instrument.
   * @param instrumentIdentifier Instrument identifier.
   * @param isLeversysConvertible A flag indicating if convertible is Leversys.
   * @param isUserAccessScope A flag indicating whether the user's access scope.
   * @returns ISaveInstrumentResponse object.
   */
  async copyFromExistingInstrument(instrumentIdentifier: string,
                                   isLeversysConvertible: boolean,
                                   isUserAccessScope: boolean): Promise<ISaveInstrumentResponse> {
    try {
      const result = await this.postAsync<ISaveInstrumentResponse>({}, '/privateInstrument/copyInstrument', {
        instrumentId: instrumentIdentifier,
        isLeversysConvertible: isLeversysConvertible,
        isUserAccessScope: isUserAccessScope
      });

      if (!result) {
        throw new LvPrivateInstrumentsError(LvDataMaster.getError('dM-1917'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }

  }

  /**
   * Copies the instrument.
   * @param instrumentIdentifier Instrument identifier.
   * @param isLeversysConvertible A flag indicating if convertible is Leversys.
   * @param isUserAccessScope A flag indicating whether the user's access scope.
   * @returns ISaveInstrumentResponse object.
   */
  async copyInstrument(instrumentIdentifier: string,
                       isLeversysConvertible: boolean,
                       isUserAccessScope: boolean): Promise<ISaveInstrumentResponse> {
    try {
      const result = await this.postAsync<ISaveInstrumentResponse>({}, '/privateInstrument/exactCopyInstrument', {
        instrumentId: instrumentIdentifier,
        isLeversysConvertible: isLeversysConvertible,
        isUserAccessScope: isUserAccessScope
      });

      if (!result) {
        throw new LvPrivateInstrumentsError(LvDataMaster.getError('dM-1917'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }

  }

  /**
   * Creates draft.
   * @param request ICreateDraftRequest object.
   * @returns Draft ID.
   */
  async createDraft(request: ICreateDraftRequest): Promise<string> {
    try {
      return await this.postAsyncWihTextResponseType(request, '/privateInstrument/createDraft');
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Updates draft.
   * @param request IUpdateDraftRequest object.
   */
  async updateDraft(request: IUpdateDraftRequest): Promise<void> {
    try {
      return await this.postAsync(request, '/privateInstrument/updateDraft');
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Gets terms detailed from LVS instrument.
   * @param leversysId Leversys Identifier.
   * @returns Custom instrument object.
   */
  async getConvertibleBondByLeversysId(leversysId: string): Promise<CustomInstrument>  {
    try {
      const result = await this.getAsync<CustomInstrument>({
        leversysId: leversysId,      
      }, '/convertibleBond/getConvertibleBond');

      if (!result) {
        throw new LvPrivateInstrumentsError(LvDataMaster.getError('dM-3025'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }
  
  /**
   * Gets terms detailed from LVS instrument.
   * @param leversysId Leversys Identifier.
   * @returns List of identifiers.
   */
  async getConvertibleBondIdentifiersByLeversysId(leversysId: string): Promise<Identifiers[]>  {
    try {
      const result = await this.getAsync<Identifiers[]>({
        leversysId: leversysId,      
      }, '/convertibleBond/getConvertibleBondIdentifiers');

      if (!result) {
        throw new LvPrivateInstrumentsError(LvDataMaster.getError('dM-3025'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Deletes custom instrument.
   * @param draftId Draft ID.
   */
  async deletePrivateInstrument(draftId: string): Promise<void> {
    try {
      await this.postAsync<any>(null, `/privateInstrument/delete/${draftId}`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Clears draft.
   * @param privateInstrumentId Custom instrument ID.
   * @param sessionId Session ID.
   */
  async clearDraft(privateInstrumentId: string, sessionId: string): Promise<void> {
    try {
      await this.postAsync<void>({}, '/privateInstrument/clearDraft', {
        privateInstrumentId: privateInstrumentId,
        sessionId: sessionId,
      });
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Sets yield from redemption.
   * @param request ICalculateYieldAndRedemptionRequest object.
   * @returns Yield issue.
   */
   async setYieldFromRedemption(request: ICalculateYieldAndRedemptionRequest): Promise<number> {
    try {
      return await this.postAsync<number>(request, '/cbFunction/yieldFromRedemption');
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Sets redemption from yield.
   * @param request ICalculateYieldAndRedemptionRequest object.
   * @returns Redemption value.
   */
  async setRedemptionFromYield(request: ICalculateYieldAndRedemptionRequest): Promise<number> {
    try {
      return await this.postAsync<number>(request, '/cbFunction/redemptionFromYield');
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Saves terms detailed.
   * @param request CustomInstrument object.
   * @returns ISaveInstrumentResponse object.
   */
  async saveTermsDetailed(request: CustomInstrument): Promise<string> {
    try {
      const id = await this.postAsyncWihTextResponseType(request, '/convertibleBond/save');
      return id;
    } catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Saves terms detailed as copy.
   * @param leversysLocalId Leversys identifier.
   * @param isUserAccessScope Is user access scope.
   * @param request CustomInstrument object.
   * @returns ISaveInstrumentResponse object.
   */
  async saveTermsDetailedAsCopy(
    leversysLocalId: string,
    isUserAccessScope: boolean,
    request: CustomInstrument): Promise<string> {
    try {
      const id = await this.postAsyncWihTextResponseType(
        request,
        '/convertibleBond/saveAsCopy',
      {
        leversysLocalId: leversysLocalId,
        isUserAccessScope: isUserAccessScope,
      });
      return id;
    } catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }
  
  /**
   * Saves terms detailed as copy.
   * @param request CustomInstrument object.
   * @returns ISaveInstrumentResponse object.
   */
  async copyTermsDetailed(request: CustomInstrument): Promise<ISaveInstrumentResponse> {
    try {
      const id = await this.postAsync<ISaveInstrumentResponse>(null,
        '/convertibleBond/copy',
        {
          leversysLocalId: request.leversysId,
          isFixedDatesSelected: !request.privateInstrumentDocument.fixDatesInQuickTermsEntry,
          name: request.privateInstrumentDocument.fullTerms.issueAndRedemption.name,
        });
      return id;
    } catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Handles unauthorized error.
   * @param e ILvError object.
   * @returns LvPrivateInstrumentsError object.
   */
  private handleUnauthorizedError(e: ILvError): LvPrivateInstrumentsError {
    const error = new LvPrivateInstrumentsError(e.message);

    if (e.message === 'Unauthorized Access') {
      error.type = LvErrorType.AUTHORIZATION;
    }

    return error;
  }
}
