import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TabStripModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';
import { LvTermsGeneralComponent, LvConvertibleBondTermsComponent, LvAccretionComponent, LvPutsComponent, LvPutsPutParametersComponent,
         LvPutsPutScheduleComponent, LvMakeWholeComponent, LvMakeWholeCallParametersComponent, LvMakeWholeConversionParametersComponent,
         LvMakeWholeRatchetMatrixComponent, LvTermsGeneralNotesComponent,
         LvTermsGeneralFxInformationComponent, LvTermsGeneralSinkingScheduleComponent, LvCouponComponent, LvCoPayCouponComponent,
         LvFloatingCouponComponent, LvPikCouponComponent, LvCustomCouponComponent, LvFixedCouponComponent, LvProjectedCouponsComponent,
         LvCallsComponent, LvConversionComponent, LvDividendProtectionComponent, LvResetConversionComponent,
         LvContingentConversionComponent, LvVariableConversionComponent, LvPepsConversionComponent, LvPercsConversionComponent,
         LvConversionScheduleComponent, LvExerciseScheduleComponent, LvFloatingSpreadScheduleComponent, LvIdentifiersComponent,
         LvFloatingFixingHistoryScheduleComponent, LvFloatingCustomDatesScheduleComponent, LvStepUpCouponScheduleComponent,
         LvPikCustomScheduleComponent, LvCoPayScheduleComponent, LvCallsScheduleComponent, LvTakeoverProtectionComponent,
         LvAveragingPeriodDetailsComponent, LvPremiumCompensationFormulaComponent, LvRatchetScheduleComponent,
         LvTakeoverPutParametersComponent, LvQuickTermsEntryComponent, LvDividendProtectionScheduleComponent } from './convertible-bond-terms/components';
import { ConvertibleBondTermsService } from './convertible-bond-terms/services';
// tslint:disable-next-line: max-line-length
import { LvTermsGeneralIssueInformationComponent } from './convertible-bond-terms/components/lv-convertible-bond-terms/lv-terms-general/lv-terms-general-issue-information/lv-terms-general-issue-information.component';
import { LvGeneralSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-general-settings/lv-general-settings.component';
import { LvCouponSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-coupon-settings/lv-coupon-settings.component';
import { LvAccreationSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-accreation-settings/lv-accreation-settings.component';
import { LvCallSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-call-settings/lv-call-settings.component';
import { LvPutSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-put-settings/lv-put-settings.component';
import { LvConversionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-conversion-settings/lv-conversion-settings.component';
// tslint:disable-next-line: max-line-length
import { LvDividendProtectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-dividend-protection-settings/lv-dividend-protection-settings.component';
import { LvMakeWholeSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-make-whole-settings/lv-make-whole-settings.component';
// tslint:disable-next-line: max-line-length
import { LvTakeoverProtectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-takeover-protection-settings/lv-takeover-protection-settings.component';
// tslint:disable-next-line: max-line-length
import { LvGeneralSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-general-settings/lv-general-section-settings/lv-general-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvTermsSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-terms-section-settings/lv-terms-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvSystemDefaultsSettingsDirective } from './convertible-bond-terms/components/lv-terms-settings/lv-terms-section-settings/directives/lv-system-defaults.directive';
// tslint:disable-next-line: max-line-length
import { LvMyDefaultsSettingsDirective } from './convertible-bond-terms/components/lv-terms-settings/lv-terms-section-settings/directives/lv-my-defaults.directive';
// tslint:disable-next-line: max-line-length
import { LvCouponSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-coupon-settings/lv-coupon-section-settings/lv-coupon-section-settings.component';
import { LvOtherComponent } from './convertible-bond-terms/components/lv-convertible-bond-terms/lv-other/lv-other.component';
import { LvEstimatesComponent } from './convertible-bond-terms/components/lv-convertible-bond-terms/lv-estimates/lv-estimates.component';
// tslint:disable-next-line: max-line-length
import { LvConversionRatioHistoryComponent } from './convertible-bond-terms/components/lv-convertible-bond-terms/lv-conversion/lv-conversion-ratio-history/lv-conversion-ratio-history.component';
// tslint:disable-next-line: max-line-length
import { LvCarryForwardHistoryComponent } from './convertible-bond-terms/components/lv-convertible-bond-terms/lv-conversion/lv-carry-forward-history/lv-carry-forward-history.component';
// tslint:disable-next-line: max-line-length
import { LvAccretionSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-accreation-settings/lv-accretion-section-settings/lv-accretion-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvCallSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-call-settings/lv-call-section-settings/lv-call-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvPutSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-put-settings/lv-put-section-settings/lv-put-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvConversionSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-conversion-settings/lv-conversion-section-settings/lv-conversion-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvDividendProtectionSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-dividend-protection-settings/lv-dividend-protection-section-settings/lv-dividend-protection-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvMakeWholeSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-make-whole-settings/lv-make-whole-section-settings/lv-make-whole-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvTakeoverProtectionSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-takeover-protection-settings/lv-takeover-protection-section-settings/lv-takeover-protection-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvCocoSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-conversion-settings/lv-conversion-section-settings/lv-coco-section-settings/lv-coco-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvResetSectionSettingsComponent } from './convertible-bond-terms/components/lv-terms-settings/lv-conversion-settings/lv-conversion-section-settings/lv-reset-section-settings/lv-reset-section-settings.component';
import { ExcelModule } from '@lv-excel/excel.module';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { LvBondAccretionComponent, LvBondCallsComponent, LvBondCallsScheduleComponent, LvBondCouponComponent, LvBondCustomCouponComponent, LvBondFixedCouponComponent, 
  LvBondFloatingCouponComponent, LvBondFloatingCustomDatesScheduleComponent, LvBondFloatingFixingHistoryScheduleComponent, LvBondFloatingSpreadScheduleComponent, LvBondGeneralComponent,
  LvBondGeneralSettingsComponent, LvBondGeneralSinkingScheduleComponent, LvBondIdentifiersComponent, LvBondMakeWholeComponent, LvBondPikCouponComponent, LvBondPikCustomScheduleComponent,
  LvBondPutsComponent, LvBondPutsScheduleComponent, LvBondStepUpCouponScheduleComponent, LvBondTermsComponent, LvBondGeneralSectionSettingsComponent } from './bond-terms/components';
import { BondTermsService } from './bond-terms/services/bond-terms.services';
import { LvBondCouponSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-coupon-settings/lv-bond-coupon-settings.component';
import { LvBondCouponSectionSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-coupon-settings/lv-bond-coupon-section-settings/lv-bond-coupon-section-settings.component';
import { LvBondAccretionSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-accretion-settings/lv-bond-accretion-settings.component';
import { LvBondAccretionSectionSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-accretion-settings/lv-bond-accretion-section-settings/lv-bond-accretion-section-settings.component';
import { LvBondCallSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-call-settings/lv-bond-call-settings.component';
import { LvBondCallSectionSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-call-settings/lv-bond-call-section-settings/lv-bond-call-section-settings.component';
import { LvBondPutSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-put-settings/lv-bond-put-settings.component';
import { LvBondPutSectionSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-put-settings/lv-bond-put-section-settings/lv-bond-put-section-settings.component';
import { LvBondMakeWholeSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-make-whole-settings/lv-bond-make-whole-settings.component';
import { LvBondMakeWholeSectionSettingsComponent } from './bond-terms/components/lv-bond-settings/lv-bond-make-whole-settings/lv-bond-make-whole-section-settings/lv-bond-make-whole-section-settings.component';
import { LvAssetSwapTermsSettingsComponent } from './asset-swap-terms/components/lv-asset-swap-settings/lv-asset-swap-terms-settings.component';
import { LvAssetSwapSectionTermsSettingsComponent } from './asset-swap-terms/components/lv-asset-swap-settings/lv-asset-swap-section-settings/lv-asset-swap-terms-section-settings.component';
import { LvEquityTermsSectionSettingsComponent } from './equity/components/lv-equity-settings/lv-equity-terms-section-settings/lv-equity-terms-section-settings.component';
import { LvTermsEquitySettingsComponent } from './equity/components/lv-equity-settings/lv-terms-equity-settings/lv-terms-equity-settings.component';
import { LvEquityTermsComponent } from './equity/components/lv-equity-terms/lv-equity-terms/lv-equity-terms.component';
import { LvEquityIdentifiersComponent } from './equity/components/lv-equity-terms/lv-equity-identifiers/lv-equity-identifiers.component';
import { AssetSwapTermsComponent } from './asset-swap-terms/components/lv-asset-swap-terms/lv-asw-terms/lv-asw-terms.component';
import { LvAswIdentifiersComponent } from './asset-swap-terms/components/lv-asset-swap-terms/lv-asw-indentifiers/lv-asw-identifiers.component';
import { LvAswConversionComponent } from './asset-swap-terms/components/lv-asset-swap-terms/lv-asw-terms/lv-asw-conversion/lv-asw-conversion.component';
import { LvAswReferenceIrSwapComponent } from './asset-swap-terms/components/lv-asset-swap-terms/lv-asw-terms/lv-asw-reference-ir-swap/lv-asw-reference-ir-swap.component';
import { LvAswFloatingLegScheduleComponent } from './asset-swap-terms/components/lv-asset-swap-terms/lv-asw-terms/lv-asw-floating-leg-schedule/lv-asw-floating-leg-schedule.component';
import { LvEquitySidePanelModalComponent } from './equity/components/lv-equity-terms/lv-equity-side-panel-modal/lv-equity-side-panel-modal.component';
import { LvEquityOptionGeneralSectionSettingsComponent } from './equity-option-terms/components/lv-equity-option-settings/lv-equity-option-general-section-settings/lv-equity-option-general-section-settings.component';
import { LvTermsEquityOptionGeneralSettingsComponent } from './equity-option-terms/components/lv-equity-option-settings/lv-equity-option-general-settings/lv-terms-equity-option-general-settings.component';
import { LvTermsEquityOptionDividendProtectionSettingsComponent } from './equity-option-terms/components/lv-equity-option-settings/lv-equity-option-dividend-protection-settings/lv-terms-equity-option-dividend-protection-settings.component';
import { LvEquityOptionDividendProtectionSectionSettingsComponent } from './equity-option-terms/components/lv-equity-option-settings/lv-equity-option-dividend-protection-section-settings/lv-terms-equity-option-dividend-protection-section-settings.component';

@NgModule({
  declarations: [
    LvTermsGeneralComponent,
    LvConvertibleBondTermsComponent,
    LvTermsGeneralComponent,
    LvAccretionComponent,
    LvPutsComponent,
    LvPutsPutParametersComponent,
    LvPutsPutScheduleComponent,
    LvMakeWholeComponent,
    LvMakeWholeCallParametersComponent,
    LvMakeWholeConversionParametersComponent,
    LvMakeWholeRatchetMatrixComponent,
    LvTermsGeneralIssueInformationComponent,
    LvTermsGeneralNotesComponent,
    LvTermsGeneralFxInformationComponent,
    LvTermsGeneralSinkingScheduleComponent,
    LvCouponComponent,
    LvCoPayCouponComponent,
    LvFloatingCouponComponent,
    LvPikCouponComponent,
    LvCustomCouponComponent,
    LvFixedCouponComponent,
    LvProjectedCouponsComponent,
    LvCallsComponent,
    LvConversionComponent,
    LvDividendProtectionComponent,
    LvResetConversionComponent,
    LvContingentConversionComponent,
    LvVariableConversionComponent,
    LvPepsConversionComponent,
    LvPercsConversionComponent,
    LvConversionScheduleComponent,
    LvExerciseScheduleComponent,
    LvFloatingSpreadScheduleComponent,
    LvFloatingFixingHistoryScheduleComponent,
    LvFloatingCustomDatesScheduleComponent,
    LvStepUpCouponScheduleComponent,
    LvPikCustomScheduleComponent,
    LvCoPayScheduleComponent,
    LvCallsScheduleComponent,
    LvTakeoverProtectionComponent,
    LvAveragingPeriodDetailsComponent,
    LvPremiumCompensationFormulaComponent,
    LvRatchetScheduleComponent,
    LvTakeoverPutParametersComponent,
    LvQuickTermsEntryComponent,
    LvDividendProtectionScheduleComponent,
    LvIdentifiersComponent,
    LvGeneralSettingsComponent,
    LvCouponSettingsComponent,
    LvAccreationSettingsComponent,
    LvCallSettingsComponent,
    LvPutSettingsComponent,
    LvConversionSettingsComponent,
    LvDividendProtectionSettingsComponent,
    LvMakeWholeSettingsComponent,
    LvTakeoverProtectionSettingsComponent,
    LvGeneralSectionSettingsComponent,
    LvTermsSectionSettingsComponent,
    LvSystemDefaultsSettingsDirective,
    LvMyDefaultsSettingsDirective,
    LvCouponSectionSettingsComponent,
    LvOtherComponent,
    LvEstimatesComponent,
    LvConversionRatioHistoryComponent,
    LvCarryForwardHistoryComponent,
    LvAccretionSectionSettingsComponent,
    LvCallSectionSettingsComponent,
    LvPutSectionSettingsComponent,
    LvConversionSectionSettingsComponent,
    LvDividendProtectionSectionSettingsComponent,
    LvMakeWholeSectionSettingsComponent,
    LvTakeoverProtectionSectionSettingsComponent,
    LvCocoSectionSettingsComponent,
    LvResetSectionSettingsComponent,
    LvEquityTermsSectionSettingsComponent,
    LvTermsEquitySettingsComponent,

    LvBondTermsComponent,
    LvBondGeneralComponent,
    LvBondCouponComponent,
    LvBondGeneralSinkingScheduleComponent,
    LvBondAccretionComponent,
    LvBondCallsComponent,
    LvBondCallsScheduleComponent,
    LvBondPutsComponent,
    LvBondMakeWholeComponent,
    LvBondFixedCouponComponent,
    LvBondStepUpCouponScheduleComponent,
    LvBondCustomCouponComponent,
    LvBondFloatingCouponComponent,
    LvBondFloatingFixingHistoryScheduleComponent,
    LvBondFloatingCustomDatesScheduleComponent,
    LvBondFloatingSpreadScheduleComponent,
    LvBondPikCouponComponent,
    LvBondPikCustomScheduleComponent,
    LvBondIdentifiersComponent,
    LvBondPutsScheduleComponent,
    LvBondGeneralSettingsComponent,
    LvBondGeneralSectionSettingsComponent,
    LvBondCouponSettingsComponent,
    LvBondCouponSectionSettingsComponent,
    LvBondAccretionSettingsComponent,
    LvBondAccretionSectionSettingsComponent,
    LvBondCallSettingsComponent,
    LvBondCallSectionSettingsComponent,
    LvBondPutSettingsComponent,
    LvBondPutSectionSettingsComponent,
    LvBondMakeWholeSettingsComponent,
    LvBondMakeWholeSectionSettingsComponent,
    LvAssetSwapSectionTermsSettingsComponent,
    LvAssetSwapTermsSettingsComponent,
    LvEquityTermsComponent,
    LvEquityIdentifiersComponent,
    AssetSwapTermsComponent,
    LvAswIdentifiersComponent,
    LvAswConversionComponent,
    LvAswReferenceIrSwapComponent,
    LvAswFloatingLegScheduleComponent,
    LvEquitySidePanelModalComponent,
    LvTermsEquityOptionGeneralSettingsComponent,
    LvEquityTermsSectionSettingsComponent,
    LvEquityOptionGeneralSectionSettingsComponent,
    LvTermsEquityOptionDividendProtectionSettingsComponent,
    LvEquityOptionDividendProtectionSectionSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    // Kendo Modules
    TabStripModule,
    DropDownsModule,
    GridModule,
    DateInputsModule,
    ButtonsModule,
    TooltipModule,
    ExcelModule,
    LabelModule,
    PopupModule,
    InputsModule,
    DatePickerModule,
    DialogModule,
    SortableModule,
    // Core UI
    LeversysCoreUIModule
  ],
  exports: [
    LvConvertibleBondTermsComponent,
    LvGeneralSettingsComponent,
    LvCouponSettingsComponent,
    LvAccreationSettingsComponent,
    LvCallSettingsComponent,
    LvPutSettingsComponent,
    LvConversionSettingsComponent,
    LvDividendProtectionSettingsComponent,
    LvMakeWholeSettingsComponent,
    LvTakeoverProtectionSettingsComponent,
    LvGeneralSectionSettingsComponent,
    LvTermsSectionSettingsComponent,
    LvSystemDefaultsSettingsDirective,
    LvMyDefaultsSettingsDirective,
    LvCouponSectionSettingsComponent,
    LvEquityTermsSectionSettingsComponent,
    LvTermsEquitySettingsComponent,
    LvBondTermsComponent,
    LvBondGeneralSettingsComponent,
    LvBondCouponSettingsComponent,
    LvBondAccretionSettingsComponent,
    LvBondCallSettingsComponent,
    LvBondPutSettingsComponent,
    LvBondMakeWholeSettingsComponent,
    LvAssetSwapSectionTermsSettingsComponent,
    LvAssetSwapTermsSettingsComponent,
    LvEquityTermsComponent,
    LvEquityIdentifiersComponent,
    AssetSwapTermsComponent,
    LvAswIdentifiersComponent,
    LvTermsEquityOptionGeneralSettingsComponent,
    LvEquityTermsSectionSettingsComponent,
    LvEquityOptionGeneralSectionSettingsComponent,
    LvTermsEquityOptionDividendProtectionSettingsComponent,
    LvEquityOptionDividendProtectionSectionSettingsComponent
  ],
  providers: [
    ConvertibleBondTermsService,
    BondTermsService
  ]
})
export class ConvertibleBondTermsModule { }
