export enum EquityOptionBarrierType {
    None = 'None',
    KI = 'KI',
    KO = 'KO',
    DKI = 'DKI',
    DKO = 'DKO',
    OT = 'OT',
    NT = 'NT',
    DT = 'DT',
    DNT = 'DNT'
}