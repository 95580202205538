import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  EventEmitter, Output, Input, ViewChild, OnChanges, ChangeDetectorRef, OnDestroy, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { constants, LvDateUtil, LvUtil } from '@lv-core-ui/util';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { GridContextMenuItem, IGridContextMenuSelectEvent } from '@lv-core-ui/directives';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { CustomMakeWholeData } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';
import { RatchetMatrixOfferValueType, TakeoverProtectionRatchetMatrixItem } from '@lv-instrument-common/index';

@Component({
  selector: 'lv-make-whole-ratchet-matrix',
  templateUrl: './lv-make-whole-ratchet-matrix.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvMakeWholeRatchetMatrixComponent implements OnInit, OnChanges, OnDestroy {
  @Output() didSetRatchetMatrix: EventEmitter<TakeoverProtectionRatchetMatrixItem[]>;
  @Input() model: TakeoverProtectionRatchetMatrixItem[];
  @Input() customMakeWholeData: CustomMakeWholeData;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];
  private _formGroupSubscription: Subscription[];
  columns: LvAdvancedGridColumn[];
  parseFn: any;

  rows: any[];
  dataColumns: {
    columnId: string,
    offerValue: number;
    parityPrice: number;
  }[];

  createFormGroup: (args: CreateFormGroupArgs) => FormGroup;
  skipRecordOnDeleteFn: (record: any) => boolean;

  contextMenuItems: GridContextMenuItem[];
  excelFieldAlias = 'MW_RTCH_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _errorService: LvErrorService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this._formGroupSubscription = [];
    this.parseFn = this.parserFunction.bind(this);
    this.didSetRatchetMatrix = new EventEmitter<TakeoverProtectionRatchetMatrixItem[]>();

    this.createFormGroup = this.doCreateFormGroup.bind(this);

    const deleteColumn = new GridContextMenuItem();
    deleteColumn.actionId = 'delete-column';
    deleteColumn.actionText = 'Delete Column';
    deleteColumn.action = this.deleteColumn.bind(this);
    deleteColumn.actionVisible = (event: IGridContextMenuSelectEvent) => event.columnIndex !== 0 && this.model.length > 0;
    this.contextMenuItems = [deleteColumn];
  }

  ngOnInit() {
    this._modelSubscription = [
      this.advancedGrid.didDataChange.subscribe((records: any[]) => this.onScheduleChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];
  }

  ngOnChanges() {
     this.transformFromApi();
     this.initColumns();
     this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
    this._formGroupSubscription.forEach(s => s.unsubscribe());
  }

  initColumns() {
    const columns = [];

    const dateColumn = new LvAdvancedGridDateColumn();
    dateColumn.field = 'date';
    dateColumn.title = ' ';
    dateColumn.width = 104;
    dateColumn.isCellVisible = (rowIndex: number, columnIndex: number) => {
      if ((rowIndex === 0 && columnIndex === 0) || (rowIndex === 1 && columnIndex === 0)) {
        return false;
      }

      return true;
    };

    dateColumn.isCellPlaceholder = (rowIndex: number, columnIndex: number) => {
      if (rowIndex === 0 && columnIndex === 0) {
        if (this.customMakeWholeData) {
          return this.customMakeWholeData.offerValueType === RatchetMatrixOfferValueType.StockPrice ?
          'Stock Price (' + this.customMakeWholeData.currency + ')' : 'Parity (%)';
        }
        else {
          return 'Stock Price ()';
        }

      }
      if (rowIndex === 1 && columnIndex === 0) {
        if (this.customMakeWholeData) {
          return this.customMakeWholeData.offerValueType === RatchetMatrixOfferValueType.StockPrice ?
          'Parity (%)' : 'Stock Price (' + this.customMakeWholeData.currency + ')';
        }
        else {
          return 'Parity (%)';
        }

      }

      return null;
    };

    columns.push(dateColumn);

    if (this.dataColumns[0].columnId !== 'id_0_empty' && this.dataColumns[this.dataColumns.length - 1].offerValue) {
      const cId = `id_${this.dataColumns.length}_empty`;
      this.dataColumns.push({
        columnId: cId,
        offerValue: null,
        parityPrice: null
      });
    }

    this.dataColumns.forEach(dc => columns.push(this.createNumericColumn(dc.columnId)));

    this.columns = columns;
    this._changeDetectorRef.detectChanges();
  }

  private deleteColumn(event: IGridContextMenuSelectEvent) {
    const keys = Object.keys(this.rows[0]);
    const key = keys[event.columnIndex - 1];

    if (keys.length > 1) {
      this.rows.map(x => delete x[key]);
      // this.rows.map(x => (x[key] = null));
    } else {
      this.rows = [];
    }

    this.onScheduleChange(this.rows);
  }

  private parserFunction(pastedDataRecords: string[]): any[] {

    const stockPrices = pastedDataRecords[0].split('\t');
    const scheduleWithoutHeader = pastedDataRecords.filter((x, i) => i !== 0);

    this.dataColumns = (stockPrices as Array<any>)
      .map((a, i) => {
        return {
          columnId: `id_${i}_${Math.round(a)}`,
          offerValue: MarketDataClipboard.parseNumberValue(a),
          parityPrice: 0
        };
      });

    let rowsDict = {};
    const rowsBody = {};
    const offerColumnsDict = {};
    const parityColumnsDict = {};

    this.dataColumns.forEach(c => {
      if (!offerColumnsDict[c.columnId]) {
        offerColumnsDict[c.columnId] = c.offerValue;
      }

      if (!parityColumnsDict[c.columnId]) {
        parityColumnsDict[c.columnId] = c.parityPrice;
      }
    });

    const ratchetMatrixItemFields = Object.keys(offerColumnsDict);

    scheduleWithoutHeader.forEach(x => {
      const items = x.split('\t');
      rowsDict = {};
      rowsDict['date'] = MarketDataClipboard.parseDateValue(items[0], '');

      ratchetMatrixItemFields.forEach((f, i) => {
        rowsDict[f] = MarketDataClipboard.parseNumberValue(items[i + 1]);
      });

      rowsBody[items[0]] = rowsDict;

    });

    this.rows = [ {...offerColumnsDict }, { ...parityColumnsDict }];
    this.rows = this.rows.concat(Object.keys(rowsBody).map(a => rowsBody[a]));
    this.rows = this.calculateParity(this.rows, Object.keys(this.rows[0]));

    this.onScheduleChange(this.rows);
    return this.rows;
  }

  doCreateFormGroup(args: CreateFormGroupArgs): FormGroup {
    const columnFormInputs = {};
    this.dataColumns.forEach(dc => {
      columnFormInputs[dc.columnId] = new FormControl(args.dataItem[dc.columnId]);
    });

    if (args.dataItem._recordId_) {
      return new FormGroup({
        'date': new FormControl(args.isNew ? new Date() : args.dataItem.date),
        ...columnFormInputs
      });
    } else {
      return new FormGroup({
        'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required),
        ...columnFormInputs
      });
    }


  }

  private createNumericColumn(field: string): LvAdvancedGridNumericColumn {
    const column = new LvAdvancedGridNumericColumn();
    column.field = field;
    column.title = ' ';
    column.width = 90;
    column.format = '#,###.####';
    column.decimals = '4';
    column.outputFormat = constants.numberFormat.upToFourDigits;

    return column;
  }

  private transformFromApi() {
    const groupByDateDict = {};
    let firstDate = null;
    this.rows = [];

    if (this.model && this.model.length > 0) {
      Array.from(this.model).forEach((dbItem: any) => {
        if (!firstDate) {
          firstDate = dbItem.startDate;
        }
        if (!groupByDateDict[dbItem.startDate]) {
          groupByDateDict[dbItem.startDate] = [];
        }

        groupByDateDict[dbItem.startDate].push(dbItem);
      });

      const firstItem = groupByDateDict[firstDate];
      this.dataColumns = (firstItem as Array<any>)
        .map((a, i) => {
          return {
            columnId: `id_${i}_${Math.round(a.offerValue)}`,
            offerValue: a.offerValue,
            parityPrice: a.parityPrice
          };
        });

      this.dataColumns = this.dataColumns.filter(x => x.offerValue).sort(LvUtil.sortBy('offerValue', false));

      const rowsDict = {};
      const offerColumnsDict = {};
      const parityColumnsDict = {};
      this.dataColumns.forEach(c => {
        if (!offerColumnsDict[c.columnId]) {
          offerColumnsDict[c.columnId] = c.offerValue;
        }

        if (!parityColumnsDict[c.columnId]) {
          parityColumnsDict[c.columnId] = c.parityPrice;
        }

        Object.keys(groupByDateDict).forEach(key => {
          if (!rowsDict[key]) {
            rowsDict[key] = {
              date: new Date(key)
            };
          }
          const record = rowsDict[key];

          groupByDateDict[key]
            .forEach((b, i) => {
              const cId = `id_${i}_${Math.round(b.offerValue)}`;

              if (c.columnId === cId) {
                record[cId] = b.compensationValue;
              }
            });
        });
      });

      this.rows = [ {...offerColumnsDict }, { ...parityColumnsDict }];
      this.rows = this.rows.concat(Object.keys(rowsDict).map(a => rowsDict[a]));
      this.rows = this.calculateParity(this.rows, Object.keys(this.rows[0]));
    } else {

      this.dataColumns = [];
      this.dataColumns = [
        {
          columnId: 'id_0_empty',
          offerValue: null,
          parityPrice: null
        }
      ];

      const offerColumnsDict = {};
      const parityColumnsDict = {};
      this.dataColumns.forEach(c => {
        if (!offerColumnsDict[c.columnId]) {
          offerColumnsDict[c.columnId] = c.offerValue;
        }

        if (!parityColumnsDict[c.columnId]) {
          parityColumnsDict[c.columnId] = c.parityPrice;
        }
      });

      this.rows = [ {...offerColumnsDict }, { ...parityColumnsDict }];
    }
  }

  private transformToApi(schedule: any): TakeoverProtectionRatchetMatrixItem[] {
    const ratchetMatrix = [];
    const stockPrices = schedule[0];
    const parityPrices = schedule[1];
    const ratchetMatrixItemFields = Object.keys(stockPrices);
    const withoutArgumentsRows = schedule.filter(x => x !== stockPrices && x !== parityPrices);

    withoutArgumentsRows.forEach(x => {
      ratchetMatrixItemFields.filter(y => y !== 'date').forEach(f => {
        ratchetMatrix.push({
          startDate: new Date(x.date),
          offerValue: stockPrices[f],
          parityPrice: parityPrices[f],
          compensationValue: x[f]
        });
      });
    });

    return ratchetMatrix;
  }

  private calculateParity(schedule: any, fields: string[]): any {
    const stockPrices = schedule[0];
    fields.forEach(f => {
      if (stockPrices[f] > 0) {
        schedule[1][f] = this.getParity(stockPrices[f]);
      }
    });

    return schedule;
  }

  private onScheduleChange(scheduleItems: any[]) {
    if (scheduleItems.length > 0) {
      const stockPrices = scheduleItems[0];
      const ratchetMatrixItemFields = Object.keys(stockPrices);

      // calculate Parity
      scheduleItems = this.calculateParity(scheduleItems, ratchetMatrixItemFields);

      // add potential column
      const lastColumnId = this.advancedGrid.columns[this.advancedGrid.columns.length - 1].field;
      if (stockPrices && stockPrices[lastColumnId]) {
        const cId = `id_${this.advancedGrid.columns.length - 1}_empty`;
        this.dataColumns.push({
          columnId: cId,
          offerValue: null,
          parityPrice: null
        });

        this.columns.push(this.createNumericColumn(cId));
      }
      this.model.splice(0, this.model.length);
      this.model.push(...this.transformToApi(scheduleItems));

      const isValid = this.isValidateSchedule(scheduleItems);
      if (isValid) {
        if (this.model && this.model.length > 0) {
          this.transformFromApi();
          this.initColumns();
          this.didSetRatchetMatrix.emit(this.model);
          this._changeDetectorRef.detectChanges();
        }
      } else {
          this._errorService.toastrService.warning('Please first set Stock Price!');
          if (this.model[0].offerValue) {
            this.transformFromApi();
            this.initColumns();
          } else {
            this.model.splice(0, this.model.length);
            this.didSetRatchetMatrix.emit(this.model);
          }
        }
    } else {
      this.model.splice(0, this.model.length);
      this.transformFromApi();
      this.initColumns();
      this.didSetRatchetMatrix.emit(this.model);
      this._changeDetectorRef.detectChanges();
    }
  }

  private isValidateSchedule(schedule: any): boolean {
    let isValid = true;
    const stockPrices = schedule[0];
    const parityPrices = schedule[1];
    const stockPricesItemFields = Object.keys(stockPrices);
    const withoutArgumentsRows = schedule.filter(x => x !== stockPrices && x !== parityPrices);

    withoutArgumentsRows.forEach(x => {
      const itemKeys = Object.keys(x);
      const lastKey = itemKeys[itemKeys.length - 1];
      if ((stockPricesItemFields.length === 1 && !stockPrices[stockPricesItemFields[0]]) ||
          (lastKey !== 'id_0_empty' && lastKey.includes('_empty') && x[lastKey])) {
        isValid = false;
      }
    });

    return isValid;
  }

  private getParity(offerValue: number): number {
    let factor;
    if (this.customMakeWholeData.offerValueType === RatchetMatrixOfferValueType.StockPrice) {
      // tslint:disable-next-line:max-line-length
      factor = this.customMakeWholeData.nominal !== 0 ? (100 * offerValue * this.customMakeWholeData.conversionRatio) / this.customMakeWholeData.nominal : 0;
    } else {
      // tslint:disable-next-line:max-line-length
      factor = this.customMakeWholeData.conversionRatio !== 0 ? offerValue * this.customMakeWholeData.nominal / (100 * this.customMakeWholeData.conversionRatio) : 0;
    }

    return factor;
  }

  private onScheduleReload() {
    this.onScheduleChange(this.rows);
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }
}
