<lv-flex-box direction="row" class="lv-equity-option-terms-settings-column">
    <lv-flex-box class="">
        <lv-flex-box data-cy="DM-5132" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="optionType"
                 filter="span">
                <span> Option Type </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-equity-option-type"
                    class="lv-terms-general-small-input"
                    [data]="optionTypeLookup.items"
                    [(ngModel)]="settings.settings.optionType"
                    [disabled]="!overrideDefaults"
                    name="option-type"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 120 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>
  
        <lv-flex-box data-cy="DM-5133" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field lv-equity-option-input"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="optionBarrierType"
                 filter="span">
                <span> Option Barrier Type </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-equity-option-barrrier-type"
                    class="lv-terms-general-small-input"
                    [data]="optionBarrierTypeLookup.items"
                    [(ngModel)]="settings.settings.optionBarrierType"
                    [disabled]="!overrideDefaults"
                    name="option-barrier-type"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-1517" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="ccy"
                 filter="span">
                <span> CCY </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-ccy"
                    class="lv-terms-general-small-input"
                    [data]="ccyLookup.items"
                    [(ngModel)]="settings.settings.currencyCode"
                    [disabled]="!overrideDefaults"
                    name="ccy"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>
  
        <lv-flex-box data-cy="DM-1518" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="underlyingCcy"
                 filter="span">
                <span> Underlying CCY </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-underlying-ccy"
                    class="lv-terms-general-small-input"
                    [data]="underlyingCcyLookup.items"
                    [(ngModel)]="settings.settings.underlyingCurrencyCode"
                    [disabled]="!overrideDefaults"
                    name="underlying-ccy"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-5134" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="callPut"
                 filter="span">
                <span> Call / Put </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-equity-option-call-put"
                    class="lv-terms-general-small-input"
                    [data]="callPutLookup.items"
                    [(ngModel)]="settings.settings.callPut"
                    [disabled]="!overrideDefaults"
                    name="call-put"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 160 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>
  
        <lv-flex-box data-cy="DM-5135" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="exerciseType"
                 filter="span">
                <span> Exercise Type </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-equity-option-exercise-type"
                    class="lv-terms-general-small-input"
                    [data]="exerciseTypeLookup.items"
                    [(ngModel)]="settings.settings.exerciseType"
                    [disabled]="!overrideDefaults"
                    name="exercise-type"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 160 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-1530" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="daysToSettle"
                 filter="span">
                <span> Days to Settle </span>
            </div>
            <div class="lv-input-field">
                <lv-numeric-text-box
                    [format]="formatFour"
                    [decimals]="decimalsFour"
                    [disabled]="!overrideDefaults"
                    [(ngModel)]="settings.settings.daysToSettle"
                    name="days-to-settle">
                </lv-numeric-text-box>
            </div>
        </lv-flex-box>
  
        <div class="lv-label-field lv-equity-option-field lv-label lv-label--title lv-equity-option-section-title">
            BARRIERS
        </div>
  
        <lv-flex-box data-cy="DM-5138" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="barrierObservationType"
                 filter="span">
                <span> Barrier Observation Type </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-equity-option-barrier-observation-type"
                    class="lv-terms-general-small-input"
                    [data]="barrierObservationTypeLookup.items"
                    [(ngModel)]="settings.settings.barrierObservationType"
                    [disabled]="!overrideDefaults"
                    name="barrier-observation-type"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-5139" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="barrierStatus"
                 filter="span">
                <span> Barrier Status </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-equity-option-barrier-status"
                    class="lv-terms-general-small-input"
                    [data]="barrierStatusLookup.items"
                    [(ngModel)]="settings.settings.barrierStatus"
                    [disabled]="!overrideDefaults"
                    name="barrier-status"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-5140" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="lowerBarrierDirection"
                 filter="span">
                <span> First Barrier Direction </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-equity-option-lower-barrier-direction"
                    class="lv-terms-general-small-input"
                    [data]="lowerBarrierDirectionLookup.items"
                    [(ngModel)]="settings.settings.firstBarrierDirection"
                    [disabled]="!overrideDefaults"
                    name="lower-burrier-direction"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-5141" direction="row">
            <div class="lv-label lv-label-field lv-equity-option-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="paymentTime"
                 filter="span">
                <span> Payment Time </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-equity-option-payment-time"
                    class="lv-terms-general-small-input"
                    [data]="paymentTimeLookup.items"
                    [(ngModel)]="settings.settings.paymentTime"
                    [disabled]="!overrideDefaults"
                    name="payment-time"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>
    </lv-flex-box>
</lv-flex-box>

  
<lv-flex-box class="lv-equity-option-terms-settings-column lv-equity-option-terms-settigs-second-column">
    <div class="lv-label-field lv-equity-option-field lv-label lv-label--title lv-equity-option-title">
        WARRANT PARAMETERS
    </div>

    <lv-flex-box
    class="lv-input-field"
    direction="row"
    data-cy="DM-5142">

        <div class="lv-label lv-label-field lv-equity-option-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="callable"
        filter="div">
            Callable
        </div>
        <div class="lv-field lv-field--checkbox">
            <input type="checkbox"
            class="k-checkbox"
            name="equity-option-callable"
            [(ngModel)]="settings.settings.callable"
            [disabled]="!overrideDefaults"/>
            <label class="k-checkbox-label"></label>
        </div>
    </lv-flex-box>
</lv-flex-box>

  
  <ng-template #optionType>
    <lv-tooltip dm="dM-5132"></lv-tooltip>
  </ng-template>
  <ng-template #optionBarrierType>
    <lv-tooltip dm="dM-5133"></lv-tooltip>
  </ng-template>
  <ng-template #callPut>
    <lv-tooltip dm="dM-5134"></lv-tooltip>
  </ng-template>
  <ng-template #ccy>
    <lv-tooltip dm="dM-1517"></lv-tooltip>
  </ng-template>
  <ng-template #underlyingCcy>
    <lv-tooltip dm="dM-1518"></lv-tooltip>
  </ng-template>
  <ng-template #exerciseType>
    <lv-tooltip dm="dM-5135"></lv-tooltip>
  </ng-template>
  <ng-template #daysToSettle>
    <lv-tooltip dm="dM-1530"></lv-tooltip>
  </ng-template>
  <ng-template #barrierObservationType>
    <lv-tooltip dm="dM-5138"></lv-tooltip>
  </ng-template>
  <ng-template #barrierStatus>
    <lv-tooltip dm="dM-5139"></lv-tooltip>
  </ng-template>
  <ng-template #lowerBarrierDirection>
    <lv-tooltip dm="dM-5140"></lv-tooltip>
  </ng-template>
  <ng-template #paymentTime>
    <lv-tooltip dm="dM-5141"></lv-tooltip>
  </ng-template>
  <ng-template #callable>
    <lv-tooltip dm="dM-5142"></lv-tooltip>
  </ng-template>