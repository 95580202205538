import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LvConvertibleBondTermsError } from '@lv-convertible-bond/models';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvDateService } from '@lv-core-ui/services';
import { IEquityTermsSectionSettings } from 'src/app/modules/instrument/equity/models/equity-terms-settings';

@Injectable({
  providedIn: 'root'
})
export class EquityTermsSettingsService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
  ) {
    super(lvDateService, http, '/instruments/equityTermsSettings');
  }

  /**
   * Get equity terms settings.
   * @returns IEquityTermsSectionSettings object.
   */
  async getEquityTermsSettings(): Promise<IEquityTermsSectionSettings> {
    try {
      return await this.getAsync<IEquityTermsSectionSettings>(null, '');
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
   * Resets equity terms settings.
   * @returns IEquityTermsSectionSettings object.
   */
  async resetEquityTermsSettings(): Promise<IEquityTermsSectionSettings> {
    try {
      return await this.getAsync<IEquityTermsSectionSettings>(null, '/default');
    }
    catch (error) {
      this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
   * Saves equity terms settings.
   * @param request IEquityTermsSectionSettings object.
   */
  async saveEquityTermsSettings(request: IEquityTermsSectionSettings): Promise<void> {
    try {
      await this.postAsync(request, '/save');
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }
}
