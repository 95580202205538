import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IEquityOptionCreditSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-credit-settings/market-data-equity-option-credit-settings';

@Component({
  selector: 'lv-equity-option-credit-settings',
  templateUrl: './lv-equity-option-credit-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityOptionCreditSettingsComponent {
  @Input() settings: IEquityOptionCreditSettings;

  @HostBinding('class.lv-credit-settings')
  get isLvEquityOptionCreditSettingsComponent () {
    return true;
  }
}