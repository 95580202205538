import { constants, LvDateUtil} from '@lv-core-ui/util';
import { ConvertibleHelper } from '@lv-analytics/helpers';
import { IValuationInputs, IPricing, IMarketDataBase, ICrEstimateSettings, IConvertible, IDividends, DividendTypeEnum, IBorrow, ICredit,
         IVolatility, VolatilityType, DividendsSourceEnum, BorrowSource, CreditSource } from '@lv-analytics/models';
import { IValuationSettings } from '@lv-analytics/models/valuation-session/valuation-settings';
import { LvExcelService } from '@lv-excel/services';
import { LvDateService } from '@lv-core-ui/services';
import { IOtherMarketData } from '@lv-analytics/models/market-data/other/other-market-data';
import { DatePipe, DecimalPipe } from '@angular/common';
import { AccretionType } from '@lv-instrument-common/index';

export type ValuationInputsFields = {
  [K in keyof IValuationInputs]: boolean;
};

export type ValuationInputFieldPlaceholders = {
  [K in keyof IValuationInputs]: string;
};

export const getDefaultValuationInputs = (settings?: IPricing): IValuationInputs => {
  const model = {
    valuationDate: new Date()
  } as IValuationInputs;

  if (settings) {
    Object.assign(model, settings);
  }

  return model;
};

export const getDefaultMarketDataBase = (settings?: IValuationSettings): IMarketDataBase => {
  const model = {} as IMarketDataBase;

  if (settings && settings.marketData) {
    Object.assign(model, settings.marketData);
  }

  return model;
};

/**
 * Valuation inputs view.
 */
export class LvValuationInputsView {

  get hasConvertible(): boolean {
    return !!this._cHelper.convertible;
  }

  get isCrEffectiveDateInFutureAndIsLessThanValuationDate(): boolean {
    if (!this._cHelper.convertible || !this.model) {
      return false;
    }

    return this._cHelper.isCrEffectiveDateInFutureAndIsLessThanValuationDate(this.model.valuationDate);
  }

  get expectedRatioValue(): string {
    if (!this._cHelper.convertible) {
      return null;
    }
    return this._decimalPipe.transform(this._cHelper.convertible.conversionRatio);
  }

  get expectedCrEffectiveDateValue(): string {
    if (!this._cHelper.convertible) {
      return '';
    }
    return this._datePipe.transform(this._cHelper.convertible.expectedCrEffectiveDate);
  }

  get isNewIssue(): boolean {
    return this._cHelper.isNewIssue;
  }

  // Form fields
  model: IValuationInputs;
  // Referent market data
  marketData: IMarketDataBase;

  // Model fields labels
  priceSuffix: string;
  stockPriceCbCcySuffix: string;
  stockPriceUndCcySuffix: string;

  minValuationDate: Date;
  // Model fields visibility
  modelFields: ValuationInputsFields;

  // Placeholders
  modelFieldsPlaceholders: ValuationInputFieldPlaceholders;

  includeCashRebateInParity: boolean;

  private _cHelper: ConvertibleHelper;
  private _creSettings: ICrEstimateSettings;

  constructor(
    private _decimalPipe: DecimalPipe,
    private _datePipe: DatePipe,
    private _lvDateService: LvDateService,
    private _excelService: LvExcelService
    ) {
    this._cHelper = new ConvertibleHelper(_lvDateService);
    this.minValuationDate = new Date();
    this.includeCashRebateInParity = false;
    this.init();
  }

  /**
   * initializes all parameters given bellow with values from AnalyticsSettingsHelper
   * @param valuationSettings- valuation settings
   * @param pricingSettings - pricing settings
   * @param convertible - convertible
   * @param cpeSettings - conversion ratio estimate settings
   * @param inclCashRebateInParity - flag for Incl Cash Rebate In Parity checkbox in MC
   */
  init(valuationSettings?: IValuationSettings,
       pricingSettings?: IPricing,
       convertible?: IConvertible,
       cpeSettings?: ICrEstimateSettings,
       inclCashRebateInParity?: boolean
       ) {
    this.model = getDefaultValuationInputs(pricingSettings);
    if (!this.marketData) {
      this.marketData = getDefaultMarketDataBase(valuationSettings);
    }

    this.mappFlatMarketDataIfExistsFromExcel();

    this.updateConvertible(convertible);

    this.setModelFieldsVisibility();
    this.setModelFieldsPlaceholders();

    this._creSettings = cpeSettings;
    this.includeCashRebateInParity = inclCashRebateInParity;
    this.recalculateParity();
    this.recalculateParityIfExpectedCRFromExcel();
  }

  /**
   * Updates valuation inputs model.
   * @param model IValuationInputs object.
   */
  update(model: IValuationInputs) {
    if (!(model.valuationDate instanceof Date)) {
      model.valuationDate = new Date(model.valuationDate);
    }

    this.model = Object.assign({}, model);
  }

  /**
   * Reset valuation date to settlement date if it is older.
   */
  resetValuatonDateToSettlementDateIfOlder() {
    if (this.model.valuationDate < this._cHelper.firstSettlementDate) {
      this.model.valuationDate = this._cHelper.firstSettlementDate;
    }
  }

  /**
   * Updates convertible.
   * @param convertible IConvertible object.
   */
  updateConvertible(convertible?: IConvertible) {
    this._cHelper.init(convertible);

    this.stockPriceUndCcySuffix = this._cHelper.underlyingCurrencyCode;
    this.stockPriceCbCcySuffix = this._cHelper.currencyCode;
    this.priceSuffix = this._cHelper.isPriceAsPar ? 'pts' : this._cHelper.currencyCode;

    const firstSettlementDate = new Date(this._cHelper.firstSettlementDate);
    this.minValuationDate = firstSettlementDate;
    // needed to reset minValuationDate hours since there was issue with typing in date manualy
    // kendo datepicker by default sets time to be 0 and validation never passed
    this.minValuationDate.setHours(0, 0, 0, 0);
    this.resetValuatonDateToSettlementDateIfOlder();

    if (this._cHelper.isCrossFx) {
      this.onCrossFxChange();
    } else if (this.model.stockPriceCbCcy) {
      this.onStockPriceChange(true);
    }
  }

  /**
   * Occurs on parity change and calculates stock price and stock price underlying currency for detachable warrants or regular CB.
   */
  onParityChange() {
    if (!this._cHelper.isValid) {
      return;
    }

    if (this._cHelper.isDetachableWarrant || this._cHelper.isDeltaNeutral) {
      this.calculateStockPriceUndCcyForDetachableWarrants();
    }
    else {
      this.calculateStockPriceUndCcyForRegularCb();
    }

    this.calculateStockPrice(false);
  }

  /**
   * Occurs on estimate ratio change.
   * @param crEstimateSettings ICrEstimateSettings object.
   */
  onEsimateRatioChanged(crEstimateSettings: ICrEstimateSettings) {
    this._creSettings = { ...crEstimateSettings };
    this.onStockPriceChange(true);
  }

  /**
   * Occurs on cross FX change.
   */
  onCrossFxChange() {
    this.onStockPriceOrCrossFxChange(false, true);
  }

  /**
   * Occurs on Stock price change.
   * @param isCbCurrency A flag indicating if Stock Price is changed, otherwise false.
   */
  onStockPriceChange(isCbCurrency: boolean) {
    this.onStockPriceOrCrossFxChange(isCbCurrency, false);
  }

  /**
   * Recalculates parity.
   */
  recalculateParity() {
    this.calculateParity();
  }
  /**
   * called whenever Stock Price or Cross Fx in Pricing are changed
   * @param isCbCurrency - flag- true if Stock Price is changed, otherwise false
   * @param isCrossFxChanged - flag- true if CrosFx is changed, oyherwise false
   * @param includeCashRebateInParity - flag for Incl Cash Rebate In Parity checkbox in MC
   */
  onStockPriceOrCrossFxChange(isCbCurrency: boolean, isCrossFxChanged: boolean): void {
    if (!this._cHelper.isValid) {
      return;
    }

    this.model.parity = null;

    if (isCrossFxChanged) {
      this.calculateStockPricesOnCrossFxChange();
    }
    else {
      this.calculateStockPrice(isCbCurrency);
    }
    this.calculateParity();
  }

  /**
   * method for calculating parity that takes into considiration Incl.Cash Rebate in parity flag in MC
   *  and checks if Cb is isDetachableWarrant or DeltaNeutral
   */
  calculateParity(): void {
    if ((!this._cHelper.isValid) || (this.model && (!this.model.stockPriceUndCcy || !this.model.crossFx))) {
      return;
    }
    if (this._cHelper.isDetachableWarrant || this._cHelper.isDeltaNeutral) {
      this.calculateParityForDetachableWarrantAndEquityNeutral();
    }
    else {
      this.calculateParityForRegularCbs(this.includeCashRebateInParity);
    }
  }

  /**
   * Handles dividends changes.
   * @param data IDividends object.
   */
  handleDividendsChanged(data: IDividends) {
    // tslint:disable-next-line: max-line-length
    this.marketData.flatDividendYield = data.dividendParameters  && data.dividendParameters.dividendType === DividendTypeEnum.DiscreteYield ?  data.dividendParameters.dividendValue : null;
    this.marketData.dividendSource = data.dividendsSource;
    this.setModelFieldsPlaceholders();
  }

  /**
   * Handles borrow changes.
   * @param data IBorrow object.
   */
  handleBorrowChanged(data: IBorrow) {
    this.marketData.borrowSource = data.borrowSource;
    this.marketData.flatBorrow = data.borrow;
    this.setModelFieldsPlaceholders();
  }

  /**
   * Handles credit changes.
   * @param data ICredit object.
   */
  handleCreditChanged(data: ICredit) {
    this.marketData.creditSource = data.creditSource;
    this.marketData.flatCreditSpread = data.issuerCreditParameters.flatCreditSpread;
    this.setModelFieldsPlaceholders();
  }

  /**
   * Handles volatility changes.
   * @param data IVolatility object.
   */
  handleVolatilityChanged(data: IVolatility) {
    this.marketData.volatilitySource = data.volType;
    this.marketData.flatVolatility = data.flatVol;
    this.marketData.upsideVol = data.upsideVol;
    this.marketData.downsideVol = data.downsideVol;
    this.setModelFieldsPlaceholders();
    this.setModelFieldsVisibility();
  }

  /**
   * Handles other market data changes.
   * @param data Other market data object.
   */
  handleOtherMarketDataChanged(data: IOtherMarketData) {
    this.marketData.stockSlippage = data.stockSlippage;
    this.setModelFieldsPlaceholders();
  }

  /**
   * Call Calculate Parity For Detachable and Equity Neutral
   */
  calculateParityForDeltaNeutral() {
    if (this._cHelper.isDetachableWarrant || this._cHelper.isDeltaNeutral) {
      this.calculateParityForDetachableWarrantAndEquityNeutral();
    }
  }

  /**
   * Recalculates parity if expected CR from Excel.
   */
  private recalculateParityIfExpectedCRFromExcel() {
    if (!!this._excelService?.isInitialized() && this._cHelper.convertible && this._cHelper.convertible.expectedCR) {
      if (this._cHelper.isDetachableWarrant || this._cHelper.isDeltaNeutral) {
        this.calculateParityForDetachableWarrantAndEquityNeutral();
      }
      else {
        this.calculateParityForRegularCbs();
      }
  }
  }

  /**
   * Sets model fields visibility.
   */
  private setModelFieldsVisibility() {
    if (!this.modelFields) {
      this.modelFields = {} as any;
    }

    this.modelFields.price = true;
    this.modelFields.stockPriceCbCcy = true;
    this.modelFields.valuationDate = true;
    this.modelFields.flatDividendYield = true;
    this.modelFields.flatBorrow = true;
    this.modelFields.flatCreditSpread = true;
    this.modelFields.flatInterestRate = true;

    // CrossFx
    this.modelFields.stockPriceUndCcy = this._cHelper.isCrossFx;
    this.modelFields.crossFx = this._cHelper.isCrossFx;

    // Parity
    this.modelFields.parity =  !(this._cHelper.isPeps || this._cHelper.isNewIssue);

    // Volatility
    this.modelFields.flatVolatility = !this._cHelper.isPeps || this.marketData.volatilitySource !== VolatilityType.UpsideDownside;
    this.modelFields.upsideVol = this._cHelper.isPeps && this.marketData.volatilitySource === VolatilityType.UpsideDownside;
    this.modelFields.downsideVol = this._cHelper.isPeps && this.marketData.volatilitySource === VolatilityType.UpsideDownside;
  }

  /**
   * Sets placeholder for input fields
   */
  private setModelFieldsPlaceholders() {
    if (!this.modelFieldsPlaceholders) {
      this.modelFieldsPlaceholders = {} as any;
    }

    if (this.marketData.dividendSource === DividendsSourceEnum.Parameters && this.marketData.flatDividendYield) {
      this.modelFieldsPlaceholders.flatDividendYield =
        this._decimalPipe.transform(this.marketData.flatDividendYield, constants.numberFormat.fourDigits);
    }
    else {
      this.modelFieldsPlaceholders.flatDividendYield = 'Discrete Yield';
    }

    if (this.marketData.borrowSource === BorrowSource.Flat && this.marketData.flatBorrow) {
      this.modelFieldsPlaceholders.flatBorrow =
        this._decimalPipe.transform(this.marketData.flatBorrow, constants.numberFormat.zeroDigits);
    }
    else {
      this.modelFieldsPlaceholders.flatBorrow = 'Flat Borrow';
    }

    if (this.marketData.volatilitySource === VolatilityType.Flat && this.marketData.flatVolatility) {
      this.modelFieldsPlaceholders.flatVolatility =
        this._decimalPipe.transform(this.marketData.flatVolatility, constants.numberFormat.twoDigits);
    }
    else {
      this.modelFieldsPlaceholders.flatVolatility = 'Flat Volatility';
    }

    if (this.marketData.volatilitySource === VolatilityType.UpsideDownside) {
      if (this.marketData.upsideVol) {
        this.modelFieldsPlaceholders.upsideVol =
          this._decimalPipe.transform(this.marketData.upsideVol, constants.numberFormat.fourDigits);
      }
      else {
        this.modelFieldsPlaceholders.upsideVol = 'Upside Vol';
      }
      if (this.marketData.downsideVol) {
        this.modelFieldsPlaceholders.downsideVol =
          this._decimalPipe.transform(this.marketData.downsideVol, constants.numberFormat.fourDigits);
      }
      else {
        this.modelFieldsPlaceholders.downsideVol = 'Downside Vol';
      }
    }

    if (this.marketData.creditSource === CreditSource.FlatSpread && this.marketData.flatCreditSpread) {
      this.modelFieldsPlaceholders.flatCreditSpread =
        this._decimalPipe.transform(this.marketData.flatCreditSpread, constants.numberFormat.zeroDigits);
    }
    else {
      this.modelFieldsPlaceholders.flatCreditSpread =  'Flat Spread';
    }

    if (!!this.marketData.stockSlippage) {
      this.modelFieldsPlaceholders.stockSlippage =
        this._decimalPipe.transform(this.marketData.stockSlippage, constants.numberFormat.fourDigits);
    }
    else {
      this.modelFieldsPlaceholders.stockSlippage =  'Stock Slippage';
    }
  }

  /**
   * Calculates stock price underlying CCY for regural CB.
   * @returns Stock price underlying CCY.
   */
  private calculateStockPriceUndCcyForRegularCb() {
    let factor = 1;

    const conversionRatio = this._cHelper.getConversionRatio(this._creSettings, this.model.valuationDate);
    let rebate = 0;

    if (this.includeCashRebateInParity) {
      if (!!this._creSettings?.useEstimatedRatio && !!this._cHelper.convertible.rebateForExpectedConversionRatioHistory) {
        rebate = this._cHelper.convertible.rebateForExpectedConversionRatioHistory;
      }
      else {
        rebate = this._cHelper.convertible.rebate
      }
    }
    
    if (this._cHelper.isCrossFx && !this.model.crossFx) {
      return;
    }

    const rebateFactor = this._cHelper.isUnderlyingRebate && this.model.crossFx ? rebate / this.model.crossFx : rebate;

    if (this._cHelper.isPriceAsPar) {
      factor = this._cHelper.convertible.nominal / 100;

      if (this._cHelper.convertible.underlyingCurrencyLinked) {
        // factor = factor * (this._cHelper.convertible.fixedFxRate / this.model.crossFx);
        this.model.stockPriceUndCcy = (this.model.parity * factor - rebateFactor) * this.model.crossFx  / conversionRatio;
      }
      else {
        this.model.stockPriceUndCcy = (this.model.parity * factor - rebateFactor) * this.model.crossFx / conversionRatio;
      }
    }
    else {
      factor = this.model.crossFx;
      this.model.stockPriceUndCcy = (this.model.parity - rebateFactor) * factor / conversionRatio;
    }

    this.model.stockPriceCbCcy = this.model.stockPriceUndCcy / this.model.crossFx;
  }

  /**
   * Calculates stock price underlying CCY for detachable warrants.
   * @returns Stock price underlying CCY.
   */
  private calculateStockPriceUndCcyForDetachableWarrants() {
    const conversionRatio = this._cHelper.getConversionRatio(this._creSettings, this.model.valuationDate);

    let conversionPrice;
    if (this._cHelper.isCrossFx) {
      const fxFactor = this._cHelper.convertible.fixedFxRate > 0 ? this._cHelper.convertible.fixedFxRate : this.model.crossFx;
      conversionPrice = (this._cHelper.convertible.nominal / conversionRatio) * fxFactor
    } else {
      conversionPrice = this._cHelper.convertible.nominal / conversionRatio
    }

    if (this._cHelper.convertible.isPriceAsPar) {
      const leftPart = ((this.model.parity * this._cHelper.convertible.nominal / 100 - this.getAccretedNominal()) * this.model.crossFx)
                        / conversionRatio;
      this.model.stockPriceUndCcy =  Math.abs(leftPart + conversionPrice);
    }
    else {
      const leftPart = ((this.model.parity - this.getAccretedNominal()) * this.model.crossFx / conversionRatio);
      this.model.stockPriceUndCcy = Math.abs(leftPart + conversionPrice);
    }
  }

  /**
   * Calculates stock prices on cross FX change.
   */
  private calculateStockPricesOnCrossFxChange() {
    if (this.model.stockPriceCbCcy && this.model.stockPriceUndCcy) {
      if (this._cHelper.isDetachableWarrant || this._cHelper.isDeltaNeutral) {
        this.calculateStockPrice(false);
      } else {
        this.calculateStockPrice(true);
      }
    }
    else if (this.model.stockPriceCbCcy) {
      this.calculateStockPrice(true);
    }
    else if (this.model.stockPriceUndCcy) {
      this.calculateStockPrice(false);
    }
  }

  /**
   * Calculates stock price.
   * @param isCbCurrency A flag indicating if Stock Price is changed.
   */
  private calculateStockPrice(isCbCurrency: boolean) {
    if (isCbCurrency) {
      this.model.stockPriceUndCcy = this.model.stockPriceCbCcy * this.model.crossFx;
    }
    else {
      this.model.stockPriceCbCcy = this.model.stockPriceUndCcy / this.model.crossFx;
    }
  }

  /**
   * calculate Parity for Regular Cb
   * @param includeCashRebateInParity - flag for Incl Cash Rebate In Parity checkbox in MC
   */
  private calculateParityForRegularCbs(includeCashRebateInParity?: boolean) {
    let  factor = 1;
    const conversionRatio = this._cHelper.getConversionRatio(this._creSettings, this.model.valuationDate);

    if (this._cHelper.isPriceAsPar) {
      factor = (this._cHelper.convertible.nominal * this.model.crossFx / 100);
    }
    else {
      factor = this.model.crossFx;
    }

    if (factor) {
      let rbtfactor = this._cHelper.convertible.rebate;
      if (!!this._creSettings?.useEstimatedRatio && !!this._cHelper.convertible.rebateForExpectedConversionRatioHistory) {
        rbtfactor = this._cHelper.convertible.rebateForExpectedConversionRatioHistory;
      }
    
      if (!includeCashRebateInParity) {
        this.model.parity = this.model.stockPriceUndCcy * conversionRatio / factor;
      } 
      else {
        if (!this._cHelper.isUnderlyingRebate) {
          rbtfactor *= this.model.crossFx;
        }
      this.model.parity = (this.model.stockPriceUndCcy * conversionRatio + rbtfactor) / factor;
      }
    }
  }

  /**
   * Calculate Parity For Detachable and Equity Neutral using AccretedNominal value
   */
  private calculateParityForDetachableWarrantAndEquityNeutral() {
    const conversionRatio = this._cHelper.getConversionRatio(this._creSettings, this.model.valuationDate);

    let conversionPrice;

    if (this._cHelper.isCrossFx) {
      const fxFactor = this._cHelper.convertible.fixedFxRate > 0 ? this._cHelper.convertible.fixedFxRate : this.model.crossFx;
      conversionPrice = (this._cHelper.convertible.nominal / conversionRatio) * fxFactor
    } else {
      conversionPrice = this._cHelper.convertible.nominal / conversionRatio
    }

    this.model.parity = ((this.model.stockPriceUndCcy - conversionPrice) * conversionRatio / this.model.crossFx) + this.getAccretedNominal();

    if (this._cHelper.isPriceAsPar) {
      this.model.parity = this.model.parity * 100 / this._cHelper.convertible.nominal;
    }
  }

  /**
   * Return Accreated Nominal value for calculate Parity For Detachable and Equity Neutral using function Math.log() and Math.Exp()
   */
  private getAccretedNominal(): number {
    let accretedNominal = this._cHelper.convertible.nominal;

    if (this._cHelper.convertible.maturityDate && this._cHelper.isAccreated && this._cHelper.isDeltaNeutral) {

        let yieldValue = 0;
        let accretedValue = 0;
        const maturityYears = LvDateUtil.calculateYearsFromDate(this._cHelper.convertible.maturityDate,
                                                          this._cHelper.convertible.firstSettlementDate);
        const valuationYears = LvDateUtil.calculateYearsFromDate(this.model.valuationDate,
                                                           this._cHelper.convertible.firstSettlementDate);

        if (this._cHelper.convertible.accretionType === AccretionType.Fixed) {
          yieldValue = this._cHelper.convertible.fixedAccretionRate;
        }
        else {
          yieldValue = this._cHelper.redemptionValue / this._cHelper.issueValue;
        }

        accretedValue = this._cHelper.issueValue *  Math.pow(yieldValue, valuationYears / maturityYears);
        accretedNominal = (accretedValue * this._cHelper.convertible.nominal) / 100;

        return accretedNominal;

    }

    return accretedNominal;
  }

  /**
   * Maps flat market data if exists from excel.
   */
  private mappFlatMarketDataIfExistsFromExcel() {
    if (!!this._excelService?.isInitialized() && this.marketData) {

      if (!!this._excelService?.getFieldValue('DVD_FLAT')) {
        this.model.flatDividendYield = this.marketData.flatDividendYield;
      }
    }
  }
}
