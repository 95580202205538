<lv-flex-box class="lv-model-customization-equity-option-section-container">
    <lv-flex-box class="lv-model-customization-equity-option-settigs-first-column">
        <div class="lv-label lv-label-field lv-label--title">
            Model Type
          </div>
          <lv-flex-box
            direction="row"
            data-cy="DM-1442">
          
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="creditModelTypeSettingsTooltipTemplate"
              filter="span">
              <span> Credit Model Type </span>
            </div>
          
            <div class="lv-input-field lv-input-field--auto">
              <kendo-dropdownlist
                name="modelCustomizationModelTypeCreditModelType"
                valueField="id"
                textField="text"
                [data]="creditModelType.items"
                [valuePrimitive]="true"
                [(ngModel)]="settings.creditModelType"
                [disabled]="!overrideSystemDefaults"
                [popupSettings]="{ width: 150 }">
              </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <div class="lv-label lv-label-field lv-label--title lv-model-customization-equity-option-warrant-parameters">
            Warrant Parameters
        </div>
        <lv-flex-box
         class="lv-input-field"
         direction="row"
         data-cy="DM-5146">

            <div class="lv-label lv-label-field"
             showAfter="1500"
             kendoTooltip
             [tooltipTemplate]="dilutiveSettingsTooltipTemplate"
             filter="div">
                Dilutive
            </div>
            <div class="lv-field lv-field--checkbox">
                <input type="checkbox"
                 class="k-checkbox"
                 [disabled]="!overrideSystemDefaults"
                 name="dilutive"
                 [attr.id]="getCheckBoxId('dilutive')"
                 [(ngModel)]="settings.equityOptionDilutive"/>
                <label class="k-checkbox-label"
                [attr.for]="getCheckBoxId('dilutive')"></label>
            </div>
        </lv-flex-box>

        <lv-flex-box
         direction="row"
         data-cy="DM-5147">

            <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="dilutionModelSettingsTooltipTemplate"
                filter="span">
                <span> Dilution Model </span>
            </div>

            <div class="lv-input-field lv-input-field--auto">
                <kendo-dropdownlist
                 name="dilutionModel"
                 valueField="id"
                 textField="text"
                 [data]="dilutionModel.items"
                 style="min-width: 150px"
                 [valuePrimitive]="true"
                 [(ngModel)]="settings.dilutionModel"
                 [disabled]="!overrideSystemDefaults"
                 [popupSettings]="{ width: 150 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>  
    </lv-flex-box>
    <lv-flex-box class="lv-model-customization-equity-option-settigs-second-column">
      <div class="lv-label lv-label-field lv-label--title">
        Call Adjustments
      </div>
      <lv-flex-box
        direction="row"
        data-cy="DM-1452">
    
        <div class="lv-label lv-label-field lv-model-customization-equity-option-settigs-call-adjustments-fields"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="softCallModelTypeSettingsTooltipTemplate"
          filter="span">
          <span> Soft Call (Trigger) Model Type </span>
        </div>
    
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            name="softCallModelType"
            valueField="id"
            textField="text"
            [data]="softCallModelType.items"
            [valuePrimitive]="true"
            [(ngModel)]="settings.callAdjustmentModelSettings.softCallModelType"
            [disabled]="!overrideSystemDefaults"
            [popupSettings]="{ width: 144 }">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        data-cy="DM-1453">

        <div class="lv-label lv-label-field lv-model-customization-equity-option-settigs-call-adjustments-fields"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="softCallAdjustFactorSettingsTooltipTemplate"
          filter="span">
          <span>  Soft Call (Trigger) Adj. Factor </span>
        </div>

        <div class="lv-input-field">
          <lv-numeric-text-box
            name="callAdjustmentSoftCallAdjustFactor"
            [format]="numberFormat"
            [decimals]="numberOfDecimals"
            [(ngModel)]="settings.callAdjustmentModelSettings.softCallAdjustmentFactor"
            [disabled]="!overrideSystemDefaults || !isSoftCallTrigger">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box>
    </lv-flex-box>
</lv-flex-box>
  <ng-template #creditModelTypeSettingsTooltipTemplate>
    <lv-tooltip dm="dM-1442"></lv-tooltip>
  </ng-template>
  <ng-template #dilutiveSettingsTooltipTemplate>
    <lv-tooltip dm="dM-5146"></lv-tooltip>
  </ng-template>
  <ng-template #dilutionModelSettingsTooltipTemplate>
    <lv-tooltip dm="dM-5147"></lv-tooltip>
  </ng-template>
  <ng-template #softCallModelTypeSettingsTooltipTemplate>
    <lv-tooltip dm="dM-1452"></lv-tooltip>
  </ng-template>
  <ng-template #softCallAdjustFactorSettingsTooltipTemplate>
    <lv-tooltip dm="dM-1453"></lv-tooltip>

  