import { v4 } from 'uuid';
import {
  Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  ViewChild, Input, ChangeDetectorRef, Output, EventEmitter, ViewRef, OnDestroy,
  OnChanges, ElementRef, SimpleChanges, Optional
} from '@angular/core';

import { Subscription } from 'rxjs';
import { LvSidePanelComponent } from '@lv-core-ui/components';
import { LvDateService, LvErrorService } from '@lv-core-ui/services';
import { LvUtil } from '@lv-core-ui/util';
import { CouponType } from '@lv-analytics/models';
import { LvConvertibleBondTermsComponent, PricingSectionCommand } from '@lv-convertible-bond/components';
import { ConvertibleBondTermsDocument, Identifiers, QuickAndFullTermsDocument } from '@lv-convertible-bond/models';
import { ConvertibleBondNewIssueDocument } from '@lv-convertible-bond/models/convertible-bond-terms/ConvertibleBondNewIssueDocument';
import { ConvertibleBondSubType } from '@lv-convertible-bond/models/convertible-bond-terms/ConvertibleBondSubType';
import {
  PricingSectionEvent,
  PricingType, SourceType
} from '@lv-convertible-bond/models/convertible-bond-terms/custom-enum';
import { ICalculateYieldAndRedemptionRequest } from '@lv-custom-instruments/models';
import { CustomInstrumentsService } from '@lv-custom-instruments/services/custom-instruments.service';
import { TermsSettingsService } from '@lv-custom-instruments/services/terms-settings/terms-settings.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvSaveTermsDetailedModalComponent } from './lv-save-terms-detailed-modal/lv-save-terms-detailed-modal.component';
import { LvDataMaster, LvErrorType } from '@lv-core-ui/models';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';
import { IResource } from '@lv-core-ui/models/authorization/resource';
import { LvExcelService } from '@lv-excel/services/excel-service';
import { LvExcelSaveModalComponent } from '@lv-excel/components/lv-excel-save-modal/lv-excel-save-modal.component';
import { CustomInstrument } from '@lv-custom-instruments/models/custom-instrument';
import { LvAccessRightsModalComponent } from './lv-access-rights-modal/lv-access-rights-modal.component';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';
import { ICustomTermsDetailsData } from '@lv-custom-instruments/models/custom-terms-details-data';
import { RestrictedResourceType } from '@lv-core-ui/models/authorization/restricted-resource-type-enum';
import { getFirstSettlementDate, getMaturityDate } from '@lv-convertible-bond/helpers/date-time-helper';
import { IdentifierType, SetupStatus } from '@lv-instrument-common/index';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';

/**
 * Private instrument event.
 */
export enum PrivateInstrumentEvent {
  InstrumentLoaded = 'InstrumentLoaded',
  DraftSaved = 'DraftSaved',
  InstrumentSaved = 'InstrumentSaved'
}

/**
 * Custom instrument component.
 */
@Component({
  selector: 'lv-custom-instrument',
  templateUrl: './lv-custom-instrument.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCustomInstrumentComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(LvConvertibleBondTermsComponent, { static: true }) convertibleBondTermsComponent: LvConvertibleBondTermsComponent;
  @ViewChild(LvSidePanelComponent, { static: true }) settingsPanel: LvSidePanelComponent;

  @Input() leversysId: string;
  @Input() isPrivateInstrument: boolean;
  @Input() hasFullTermsAccess: boolean;
  @Input() region: string;

  @Output() didPrivateInstrumentChange: EventEmitter<PrivateInstrumentEvent>;
  @Output() didPrivateInstrumentLoad: EventEmitter<QuickAndFullTermsDocument>;
  @Output() didPrivateInstrumentReload: EventEmitter<void>;
  @Output() didInstrumentNotFoundReturned: EventEmitter<void>;
  @Output() didInstrumentNameChanged: EventEmitter<string>;

  originalIdentifier: string;

  get isDraft(): boolean {
    return this._draftCreated;
  }

  get draftId(): string {
    return this.isDraft ? this.leversysId : null;
  }

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get isLeversysInstrument(): boolean {
    return this.leversysId?.startsWith('LVS') && !this.leversysId?.startsWith('LVSP');
  }

  get isCustomInstrument(): boolean {
    return this.leversysId?.startsWith('LVSP');
  }

  isFixDates: boolean;
  isFixDatesVisible: boolean;
  fixDatesId: string;
  updateDatesId: string;
  isLoading: boolean;

  notAuthorized: boolean;
  canSaveTermsDetailed: boolean;

  private _draftCreated: boolean;
  private _subscriptions: Subscription[];
  private _originalInstrumentId: string;

  private _termsChangedAndDraftNeedsToBeUpdated: boolean;

  private get _isDraftSaved(): boolean {
    return !!this.leversysId;
  }

  constructor(
    private _service: CustomInstrumentsService,
    private _termsSettings: TermsSettingsService,
    private _errorService: LvErrorService,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef,
    private _authorizationService: AuthorizationService,
    private _lvDateService: LvDateService,
    private _analyticsPresenter: LvAnalyticsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.fixDatesId = v4();
    this.updateDatesId = v4();
    this.isFixDates = false;
    this.isFixDatesVisible = true;

    // this.instrumentIdentifier = null;


    // if this is false draft is needed to be created from reference data convertible
    this.isPrivateInstrument = true;

    this.didPrivateInstrumentChange = new EventEmitter<PrivateInstrumentEvent>();
    this.didPrivateInstrumentLoad = new EventEmitter<QuickAndFullTermsDocument>();
    this.didPrivateInstrumentReload = new EventEmitter<void>();
    this.didInstrumentNotFoundReturned = new EventEmitter<void>();
    this.didInstrumentNameChanged = new EventEmitter<string>();

    this.canSaveTermsDetailed = false;
    this.notAuthorized = false;
    this.isLoading = false;
    this._originalInstrumentId = null;
    this._termsChangedAndDraftNeedsToBeUpdated = false;
  }

  /**
   * Handles any additional initialization tasks.
   */
  async ngOnInit() {
    this._subscriptions = [
      this.convertibleBondTermsComponent.getPricingSectionUpdated().subscribe(
        event => {
          if (event.eventId === PricingSectionEvent.IssueYield) {
            this.onIssueYieldUpdate(event.data, event.source);
          }

          if (event.eventId === PricingSectionEvent.Redemption) {
            this.onRedemptionUpdate(event.data, event.source);
          }

          if (event.eventId === PricingSectionEvent.DatesUpdated) {
            this.updateTermsFromSettingsAndSettingsData();
          }
        }
      )
    ];

    //await this.loadTerms();
  }

  /**
   * Handles the changes.
   * @param changes SimpleChanges object.
   */
  async ngOnChanges(changes: SimpleChanges) {
    const change = changes['leversysId'];

    this.hasFullTermsAccess = await this._authorizationService.hasResourcePermission('TERMS', 'VIEW_FULL_TERMS',
      { identifier: this.leversysId, region: this.region } as IResource);

    if (change && change.currentValue !== change.previousValue && (this.hasFullTermsAccess || this.isPrivateInstrument)) {
        this.loadTerms();
        this.originalIdentifier = this.leversysId;
    }
    else {
      //load empty terms (leversys id null or undefined)
      if (this.hasFullTermsAccess || this.isPrivateInstrument) {
        this.loadTerms();
      }
    }

    this._termsChangedAndDraftNeedsToBeUpdated = false;
  }

  /**
   * Occurs on show settings.
   */
  onShowSettings() {
    this.settingsPanel.togglePanel();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }


  /**
   * On Instrument dates type changed.
   *
   * @return {*}  {Promise<void>}
   * @memberof LvCustomInstrumentComponent
   */
  async onInstrumentDatesTypeChanged(): Promise<void> {
    if (this.leversysId && this.leversysId?.startsWith('LVSP')) {
      this.onSaveInstrument();
    }
    else {
      this.changeInstrumentDatesType();
    }
  }

  /**
   * Occurs on issue yield update.
   * @param type Pricing type.
   * @param sourceType Source type.
   */
  async onIssueYieldUpdate(type: PricingType, sourceType: SourceType) {
    try {
      if (this._isDraftSaved || this.isOpenedFromExcel) {
        this.convertibleBondTermsComponent.applyGridChanges();
        const termsDocument = this.convertibleBondTermsComponent.getQuickAndFullTermsForApi();

        const yieldAndRedemptionRequest = {
          pricingType: type,
          terms: termsDocument.fullTerms
        } as ICalculateYieldAndRedemptionRequest;

        let issueYield = 0;
        if (this.validatePricingSection(yieldAndRedemptionRequest)) {
          issueYield = await this._service.setYieldFromRedemption(yieldAndRedemptionRequest);
        }
        this.convertibleBondTermsComponent.executeCommand(new PricingSectionCommand(type, issueYield, sourceType));

      }
    }
    catch (error) {
      // this._errorService.toastrService.warning(error); hidden message on request of the analytical team
    }
  }

  /**
   * Occurs on redemption update.
   * @param type Pricing type.
   * @param sourceType Source type.
   */
  async onRedemptionUpdate(type: PricingType, sourceType: SourceType) {
    try {
      if (this._isDraftSaved || this.isOpenedFromExcel) {
        this.convertibleBondTermsComponent.applyGridChanges();
        const termsDocument = this.convertibleBondTermsComponent.getQuickAndFullTermsForApi();

        const yieldAndRedemptionRequest = {
          pricingType: type,
          terms: termsDocument.fullTerms
        } as ICalculateYieldAndRedemptionRequest;

        let redemption = 0;
        if (!termsDocument.fullTerms.issueAndRedemption.isPerpetual) {
          redemption = await this._service.setRedemptionFromYield(yieldAndRedemptionRequest);
        }

        this.convertibleBondTermsComponent.executeCommand(new PricingSectionCommand(type, redemption, sourceType));
      }
    }
    catch (error) {
      // this._errorService.toastrService.warning(error); hidden message on request of the analytical team
    }
  }

  /**
   * Occurs on save terms detailed.
   */
  onSaveTermsDetailed() {
    const dialogRef = this._dialogService.open({
      title: 'Save As Copy',
      content: LvSaveTermsDetailedModalComponent,
    });

    const instance = dialogRef.content.instance as LvSaveTermsDetailedModalComponent;
    instance.customTermsDetailsData = {
      instrumentName: this.convertibleBondTermsComponent.getQuickAndFullTermsForApi().fullTerms.issueAndRedemption.name,
      accessScope: AccessScopeType.User
    } as ICustomTermsDetailsData;

    this._subscriptions.push(instance.didSave.subscribe(async (customTermsDetailsData) => {
      if (this.isDraft) {
        await this.saveAsCopy(customTermsDetailsData.instrumentName, customTermsDetailsData.accessScope);
      }
      else {
        await this.onCopy(customTermsDetailsData);
      }

      dialogRef.close();
    }));

    this._subscriptions.push(instance.didCancel.subscribe(() => {
      dialogRef.close();
    }));
  }

  /**
   * Open dialog for choosing Access Scope
   * @param accessScopeType - Access Scope
   */
  onSaveAccessRights(accessScopeType: AccessScopeType, title: string) {
    const dialogRef = this._dialogService.open({
      title: title,
      content: LvAccessRightsModalComponent,
      width: 400,
      height: 164,
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-access-rights-modal');
    const instance = dialogRef.content.instance as LvAccessRightsModalComponent;
    instance.accessScope = accessScopeType;

    this._subscriptions.push(instance.didSave.subscribe(async (accessScope) => {
      await this.onSave(accessScope as AccessScopeType);
      dialogRef.close();
    }));

    this._subscriptions.push(instance.didCancel.subscribe(() => {
      dialogRef.close();
    }));
  }

  /**
   * Occurs on save terms detailed from Excel.
   */
  onSaveTermsDetailedFromExcel() {
    const dialogRef = this._dialogService.open({
      title: 'Save Destination',
      content: LvExcelSaveModalComponent,
      width: 240,
      height: !this._analyticsPresenter.convertibleBondSession?.leversysLocalId ? 90 : 180
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-save-excel');

    const dialog = dialogRef.content.instance as LvExcelSaveModalComponent;
    dialog.isTermsSave = !this._analyticsPresenter.convertibleBondSession?.leversysLocalId;

    dialog.doSaveToApplication.subscribe(() => {
      this.onSaveTermsDetailed();
    });

    dialog.doSaveToAll.subscribe(() => {
      this.onSaveTermsDetailed();
      this.saveDataToExcel();
    });

    dialog.doSaveToExcel.subscribe(() => {
      this.saveDataToExcel();
    });
  }

  /**
   * Occurs on save.
   * @param accessScope Access scope type.
   */
  async onSave(accessScope: AccessScopeType = null) {
    try {
      const privateInstrument = this.preparePrivateInstrumentForSave();

      if (privateInstrument) {
        await LvUtil.timeout(350);

        if (accessScope) {
          privateInstrument.privateInstrumentDocument.accessScope = accessScope;
        }

        const leversysId = await this._service.saveTermsDetailed(privateInstrument);

        this.leversysId = leversysId;
        this._draftCreated = false;

        this.convertibleBondTermsComponent.loadUnderlyingId(privateInstrument.underlyingId);

        const identifiers = await this._service.getConvertibleBondIdentifiersByLeversysId(this.leversysId);
        privateInstrument.privateInstrumentDocument.identifiers = identifiers;

        this.convertibleBondTermsComponent.loadTerms(privateInstrument.privateInstrumentDocument, privateInstrument.underlyingId);
        this._changeDetector.detectChanges();
        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1826'));
        this.didPrivateInstrumentChange.next(PrivateInstrumentEvent.InstrumentSaved);
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetector.detectChanges();
    }
  }

    /**
   * Save draft from leversys convertible as copy.
   * @param accessScope Access scope type.
   */
    async saveAsCopy(name: string, accessScope: AccessScopeType = null) {
      try {
        const privateInstrument = this.preparePrivateInstrumentForSave();

        if (!privateInstrument) {
          return;
        }

        privateInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.name = name;
        if (privateInstrument) {
          await LvUtil.timeout(350);

          if (accessScope) {
            privateInstrument.privateInstrumentDocument.accessScope = accessScope;
          }

          const leversysId = await this._service.saveTermsDetailedAsCopy(
            this.leversysId,
            accessScope === AccessScopeType.User,
            privateInstrument);

          this.leversysId = leversysId;
          this._draftCreated = false;

          this.convertibleBondTermsComponent.loadUnderlyingId(privateInstrument.underlyingId);

          const identifiers = await this._service.getConvertibleBondIdentifiersByLeversysId(this.leversysId);
          privateInstrument.privateInstrumentDocument.identifiers = identifiers;

          this.convertibleBondTermsComponent.loadTerms(privateInstrument.privateInstrumentDocument, privateInstrument.underlyingId);
          this._changeDetector.detectChanges();
          this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1826'));
          this.didPrivateInstrumentChange.next(PrivateInstrumentEvent.InstrumentSaved);
        }
      }
      catch (error) {
        this._errorService.handleError(error);
      }
      finally {
        this._changeDetector.detectChanges();
      }
    }

  /**
   * Occurs on save instrument.
   */
  onSaveInstrument() {
    if (this.leversysId?.startsWith("LVSP")) {
      this.onSave();
    }
    else {
      this.onSaveAccessRights(AccessScopeType.User, 'Save Instrument As');
    }
  }

  /**
   * Occurs on save from Excel.
   */
  async onSaveFromExcel() {
    const dialogRef = this._dialogService.open({
      title: 'Save Destination',
      content: LvExcelSaveModalComponent,
      width: 240,
      height: !this._analyticsPresenter.convertibleBondSession?.leversysLocalId ? 90 : 180
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-save-excel');

    const dialog = dialogRef.content.instance as LvExcelSaveModalComponent;

    dialog.isTermsSave = !this._analyticsPresenter.convertibleBondSession?.leversysLocalId;

    dialog.doSaveToApplication.subscribe(() => {
      this.onSave();
    });

    dialog.doSaveToAll.subscribe(() => {
      this.onSave();
      this.saveDataToExcel();
    });

    dialog.doSaveToExcel.subscribe(() => {
      this.saveDataToExcel();
    });
  }

  /**
   * Save a instrument as copy.
   * @param customTermsDetailsData Custom terms details data.
   */
  async onCopy(customTermsDetailsData: ICustomTermsDetailsData) {
    try {
      const privateInstrument = await this.preparePrivateInstrumentForSave();
      privateInstrument.originalInstrumentId = this._originalInstrumentId;

      //on save as copy when leversys convertible entered in draft has to use original ID
      if (this.leversysId === 'draft')
      {
        privateInstrument.leversysId = this._originalInstrumentId;
      }

      if (privateInstrument) {
        await LvUtil.timeout(350);
        privateInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.name = customTermsDetailsData.instrumentName;
        privateInstrument.privateInstrumentDocument.accessScope = customTermsDetailsData.accessScope;

        const response = await this._service.copyTermsDetailed(privateInstrument);
        this.leversysId = response.leversysId;
        this._draftCreated = false;
        this.isPrivateInstrument = true;
        this.onReload();
        this.didPrivateInstrumentChange.next(PrivateInstrumentEvent.InstrumentSaved);
        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1826'));
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetector.detectChanges();
    }
  }

  /**
   * Reload instrument Terms tab.
   * @param skipInstrumentReloadEvent - the parameter is true when we reload instrument after approving notification from LWS.
   * If this parameter is true we will skip emitting reload event.
   */
  async onReload(skipInstrumentReloadEvent: boolean = false) {
    try {
      this.convertibleBondTermsComponent.applyGridChanges();

      if (this.leversysId) {
        await this.loadTerms();
        this._draftCreated = false;
      }
      else {
        await this.initEmptyTerms();
      }

      if (!this._excelSvc?.isInitialized() && !skipInstrumentReloadEvent) {
        this.didPrivateInstrumentReload.next();
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.detectChanges();
    }
  }

  private async initEmptyTerms() {
    if (this.isOpenedFromExcel) {
      const firstSettlement = getFirstSettlementDate(this._excelSvc?.getFieldValue('FRST_STL'));
      const maturity = getMaturityDate(this._excelSvc?.getFieldValue('MAT'));
      const cbTerms = await this._termsSettings.createTermsFromSettings(firstSettlement, maturity, false) as ConvertibleBondNewIssueDocument;
      const settings = await this._termsSettings.getTermsSettings();
      this.convertibleBondTermsComponent.populateServiceConvertibleBondTermsData(cbTerms, settings);

      this.convertibleBondTermsComponent.loadTermsFromExcel(cbTerms);
    }
    else {
      const firstSettlement = getFirstSettlementDate(this._lvDateService.getUtcDate(new Date()));
      const cbTerms = await this._termsSettings.createTermsFromSettings(firstSettlement, null, true) as ConvertibleBondNewIssueDocument;
      const settings = await this._termsSettings.getTermsSettings();

      this.convertibleBondTermsComponent.populateServiceConvertibleBondTermsData(cbTerms, settings);

      this.convertibleBondTermsComponent.createTerms(cbTerms, settings);
    }
  }

  /**
   * Loads terms detailed.
   * @returns Quick and full terms document.
   */
  async loadTerms() {
    try {
      this.isLoading = true;
      this.detectChanges();

      this.canSaveTermsDetailed = await this._authorizationService.hasResourcePermission('TERMS', 'COPY_TERMS',
        {
          region: this.region,
          restrictedResourceType: RestrictedResourceType.Instrument,
          identifier: this.leversysId
        } as IResource);

      this.detectChanges();

      const instrumentLeversysId = this.leversysId === 'draft' ? this.originalIdentifier : this.leversysId;

      if (instrumentLeversysId) {

        const instrument = await this._service.getConvertibleBondByLeversysId(instrumentLeversysId);
        this._draftCreated = false;

        await this.populateTermsSettings(instrument);
        this._originalInstrumentId = instrument.leversysId;
        this.setCustomInstrument(instrument);
      }
      else {
        this.isFixDates = true;
        await this.initEmptyTerms();
      }
    }
    catch (error) {
      if (error.type !== LvErrorType.AUTHORIZATION && error.type !== LvErrorType.NOT_FOUND) {
        this._errorService.handleError(error);
      }

      if (error.type === LvErrorType.NOT_FOUND) {
        this.didInstrumentNotFoundReturned.next();
      }
    }
    finally {
      this.isLoading = false;
      this.detectChanges();
    }
  }

  /**
   * Refresh terms from settings and settings data
   */
  async updateTermsFromSettingsAndSettingsData() {
    if (this.leversysId && !this.isPrivateInstrument) {
      const instrumentResponse = await this._service.getConvertibleBondByLeversysId(this.leversysId);
      await this.populateTermsSettings(instrumentResponse);
    }
  }

  /**
   * Send update event to other analytics components
   * when terms is updated, with priority.
   *
   * @param {string} eventSource
   * @memberof LvCustomInstrumentComponent
   */
  onTermsChanged(eventSource: string) {
    this._termsChangedAndDraftNeedsToBeUpdated = true;

    if (!this.leversysId && !this.isDraft) {
      this.createDraft();
    }
    else if (this.leversysId) {
      if (!this._draftCreated) {
        this._draftCreated = true;
        this.didPrivateInstrumentChange.next(PrivateInstrumentEvent.DraftSaved);
      }

      this.didInstrumentNameChanged.next(this.convertibleBondTermsComponent.getQuickAndFullTermsForApi().quickTerms.issueName);
    }

    this._analyticsPresenter.onTermsChanged(eventSource);
  }

  /**
   * Populate terms from settings and and settings data
   * @param instrument Custom instrument data
   */
  private async populateTermsSettings(instrument: CustomInstrument) {
    const firstSettlement = getFirstSettlementDate(instrument.privateInstrumentDocument.fullTerms.issueAndRedemption.firstSettlementDate);
    const maturity = getMaturityDate(instrument.privateInstrumentDocument.fullTerms.issueAndRedemption.maturityDate);
    const isNewIssue = instrument.privateInstrumentDocument.fullTerms.issueAndRedemption.subType === ConvertibleBondSubType.PEPS;

    const cbTerms = await this._termsSettings.createTermsFromSettings(firstSettlement, maturity, isNewIssue) as ConvertibleBondNewIssueDocument;
    const settings = await this._termsSettings.getTermsSettings();

    this.convertibleBondTermsComponent.populateServiceConvertibleBondTermsData(cbTerms, settings);
  }

  /**
   * Saves data to Excel.
   */
  private async saveDataToExcel() {
    if (this._excelSvc.isAnyV3version()) {
      await this.updateTermsInSession();
      const fields = this._excelSvc.getMappedFieldsForSave('Terms');
      await this._excelSvc.saveDataToExcelV2(fields,
        this._analyticsPresenter.sessionId,
        this.leversysId,
        'Terms',
        this.convertibleBondTermsComponent.isQuickTermsVisible,
        this.convertibleBondTermsComponent.isCrossFx);
    }
    else {
      this._excelSvc.saveDataToExcel(
        this.convertibleBondTermsComponent.getMappedExcelFieldsFromExcel()
      );
    }
  }

  /**
   * Validates draft terms.
   * @param terms ConvertibleBondNewIssueDocument object.
   * @returns A flag indicating if terms are valid.
   */
  private validateDraftTerms(terms: ConvertibleBondNewIssueDocument): boolean {
    let isValid: boolean;
    isValid = true;

    if (!terms.issueAndRedemption.name) {
      isValid = false;
    }
    if (!terms.issueAndRedemption.currencyCode) {
      isValid = false;
    }
    if (terms.issueAndRedemption.underlyingEquity && !terms.issueAndRedemption.underlyingEquity.currencyCode) {
      isValid = false;
    }
    if (!terms.issueAndRedemption.maturityDate && !terms.issueAndRedemption.isPerpetual) {
      isValid = false;
    }

    return isValid;
  }

  /**
  * Saves draft.
  * @param event Convertible bond terms event.
  */
  async updateTermsInSession(): Promise<boolean> {
    if (!this._termsChangedAndDraftNeedsToBeUpdated) {
      return false;
    }

    try {
      this.convertibleBondTermsComponent.applyGridChanges();
      const terms = this.convertibleBondTermsComponent.getQuickAndFullTermsForApi();

      if (terms.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.NewIssue &&
        terms.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.PartiallyCompleted) {
        terms.fixDatesInQuickTermsEntry = false;
        this.isFixDates = false;
        this.isFixDatesVisible = false;
      }
      else {
        this.isFixDatesVisible = true;
        terms.fixDatesInQuickTermsEntry = this.isFixDates;
      }

      if (this.validateDraftTerms(terms.fullTerms)) {
        await this._analyticsPresenter.overrideTermsInSession(terms.fullTerms);
        this._termsChangedAndDraftNeedsToBeUpdated = false;

        if (!this._draftCreated) {
          this._draftCreated = true;
          this.didPrivateInstrumentChange.next(PrivateInstrumentEvent.DraftSaved);
        }

        return true;
      }
    }
    catch (error) {
      this._errorService.handleError(error);
      return false;
    }
  }

  async createDraft() {
    try {
      this.convertibleBondTermsComponent.applyGridChanges();
      const terms = this.convertibleBondTermsComponent.getQuickAndFullTermsForApi();

      if (!this.validateDraftTerms(terms.fullTerms)) {
        return;
      }

      if (terms.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.NewIssue &&
        terms.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.PartiallyCompleted) {
        terms.fixDatesInQuickTermsEntry = false;
        this.isFixDates = false;
        this.isFixDatesVisible = false;
      }
      else {
        this.isFixDatesVisible = true;
        terms.fixDatesInQuickTermsEntry = this.isFixDates;
      }

      await this._analyticsPresenter.loadConvertibleBondDraftInSession(terms.fullTerms);
      this._draftCreated = true;
      this.leversysId = 'draft';
      this.didPrivateInstrumentChange.next(PrivateInstrumentEvent.DraftSaved);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  /**
   * Change instrument dates type to be fixed or rolling dates.
   *
   * @param {boolean} fixDatesInQuickTermsEntry
   * @memberof LvConvertibleBondTermsPresenter
   */
  private changeInstrumentDatesType() {
    this.convertibleBondTermsComponent.changeInstrumentDatesType(this.isFixDates);
  }

  /**
   * Prepares custom instrument for save.
   * @returns CustomInstrument object.
   */
  private preparePrivateInstrumentForSave(): CustomInstrument {

    this.convertibleBondTermsComponent.applyGridChanges();
    const privateInstrument = new CustomInstrument();

    privateInstrument.privateInstrumentDocument = this.convertibleBondTermsComponent.getQuickAndFullTermsForApi();
    privateInstrument.leversysId = this.leversysId;
    privateInstrument.underlyingId = this.convertibleBondTermsComponent.underlyingEquityId;

    if (privateInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.NewIssue &&
      privateInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.PartiallyCompleted) {
      this.isFixDates = false;
    }

    privateInstrument.privateInstrumentDocument.fixDatesInQuickTermsEntry = this.isFixDates;

    const termsDoc = Object.assign(new ConvertibleBondTermsDocument(), privateInstrument.privateInstrumentDocument.fullTerms);

    const validationData = termsDoc.validateTerms();

    if (!this.convertibleBondTermsComponent.useDummyEquity && !privateInstrument.underlyingId) {
      validationData[0] = false;
      validationData[1] += '<div>' + LvDataMaster.getWarning('dM-4865') + '</div>';
    }

    if (validationData[0]) {
      return privateInstrument;
    } else {
      this._errorService.toastrService.warning(validationData[1]);
      return null;
    }
  }

  /**
   * Validates pricing section.
   * @param request ICalculateYieldAndRedemptionRequest object.
   * @returns A flag indicating if pricing section is valid.
   */
  private validatePricingSection(request: ICalculateYieldAndRedemptionRequest): boolean {
    let isValid = true;
    const terms = request.terms;
    const type = request.pricingType;

    if (terms.issueAndRedemption.subType === ConvertibleBondSubType.PEPS) {
      isValid = false;
    }
    if (!terms.issueAndRedemption.maturityDate) {
      isValid = false;
    }
    if (terms.coupon && terms.coupon.type === CouponType.Floating) {
      isValid = false;
    }
    if (type === PricingType.Best && terms.priceTalk.redemptionValueBest && terms.priceTalk.redemptionValueBest === 0) {
      isValid = false;
    }
    if (type === PricingType.Worst && terms.priceTalk.redemptionValueWorst && terms.priceTalk.redemptionValueWorst === 0) {
      isValid = false;
    }

    return isValid;
  }

  /**
   * Detects changes.
   */
  private detectChanges() {
    if (!(this._changeDetector as ViewRef).destroyed) {
      this._changeDetector.detectChanges();
    }
  }

  /**
   * Sets custom instrument.
   * @param customInstrument CustomInstrument object.
   */
  private setCustomInstrument(customInstrument: CustomInstrument, termsSettingsDocument?: ConvertibleBondTermsDocument) {
    if ((customInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.NewIssue &&
      customInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.PartiallyCompleted)
    || this.isLeversysInstrument) {

      customInstrument.privateInstrumentDocument.fixDatesInQuickTermsEntry = false;
      this.isFixDates = false;
      this.isFixDatesVisible = false;
    }
    else {
      this.isFixDatesVisible = true;
      this.isFixDates = customInstrument.privateInstrumentDocument.fixDatesInQuickTermsEntry;
    }

    this.convertibleBondTermsComponent.loadTerms(customInstrument.privateInstrumentDocument, customInstrument.underlyingId);

    this.didPrivateInstrumentLoad.next(customInstrument.privateInstrumentDocument);
  }

  /**
   * Gets custom instrument tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Section ID.
   */
  getPrivateInstrumentTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}

