import {
  Component, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef,
  OnDestroy, OnInit, ViewRef, Optional, TemplateRef, ElementRef, AfterViewInit, Input,
  DestroyRef,
} from '@angular/core';

import { filter, Subscription } from 'rxjs';

import { DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { LvGridLayoutComponent } from '@lv-core-ui/components';
import { LvDateService, LvErrorService } from '@lv-core-ui/services';
import { OutputsService, ValuationSessionService } from '@lv-analytics/services';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { LvExcelService } from '@lv-excel/services';
import { AnalyticsCommands, AnalyticsEvents, AnalyticsSettingsEvents, IAnalyticsSettings, IOutput, IValuationResult } from '@lv-analytics/models';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { LvSendValuationQueryDialogComponent } from '../lv-send-valuation-query-dialog/lv-send-valuation-query-dialog.component';
import { LvOutputsDialogComponent} from '../lv-outputs-dialog/lv-outputs-dialog.component';
import { LvOutputsWidgetView } from './lv-outputs-widget.view';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { ILvOutput } from '@lv-analytics/models/outputs/lv-output';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LvDateUtil } from '@lv-core-ui/util';

/**
 * The outputs component is responsible for presenting results of calculation.
 * It also allows user to send message to Leversys support with XML file with input calculation parameters.
 */
@Component({
  selector: 'lv-outputs-widget',
  templateUrl: './lv-outputs-widget.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvOutputsWidgetComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() instrumentType: string;

  @ViewChild(LvGridLayoutComponent) gridLayout: LvGridLayoutComponent;
  @ViewChild('tooltipTemplate', { read: TemplateRef }) public tooltipTemplate: TemplateRef<any>;
  @ViewChild('tabStrip', { static: true }) tabStrip: TabStripComponent;

  view: LvOutputsWidgetView;
  isLoading: boolean;
  showSettings: boolean;
  isPricingValid: boolean;
  keepTabContent: boolean;
  isTabularOutputsVisible: boolean;
  isOutputsVisible: boolean;

  private _valuationResult: IValuationResult;

  private _subscriptions: Subscription[];

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get editWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Edit Excel View' : 'Edit Custom View';
  }

  get saveWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Save as Excel View' : 'Save as Default View';
  }

  get selectedTabName():string {
    return this.tabStrip.tabs.find(x => !!x.selected)?.title
  }

  get areTenorTabularOutputsVisible(): boolean {
    return !!this._presenter?.areTenorTabularOutputsVisible;
  }

  get isBond(): boolean {
    return this.instrumentType === 'Bond';
  }

  /**
   * Constructor for the outputs component. Creates and initializes view component.
   * @param _changeDetectorRef pass changeDetectorRef instance
   * @param _dialogService pass dialogService instance
   * @param _errorService pass lvErrorService instance
   * @param _service pass valuationSessionService instance
   * @param _outputsService pass outputsService instance
   * @param _presenter pass lvAnalyticsPresenter instance
   * @param _widgetStateManager pass widgetStateManagerService instance
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialogService: DialogService,
    private _errorService: LvErrorService,
    private _service: ValuationSessionService,
    private _outputsService: OutputsService,
    private _presenter: LvAnalyticsPresenter,
    private _widgetStateManager: WidgetStateManagerService,
    private _lvDateService: LvDateService,
    private _destroyRef: DestroyRef,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.view = new LvOutputsWidgetView(_lvDateService);
    this.view.init();

    this.showSettings = true;
    this.isPricingValid = false;
    this.initValuationResult();
    this.isOutputsVisible = true;
    this.isTabularOutputsVisible = true;

    this.instrumentType = '';

    /* Outputs widget is special widget that doesn't use application
     settings for persisting applicaion state but it uses analytics backend (session)
    to store selected outputs, since we then optimize calcuation by sending only selected outputs to pricer
    this is why we don't register to state manager here, but only subscribe to global SaveAllAsDefeault and notify dashboard when custom
    view is used, so dashboard can switch to custom view as well */
  }

  /**
   * Initializes the component. Subscribes to all relevant events.
   *
   * Two events (onAnalyticsSettingsUpdated and onPricingModelUpdated) are used to update isPricingValid flag
   * after every change of pricing parameters.
   */
  ngOnInit() {
    this._subscriptions = [
      this._presenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._presenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        this.init(this._presenter.getModelData());
        this.isPricingValid = this._presenter.isPricingValid;
        this._changeDetectorRef.detectChanges();
      }),

      this._presenter.onEventPublished.subscribe(evt => {
        if (evt.eventId === AnalyticsEvents.ValuationCompleted) {
          this.initValuationResult(this.mapToUi(evt.data));
          this.renderLayout();
        }
        this._changeDetectorRef.detectChanges();
      }),

      this._presenter.onPricingModelUpdated.subscribe(evt => {
        this.isPricingValid = this._presenter.isPricingValid;
      }),

      this._widgetStateManager.onSaveAsDefaultAll.subscribe(() => {
        this.onSaveAsDefaultView();
      }),

      this._widgetStateManager.didSelectDefaultWidgetState().subscribe(() => {
        this.view.configuration.useDefault = true;
        this.onDisplayDefaultView();
      })
    ];

    this._presenter.termsChanged
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(async event => {
        this.init(this._presenter.getModelData());
        this.renderLayout();
        this._changeDetectorRef.detectChanges();
      });

    const relevantEventForModelUpdatedIds: string[] = [
      AnalyticsSettingsEvents.ValuationSettingsModelUpdated,
      AnalyticsSettingsEvents.CreditUpdated,
      AnalyticsSettingsEvents.VolatilityUpdated,
      AnalyticsSettingsEvents.BondInstrumentUpdated,
      AnalyticsSettingsEvents.BondInstrumentSaved,
      AnalyticsSettingsEvents.BondInstrumentLoaded
    ];

    this._presenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => relevantEventForModelUpdatedIds.includes(event?.eventId)))
      .subscribe(evt => {
        this.init(this._presenter.getModelData());
        this.renderLayout();
        this._changeDetectorRef.detectChanges();
      });

    if (!this._widgetStateManager.dashboardStateId && !this.isOpenedFromExcel) {
      this.showSettings = false;
    }

    if (!this.selectedTabName) {
      this.tabStrip.selectTab(0);
    }

    this.init(this._presenter.getModelData());
  }

  
  /**
   * Map output results 
   * Dates should be displayed in
   * @param {IValuationResult} data
   * @return {*} 
   * @memberof LvOutputsWidgetComponent
   */
  mapToUi(data: IValuationResult) {
    if (!!data.outputs['accretedPutValue']) {
      data.outputs['accretedPutValue'] = data.outputs['accretedPutValue'].map(element => {
        element.date = LvDateUtil.getUtcDataFromIsoString(element.date)
        return element;
      });
    }

    if (!!data.outputs['lastFixDate']) {
      data.outputs['lastFixDate'] = LvDateUtil.getUtcDataFromIsoString(data.outputs['lastFixDate']) 
    }

    if (!!data.outputs['nextFixDate']) {
      data.outputs['nextFixDate'] = LvDateUtil.getUtcDataFromIsoString(data.outputs['nextFixDate']) 
    }

    return data;
  }

  /**
   * Initializes view and sets isPricingValid flag on loading component.
   * @param {IAnalyticsSettings} analyticsSettings pass analytics settings
   */
  init(analyticsSettings?: IAnalyticsSettings) {
    this.isPricingValid = this._presenter.isPricingValid;
    this.view.init(
      analyticsSettings,
      this.instrumentType,
      this._presenter.bondSession?.terms?.general.currencyCode,
      this._presenter.bondSession?.terms?.general.isPriceAsPar);

    this.initValuationResult();
    this.renderLayout();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on show as persent of par and updates layout.
   */
  onShowAsPercentOfPar() {
    this.view.onShowAsPriceAsPar();

    this.gridLayout.updateLayout<ILvOutput>(this.view.regularOutputs,
      (previous, current) => {
        Object.assign(previous, current);
      },
    'type');

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Opens Send Valuation Query dialog. Subscribes to didSend event of a dialog in order to publish SendValuationQueryStarted event.
   */
  onShowSendValuationQueryDialog() {
    const dialogRef = this._dialogService.open({
      title: 'Send Valuation Query',
      content: LvSendValuationQueryDialogComponent
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-outputs-side-panel-window');
    const dialog = dialogRef.content.instance as LvSendValuationQueryDialogComponent;

    dialog.didSend.subscribe(() => {
      this._presenter.publishEvent({
        eventId: AnalyticsEvents.SendValuationQueryStarted,
        data: dialog.valuationQueryNotes
      });
      dialogRef.close();
    });
  }

  /**
   * Occurs on display default view.
   */
  async onDisplayDefaultView() {
    try {
      this.setLoadingState(true);
      await this._service.selectSessionOutputs(this.getSessionId(), true, this.instrumentType);
      this.view.configuration.useDefault = true;
      this.renderLayout();
    } catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on display custom view.
   */
  async onDisplayCustomView() {
    try {
      this.setLoadingState(true);
      await this._service.selectSessionOutputs(this.getSessionId(), false, this.instrumentType);
      this.view.configuration.useDefault = false;
      this._widgetStateManager.publishCustomViewUsed();
      this.renderLayout();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on save as default view and saves user default outputs.
   */
  async onSaveAsDefaultView() {
    try {
      this.setLoadingState(true);
      this.view.configuration.useDefault = true;
      await this._outputsService.saveUserDefaultOutputs(this.view.configuration.customOutputs, this.isOpenedFromExcel, this.instrumentType);
      this.view.configuration.outputs = this.view.configuration.customOutputs;
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Gets element tooltip ID.
   * @param anchor Anchor.
   * @param element Element.
   * @returns Tooltip ID.
   */
  getTootlipId(anchor: ElementRef, element: string) {
    return anchor.nativeElement.getAttribute('tooltip-id') === element;
  }

  /**
   * Occurs on edit custom view.
   */
  onEditCustomView() {
    const dialogRef = this._dialogService.open({
      title: 'Outputs Custom Selection',
      maxHeight: 770,
      height: '80%',
      content: LvOutputsDialogComponent,
    } as DialogSettings);

    dialogRef.dialog.location.nativeElement.classList.add('lv-outputs-custom-selection-dialog');

    const dialog = (dialogRef.content.instance as LvOutputsDialogComponent);

    const mappedOutputs = this.view.getMappedCustomOutputsOutputs('Outputs');
    const mappedTabular = this.view.getMappedCustomOutputsOutputs('Tabular Outputs');

    dialog.outputs =
      { sessionId: this.getSessionIdByInstrumentType(),
        availableItems: mappedOutputs.availableOutputs,
        selectedItems: mappedOutputs.selectedOutputs,
        tooltips: this.tooltipTemplate
      };

    dialog.tabularOutputs = 
      { sessionId: this.getSessionIdByInstrumentType(),
        availableItems: mappedTabular.availableOutputs,
        selectedItems: mappedTabular.selectedOutputs,
        tooltips: this.tooltipTemplate
      };

    dialog.instrumentType = this.instrumentType;

    dialog.didSelectedItemsChanged.subscribe((selectedOutputsListEmpty) => {
    this.isOutputsVisible = !selectedOutputsListEmpty[0],
    this.isTabularOutputsVisible = !selectedOutputsListEmpty[1]
    });

    dialog.didSave.subscribe(
      (updatedOutputs: IOutput[]) => {
        updatedOutputs.forEach(el => {
          this.view.configuration.customOutputs = updatedOutputs.map(a => a);
          this.view.configuration.useDefault = false;
        });
        this.updateAnalyticsSettings();
        this.renderLayout();

        if (!(this._changeDetectorRef as ViewRef).destroyed) {
            this._changeDetectorRef.detectChanges();
          }
    });
  }

  getSessionIdByInstrumentType() {
    if (this.instrumentType === 'ConvertibleBond') {
      return this.view.asHelper.sessionId;
    }
    else if (this.instrumentType === 'Bond'){
      return this._presenter.bondSession.sessionId;
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

   /**
   * Occurs after view initialization.
   */
   ngAfterViewInit(): void {
    this.renderLayout();
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Renders layout.
   */
  private renderLayout() {
    this.view.calculateOutputs(this._valuationResult);
    this.isOutputsVisible = this.view.regularOutputs.length  > 0;
    this.isTabularOutputsVisible = this.view.tabularOutputs.length > 0;
    this._changeDetectorRef.detectChanges();

    if (!this.selectedTabName) {
      this.tabStrip.selectTab(0);
      this._changeDetectorRef.detectChanges();
    }

    if (this.selectedTabName === 'Outputs') {
      this.gridLayout.renderLayout(this.view.regularOutputs);
    }

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Initializes valuation result.
   * @param result IValuationResult object.
   */
  private initValuationResult(result?: IValuationResult) {
    this._valuationResult = result || {
      outputs: {}, warning: null,
    };
  }
  /**
   * Updates analytics settings when user clicked save on edit custom view
   * When you change a field on terms, it trigger an event that leads to calculateOutputs function being called
   * Problem is, calculate function has old AnalyticsSettings, more precisely old IOutputsConfiguration
   * and it resets the view, creating the issue here: https://leversys.atlassian.net/browse/SYSTEM-4064
   */
  private updateAnalyticsSettings() {
    const _analyticsSettings = this._presenter.getModelData();
    if (this.instrumentType === 'ConvertibleBond') {
      _analyticsSettings.valuationSession.outputsConfiguration.customOutputs = this.view.configuration.customOutputs;
      _analyticsSettings.valuationSession.outputsConfiguration.useDefault = this.view.configuration.useDefault;
    }
    else if (this.instrumentType === 'Bond'){
      _analyticsSettings.bondValuationSession.outputsConfiguration.customOutputs = this.view.configuration.customOutputs;
      _analyticsSettings.bondValuationSession.outputsConfiguration.useDefault = this.view.configuration.useDefault;
    }
  }

  /**
   * Get session id for different types of instrument.
   * @returns 
   */
  private getSessionId(): string {
    switch (this.instrumentType) {
      case 'ConvertibleBond':
        return this.view.asHelper.sessionId;
      case 'Bond':
        return this._presenter.bondSession.sessionId;
      default:
        return this.view.asHelper.sessionId;
    }
  }
}