import {
  Component, Input, ViewEncapsulation, EventEmitter,
  ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, Optional, OnInit, OnDestroy, ViewRef, Output,
  DestroyRef
} from '@angular/core';

import { filter, Subscription } from 'rxjs';
import { DialogService } from '@progress/kendo-angular-dialog';
import * as _ from 'lodash';

import { LvBasicTermsDialogComponent } from './lv-basic-terms-dialog/lv-basic-terms-dialog.component';
import { LvBaseWidgetComponent } from '../../lv-base-widget.component';
import { ConvertiblesService } from '../../services/convertibles/convertibles.service';
import { AnalyticsCommands, AnalyticsSettingsEvents } from '../../models/enum/analytics-settings-events';
import { IConstants, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvUtil, constants } from '@lv-core-ui/util';
import { BasicTermFieldType, BasicTermsEnum, IBasicTermField, IBasicTerms } from '@lv-analytics/models/basic-terms';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { ITermsSummaryState } from '@lv-application-settings/models';
import { DefaultWidgetType } from '@lv-shared/models';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { LvExcelService } from '@lv-excel/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface IBasicTermsInstrumentInfo {
  lwsIdentifier: string;
  sessionId: string;
  isPrivateInstrument: boolean;
}

/**
 * Basic terms component.
 */
@Component({
  selector: 'lv-basic-terms',
  templateUrl: './lv-basic-terms.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBasicTermsComponent extends LvBaseWidgetComponent<ITermsSummaryState> implements OnInit, OnDestroy {

  private _instrumentInfo: IBasicTermsInstrumentInfo;
  
  // @Input()

  set instrumentInfo(value: IBasicTermsInstrumentInfo) {
    this._instrumentInfo = value;

    this.getTermsSummary();
  }

  @Input() instrumentTypeValue: string;

  get instrumentInfo(): IBasicTermsInstrumentInfo {
    return this._instrumentInfo;
  }

  get isOpenedFromExcel(): boolean {
    return this._excelSvc && this._excelSvc.isInitialized();
  }

  get editWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Edit Excel View' : 'Edit Custom View';
  }

  get saveWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Save as Excel View' : 'Save as Default View';
  }

  @Output() didNotAuthorized: EventEmitter<boolean>;

  // TODO Remove this field and add it as part of basic terms state.
  basicTerms: IBasicTerms;

  basicTermsViewColumns: {
    data: IBasicTermField[]
  }[];

  visibleFieldsDict: any;

  pepsFieldsDict: any;
  dateFieldsDict: any;
  currencyFieldsDict: any;
  percentOfNominalFields: any;

  constants: IConstants;
  isLoading: boolean;

  private _subscriptions: Subscription[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private errorService: LvErrorService,
    private _service: ConvertiblesService,
    public widgetStateManagerService: WidgetStateManagerService,
    private _destroyRef: DestroyRef,
    @Optional() private _presenter: LvAnalyticsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {
    super(
      changeDetectorRef,
      widgetStateManagerService,
      getInitialBasicTermsWidgetState(!!_excelSvc?.isInitialized(), getInstrumentType(_presenter)),
      DefaultWidgetType.InstrumentInfo,
      getInstrumentType(_presenter)
    );

    this.constants = constants;

    this.pepsFieldsDict = {
      'conversionPrice': 'Max. ',
      'conversionRatio': 'Min. ',
      'expectedCP': 'Max. ',
      'expectedCR': 'Min. ',
      'minConversionPrice': 'empty',
      'minExpectedConversionPrice': 'empty',
      'maxConversionRatio': 'empty',
      'maxExpectedConversionRatio': 'empty'
    };

    this.dateFieldsDict = {
      'firstSettlement': true,
      'firstCouponPaymentDate': true,
      'maturity': true
    };

    this.currencyFieldsDict = {
      'conversionPrice': true,
      'expectedCP': true,
      'minConversionPrice': true,
      'minExpectedConversionPrice': true
    };

    this.percentOfNominalFields = {
      'issueValue': 'IsPercentOfNominal',
      'redemptionValue': 'IsPercentOfNominal'
    };

    this.initializeState();
    this.initializeFields();

    this._subscriptions = [];
    this.isLoading = false;

    this.didNotAuthorized = new EventEmitter<boolean>();
  }

  /**
   * Handles any additional initialization tasks.
   */
  onInit() {
    if (this._presenter) {
      this._subscriptions = [
        this._presenter.onAnalyticsSettingsUpdated.subscribe(async evt => {
          await this.reloadWidgetState(DefaultWidgetType.InstrumentInfo, this.instrumentTypeValue);
          this.handleModelUpdate(evt);
        }),
      ];

      if (this._presenter.isConvertibleModelLoaded()) {
        this.instrumentInfo = {
          lwsIdentifier: this._presenter.getInstrumentIdentifier(),
          sessionId: this._presenter.sessionId,
          isPrivateInstrument: this._presenter.isPrivateInstrument
        };

        this.detectChanges();
      }
    }

    const relevantEventForModelUpdatedIds: string[] = [
      AnalyticsSettingsEvents.ConvertibleBondInstrumentLoaded,
      AnalyticsSettingsEvents.ConvertibleBondInstrumentSaved,
      AnalyticsSettingsEvents.EquityInstrumentUpdated,
      AnalyticsSettingsEvents.BondInstrumentSaved,
      AnalyticsSettingsEvents.BondInstrumentLoaded,
    ];

    this._presenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => relevantEventForModelUpdatedIds.includes(event?.eventId)))
      .subscribe(evt => {
        this.getTermsSummary();
      });

    if (this.instrumentTypeValue) {
      this.isLoading = true;
      this._changeDetectorRef.detectChanges();

      this.instrumentType = this.instrumentTypeValue;
      this.initializeState();
      this.getTermsSummary();

      this.isLoading = false;
      this.detectChanges();
    }
  }

  /**
   * Initializes state.
   */
  initializeState() {
    this.visibleFieldsDict = LvUtil.toDictionary(this.widgetState.selectedItems, null, true);
  }

  /**
   * Gets field type.
   * @param fieldName Field name.
   * @returns Field type.
   */
  getFieldType(fieldName: string): BasicTermFieldType {
    const fieldValue = this.basicTerms[fieldName];
    if (this.dateFieldsDict[fieldName]) {
      return fieldValue === 'Perpetual' ? 'string' : 'date';
    }

    if (typeof fieldValue === 'number') {
      return 'number';
    }

    return 'string';
  }

  /**
   * Initializes fields.
   */
  initializeFields() {
    this.basicTerms = this.basicTerms || {} as IBasicTerms;

    const currency = this.instrumentType === 'Bond' ? ` (${this.basicTerms.currencyCode || 'N/A'})` : ` (${this.basicTerms.conversionPriceCcy || 'N/A'})`;
    const percent = ' (%)';

    let columnLength = Object.keys(this.visibleFieldsDict).length;

    if (!this.basicTerms.isPeps) {
      const pepsFields = Object.keys(this.pepsFieldsDict).filter(x => this.pepsFieldsDict[x] === 'empty' && this.visibleFieldsDict[x]);

      columnLength = (columnLength - pepsFields.length);
    }

    columnLength = columnLength / 2;

    this.basicTermsViewColumns = [{ data: [] }, { data: [] }];

    Object.keys(this.visibleFieldsDict)
      .forEach((a, i) => {
        const fieldType = this.getFieldType(a);
        let fieldValue = this.basicTerms[a] || null;
        let fieldLabel = BasicTermsEnum[a];
        let visible = true;

        if (this.pepsFieldsDict[a]) {
          visible = this.pepsFieldsDict[a] !== 'empty' || this.basicTerms.isPeps;
          if (this.pepsFieldsDict[a] !== 'empty') {
            fieldLabel = `${this.basicTerms.isPeps ? this.pepsFieldsDict[a] : ''}${fieldLabel}`;
          }
        }

        if (this.currencyFieldsDict[a]) {
          fieldLabel = `${fieldLabel}${currency}`;
        }

        if (this.percentOfNominalFields[a]) {
          if (this.instrumentType === 'Bond') {
            if (a === 'issueValue') {
              const isPercent = this._presenter.bondSession?.terms?.general.issuePriceAsPar;
              fieldLabel = `${fieldLabel}${isPercent ? percent : currency}`;
            }

            if (a === 'redemptionValue') {
              const isPercent = this._presenter.bondSession?.terms?.general.redemptionValueAsPar;
              fieldLabel = `${fieldLabel}${isPercent ? percent : currency}`;
            }
          }
          else {
            const fieldIsPercentOfNominal = !!this.basicTerms[`${a}${this.percentOfNominalFields[a]}`];
            fieldLabel = `${fieldLabel}${fieldIsPercentOfNominal ? percent : currency}`;
          }
        }

        if (this.dateFieldsDict[a] && fieldType === 'date') {
          const date = new Date(fieldValue);

          if (date.getTime() === 0) { // 1/1/1970
            fieldValue = null;
          }
        }

        if (visible) {
          const field = {
            name: a,
            type: fieldType,
            value: fieldValue,
            label: fieldLabel
          } as IBasicTermField;

          this.basicTermsViewColumns[i < columnLength ? 0 : 1].data.push(field);
        }
      });
  }

  /**
   * Gets terms tooltip ID.
   * @param element HTML element.
   * @returns Terms tooltip ID.
   */
  getTermsTootlipId(element: ElementRef<HTMLElement>) {
    return element.nativeElement.getAttribute('terms-tooltip-id');
  }

  /**
   * Gets terms summary.
   */
  async getTermsSummary() {
    try {
      this.isLoading = true;
      this._changeDetectorRef.detectChanges();

      this.basicTerms = {} as IBasicTerms;
      this.didNotAuthorized.next(false);

      if (this.instrumentTypeValue === 'Bond') {
        if (this._presenter.bondSession?.terms && this._presenter.bondSession?.sessionId) {
          this.basicTerms = await this._presenter.getBondInstrumentInfo();
        }
      }
      else if (this.instrumentTypeValue === 'ConvertibleBond') {
        if (this._presenter.isConvertibleModelLoaded() && this._presenter.getModelData().valuationSession?.sessionId) {
          this.basicTerms = await this._service.getInstrumentInfo(
            this._presenter.getModelData().valuationSession?.leversysLocalId ?? 'draft',
            this._presenter.getModelData().valuationSession?.sessionId,
            !!this._presenter ? this._presenter.draftId : null);
        }
      }

      this.initializeFields();
    }
    catch (error) {
      this.errorService.handleError(error, e => {
        if (e.type === LvErrorType.AUTHORIZATION) {
          this.didNotAuthorized.next(true);
        }
        if (e.type === LvErrorType.CONFLICT) {
          console.log(e);
        }
        else {
          this.errorService.toastrService.error(e.message, e.name);
        }
      });
    }
    finally {
      this.isLoading = false;
      this.detectChanges();
    }
  }

  /**
   * Occurs on edit custom view.
   */
  onEditCustomView() {
    const dialogRef = this.dialogService.open({
      title: 'Instrument Info Data',
      content: LvBasicTermsDialogComponent
    });

    const dialog = dialogRef.content.instance as LvBasicTermsDialogComponent;
    dialog.isPeps = this.basicTerms.isPeps;
    dialog.widgetState = _.cloneDeep(this.widgetState);
    dialog.instrumentType = this.instrumentType;

    dialog.didSave.subscribe((updatedState) => {
      this.widgetState = updatedState;
      this.widgetState.useDefaultView = false;
      this._widgetStateManagerService.saveWidgetState(this.widgetType, this.widgetState);
      this._widgetStateManagerService.useCustomView(this.widgetType);
      this.initializeState();
      this.initializeFields();
      this._changeDetectorRef.detectChanges();
    });
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  onDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Occurs on view changed. Initializes state and fields.
   */
  viewChanged() {
    this.initializeState();
    this.initializeFields();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Checks if field label is 'Pledged shares (%)'.
   * @param fieldLabel Field label.
   * @returns A flag indicating if field label is 'Pledged shares (%)'.
   */
  isPledgedShares(fieldLabel: string) {
    return fieldLabel === 'Pledged Shares (%)';
  }

  /**
   * Checks if field label is 'Exchangeable'.
   * @param fieldLabel Field label.
   * @returns A flag indicating if field label is 'Exchangeable'.
   */
  isExchangable(fieldLabel: string) {
    return fieldLabel === 'Exchangeable';
  }

  /**
   * Displays exchangeable values.
   * @param fieldValue Field value.
   * @returns Exchangeable value.
   */
  displayExchangeableValues(fieldValue: string): string {
    if (fieldValue === 'Non Exchangeable') {
      return 'No';
    }
    else if (fieldValue === 'Pledged Shares') {
      return 'Pledged Shares';
    }
    else if (fieldValue === 'Recovery Value Par') {
      return 'Recovery Par';
    }
    else if (fieldValue === 'Recovery Value Max') {
      return 'Recovery Max(Parity, Par)';
    }
  }

  /**
   * Checks if field label is 'Conversion Ratio Adjustment'.
   * @param fieldValue Field value.
   * @returns A flag indicating if field label is 'Conversion Ratio Adjustment'.
   */
  isDividentProtCRAdjustment(fieldValue: string): boolean {
    return fieldValue === 'Conversion Ratio Adjustment';
  }

  /**
   * Checks if field label is 'Dividend Protection Type'.
   * @param fieldValue Field value.
   * @returns A flag indicating if field label is 'Dividend Protection Type'.
   */
  isDvidendProtectionType(type: string): boolean {
    return type === 'Dividend Protection Type';
  }

  /**
   * Detects changes.
   */
  private detectChanges() {
    if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Handle updated event and reload instrument info.
   * @param evt Event form presenter.
   * @param eventType Event type.
   */
  private handleModelUpdate(evt: any) {
    if (evt && !!evt?.eventId) {
      return;
    }

    this.instrumentInfo = {
      lwsIdentifier: this._presenter.getInstrumentIdentifier(),
      sessionId: this._presenter.sessionId,
      isPrivateInstrument: this._presenter.isPrivateInstrument
    };
  
    this.initializeState();
    this.initializeFields();
    this.detectChanges();
  }
}

/**
 * Gets initial basic terms widget state.
 * @param isOpenedFromExcel A flag indicating if instrument is opened from Excel.
 * @returns ITermsSummaryState object.
 */
function getInitialBasicTermsWidgetState(isOpenedFromExcel: boolean, instrumentType: string): ITermsSummaryState {
  if (instrumentType === 'Bond') {
    return {
      useDefaultView: true,
      availableItems: [],
      selectedItems: ['shortName', 'issuerName', 'currencyCode', 'firstSettlement', 'maturity', 'nominal', 'issueValue', 'redemptionValue',
      'sinkable', 'couponType', 'coupon', 'couponFrequency', 'couponDayCount', 'accrualMethod', 'firstCouponPaymentDate', 'put', 'call',
      'leversysId', 'isin', 'cusip', 'figi', 'permId', 'bondCode'],
      isOpenedFromExcel: isOpenedFromExcel
    } as ITermsSummaryState;
  }
  return {
    useDefaultView: true,
    availableItems: ['accrualMethod', 'contigentConversion', 'countryCode', 'protectionType', 'exchangeableType', 'firstCouponPaymentDate',
      'firstSettlement', 'fixedFxRate', 'isin', 'initialPremium', 'issueValue', 'issueSeries', 'percentOfPledgedShares',
      'redemptionValue', 'resettable', 'riskCountry', 'shortName', 'sinkable', 'leversysId', 'underlyingIsin', 'underlyingExchange',
      'couponDayCount', 'underlyingBloombergTicker', 'cusip', 'underlyingCUSIP', 'figi', 'underlyingFIGI', 'permId', 'underlyingPermId', 'underlyingCompositeTicker'
    ],
    selectedItems: ['nominal', 'currencyCode', 'region', 'conversionRatio', 'maturity', 'coupon', 'couponFrequency', 'issueSize',
      'conversionPrice', 'expectedCP', 'couponType', 'put', 'call', 'expectedCR', 'underlying', 'issuerName', 'mandatoryConversion',
      'variableConversion', 'dividendProtection', 'useTakeoverProtection', 'minConversionPrice', 'maxConversionRatio',
      'minExpectedConversionPrice', 'maxExpectedConversionRatio'],
    isOpenedFromExcel: isOpenedFromExcel
  } as ITermsSummaryState;
}

function getInstrumentType(_presenter: LvAnalyticsPresenter): string {
  if (!!_presenter.bondSession) {
    return 'Bond';
  }

  return 'ConvertibleBond';
}
