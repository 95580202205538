import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { EquityOptionCreditModelType, EquityOptionCreditModelTypeDescription, EquityOptionDilutionModel, EquityOptionDilutionModelDescription, SoftCallModelType, SoftCallModelTypeDescription } from '@lv-analytics/models';
import { IEquityOptionModelCustomizationSectionSettings } from '@lv-analytics/models/equity-option/model-customization/model-customization-settings/equity-option-model-customization-section-settings';
import { IEquityOptionCallAdjustmentsModelSettings } from '@lv-analytics/models/model-customization-settings/equity-option/equity-option-call-adjustments-model-settings';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-equity-option-model-customization-section-settings',
  templateUrl: './lv-equity-option-model-customization-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityOptionModelCustomizationSectionSettingsComponent {

  @Input() settings: IEquityOptionModelCustomizationSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  /**
   * Get call adjustment model settings if soft call model is soft call trigger.
   */
  get isSoftCallTrigger(): boolean {
    return this.settings.callAdjustmentModelSettings.softCallModelType === SoftCallModelTypeDescription.SoftCallTrigger.split(' ').join('');
  }

  numberOfDecimals = '4';
  numberFormat = '#.####';

  creditModelType: LvLookupEnum;
  softCallModelType: LvLookupEnum;

  dilutionModel: LvLookupEnum;
  checkBoxId: string;

  constructor() {
    this.overrideSystemDefaults = true;
    this.creditModelType = new LvLookupEnum(EquityOptionCreditModelTypeDescription)
    this.checkBoxId = v4();
    this.dilutionModel = new LvLookupEnum(EquityOptionDilutionModelDescription);
    this.softCallModelType = new LvLookupEnum(SoftCallModelTypeDescription);
    this.softCallModelType.setFilterFn(item => {
      if (item.id === SoftCallModelType.FullImplementation) {
        return false;
      }
      return true;
    });
    this.settings = {
      creditModelType: '' as EquityOptionCreditModelType,
      equityOptionDilutive: false,
      dilutionModel: '' as EquityOptionDilutionModel,
      callAdjustmentModelSettings: {
        softCallAdjustmentFactor: 0,
        softCallModelType: '' as SoftCallModelType
      } as IEquityOptionCallAdjustmentsModelSettings,
    } as IEquityOptionModelCustomizationSectionSettings;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--row')
  get isFlexColumnComponent() {
    return false;
  }

  @HostBinding('class.lv-model-customization-equity-option-parts')
  get isLvEquityOptionModelCustomizationSectionSettingsComponent() {
    return true;
  }
  /**
   * Gets checkbox ID..
   * @param name Checkbox name.
   * @returns Checkbox ID.
   */
  getCheckBoxId(name: string): string {
    return `${name}-${this.checkBoxId}`;
  }
}