import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IEquityOptionVolatilitySectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-volatility-settings/market-data-equity-option-volatility-section-settings';
import { VolatilitySurfaceRiskyTypeDescription, VolatilityType, VolatilityTypeDescription } from '@lv-analytics/models/market-data/volatility/volatility-enums';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-equity-option-volatility-section-settings',
  templateUrl: './lv-equity-option-volatility-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvEquityOptionVolatilitySectionSettingsComponent {
  @Input() settings: IEquityOptionVolatilitySectionSettings;
  @Input() overrideSystemDefaults: boolean;

  volTypeEnum: LvLookupEnum;

  constructor() {
    this.volTypeEnum = new LvLookupEnum(VolatilityTypeDescription);
    this.overrideSystemDefaults = true;

    this.volTypeEnum.setFilterFn(item => {
      if (item.id === VolatilityType.UpsideDownside) {
        return false;
      }
      return true;
    });
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }
}
