import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { DividendFrequencyEnum, DividendsSourceEnum, DividendsSourceEnumDescription } from '@lv-analytics/models';
import { IEquityOptionDividendsSectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-dividend-settings/market-data-equity-option-dividends-section-settings';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-equity-option-dividends-section-settings',
  templateUrl: './lv-equity-option-dividends-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEquityOptionDividendsSectionSettingsComponent {
  @Input() settings: IEquityOptionDividendsSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  get isFrequencyDisabled(): boolean {
    return this.settings.dividendsSource !== DividendsSourceEnum.Parameters || !this.overrideSystemDefaults;
  }

  dividendSource: LvLookupEnum;
  dividendFrequency: LvLookupEnum;

  numberOfDecimals = '4';
  numberFormat = '#.####';

  constructor(
  ) {
    this.dividendSource = new LvLookupEnum(DividendsSourceEnumDescription);
    this.dividendFrequency = new LvLookupEnum(DividendFrequencyEnum);
    this.overrideSystemDefaults = true;

    this.settings = {} as IEquityOptionDividendsSectionSettings;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }
}