import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IEquityOptionBorrowSectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-borrow-settings/market-data-equity-option-borrow-section-settings';
import { IEquityOptionBorrowSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-borrow-settings/market-data-equity-option-borrow-settings';

@Component({
  selector: 'lv-equity-option-borrow-settings',
  templateUrl: './lv-equity-option-borrow-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEquityOptionBorrowSettingsComponent {
  @Input() settings: IEquityOptionBorrowSettings;

  constructor() {
    this.settings = {
      systemDefaults: {} as IEquityOptionBorrowSectionSettings,
      environments: []
    };
  }

  @HostBinding('class.lv-borrow-settings')
  get isLvBorrowSettingsComponent() {
    return true;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}

