import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { AccruedOnDefaultDescription, CreditSourceDescription, DateAdjustmentDescription, } from '@lv-analytics/models';
import { IEquityOptionCreditSectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-credit-settings/market-data-equity-option-credit-section-settings';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-equity-option-credit-section-settings',
  templateUrl: './lv-equity-option-credit-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityOptionCreditSectionSettingsComponent {
  @Input() settings: IEquityOptionCreditSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  creditSource: LvLookupEnum;
  badDayConvention: LvLookupEnum;
  accruedOnDefault: LvLookupEnum;

  numberOfDecimals = '4';
  numberFormat = '#.####';

  constructor() {
    this.creditSource = new LvLookupEnum(CreditSourceDescription);
    this.badDayConvention = new LvLookupEnum(DateAdjustmentDescription);
    this.accruedOnDefault = new LvLookupEnum(AccruedOnDefaultDescription);

    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return false;
  }
}

