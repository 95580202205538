export enum EquityOptionType {
    Equity = 'Equity',
    EquityWarrant = 'EquityWarrant',
    Future = 'Future',
}

export enum EquityOptionTypeDescription {
    Equity = 'Equity',
    EquityWarrant = 'Equity Warrant',
    Future = 'Future',
}