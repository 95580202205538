<lv-loader *ngIf="isLoading"></lv-loader>

<lv-settings-widget class="lv-model-customization"
  data-cy="DM-1214"
  titleText="Model Customization"
  [isLoading]="isLoading"
  [showSettings]="showSettings">
  <ng-container content>

    <div class="lv-model-customization-content" #modelCustomizationContent>
      <lv-environment-settings
        [section]="modelCustomizationSection"
        [showControls]="true"
        [saveToExcel]="isOpenedFromExcel"
        instrumentType="ConvertibleBond"
        lvId="lv-model-customization-environment-settings"
        (didChangeEnvironment)="onChangeEnvironment($event)"
        (doSave)="onSaveSection()"
        (doSaveToExcel)="onSaveSectionToExcel()"
        (doReload)="onReloadSection()">
      </lv-environment-settings>

      <lv-flex-box direction="row" class="lv-model-customization-content-scroll" style="flex: 1; overflow: auto; flex-wrap: wrap;">

        <lv-flex-box
          *ngIf="widgetState.excludeTerms.isExcludeTerms || widgetState.modelType.isModelType"

          [style.min-width]="view.excludeTermsModelTypeMinWidth"
          class="lv-model-customization-exclude-terms-model-type-section"
          [class.lv-model-customization-exclude-terms-model-type-section--column]="view.excludeTermsModelTypeDirection === 'column'"
          [class.lv-model-customization-exclude-terms-model-type-section--row]="view.excludeTermsModelTypeDirection === 'row'">

          <lv-flex-box>
            <lv-flex-box
              class="lv-model-customization-exclude-terms-section"
              *ngIf="widgetState.excludeTerms.isExcludeTerms">

              <!-- EXCLUDE TERMS -->
              <div
                *ngIf="view.shouldShowExcludeTerms"
                class="lv-label lv-label-field lv-label--title"
                lvId="lv-model-customization-exclude-terms-section">
                EXCLUDE TERMS
              </div>

              <!-- Call -->
              <lv-flex-box
                *ngIf="widgetState.excludeTerms.isCall && view.isCallable"
                data-cy="DM-734"
                direction="row">
                <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callTooltipTemplate"
                filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'callTooltip'">
                Call
              </span>
                  <lv-xl-label [field]="'MC_CALL'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="exCall"
                    type="checkbox"
                    [disabled]="view.isModelCustomizationDisabled"
                    [attr.id]="view.chCallId"
                    class="k-checkbox"
                    [(ngModel)]="view.modelSettings.excludeCall"
                    (change)="onModelCustomizationChange()" />
                  <label class="k-checkbox-label"
                    lvId="exCall"
                    [attr.for]="view.chCallId"></label>
                </div>
              </lv-flex-box>

              <!-- Put -->
              <lv-flex-box
                *ngIf="widgetState.excludeTerms.isPut && view.isPuttable"
                data-cy="DM-735"
                direction="row">
                <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="putTooltipTemplate"
                filter="span:not(lv-xl-label span)">
                <span [attr.averaging-data-tooltip-id]= "'putTooltip'">
                  Put
                </span>
                  <lv-xl-label [field]="'MC_PUT'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="exPut"
                    type="checkbox"
                    [attr.id]="view.chPutId"
                    [disabled]="view.isModelCustomizationDisabled"
                    class="k-checkbox"
                    [(ngModel)]="view.modelSettings.excludePut"
                    (change)="onModelCustomizationChange()"/>
                  <label class="k-checkbox-label"
                    lvId="exPut"
                    [attr.for]="view.chPutId"></label>
                </div>
              </lv-flex-box>

              <!-- Dividend Protection -->
              <lv-flex-box
                *ngIf="widgetState.excludeTerms.isDividendProtection && view.isDividendProtection"
                data-cy="DM-736"
                direction="row">
                <div class="lv-label lv-label-field"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dividendProtectionTooltipTemplate"
                  filter="span:not(lv-xl-label span)">
                  <span [attr.averaging-data-tooltip-id]= "'dividendProtectionTooltip'">
                    Dividend Protection
                  </span>
                  <lv-xl-label [field]="'MC_DVD_PROT'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="exDividendProtection"
                    type="checkbox"
                    [attr.id]="view.chDividendProtectionId"
                    [disabled]="view.isModelCustomizationDisabled"
                    class="k-checkbox"
                    [(ngModel)]="view.modelSettings.excludeDividendProtection"
                    (change)="onModelCustomizationChange()"/>
                  <label class="k-checkbox-label"
                    lvId="exDividendProtection"
                    [attr.for]="view.chDividendProtectionId"></label>
                </div>
              </lv-flex-box>

              <!-- Contingent Conversion -->
              <lv-flex-box
                *ngIf="widgetState.excludeTerms.isContingentConversion && view.isContingentConversion"
                data-cy="DM-740"
                direction="row">
                <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="contigentConversionTooltipTemplate"
                filter="span:not(lv-xl-label span)">
                <span [attr.averaging-data-tooltip-id]= "'contigentConversionTooltip'">
                  Contingent Conversion
                </span>
                  <lv-xl-label [field]="'MC_COV_CONTG'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="exContigentConversion"
                    type="checkbox"
                    [attr.id]="view.chContigentConversionId"
                    [disabled]="view.isModelCustomizationDisabled"
                    class="k-checkbox"
                    [(ngModel)]="view.modelSettings.excludeContigentConversion"
                    (change)="onModelCustomizationChange()"/>
                  <label class="k-checkbox-label"
                    lvId="exContigentConversion"
                    [attr.for]="view.chContigentConversionId"></label>
                </div>
              </lv-flex-box>

              <!-- Conversion Price Reset -->
              <lv-flex-box
                *ngIf="widgetState.excludeTerms.isConversionPriceReset && view.isResettable"
                data-cy="DM-737"
                direction="row">
                <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="conversionPriceResetTooltipTemplate"
                filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'conversionPriceResetTooltip'">
                Conversion Price Reset
              </span>
                  <lv-xl-label [field]="'MC_COV_RESET'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="exConversionPriceReset"
                    type="checkbox"
                    [attr.id]="view.chConversionPriceResetId"
                    [disabled]="view.isModelCustomizationDisabled"
                    class="k-checkbox"
                    [(ngModel)]="view.modelSettings.excludeConversionPriceReset"
                    (change)="onModelCustomizationChange()" />
                  <label class="k-checkbox-label"
                    lvId="exConversionPriceReset"
                    [attr.for]="view.chConversionPriceResetId"></label>
                </div>
              </lv-flex-box>

              <!-- Variable Conversion -->
              <lv-flex-box
                *ngIf="widgetState.excludeTerms.isVariableConversion && view.isVariableConversion"
                data-cy="DM-738"
                direction="row">
                <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="variableConversionTooltipTemplate"
                filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'variableConversionTooltip'">
                Variable Conversion
              </span>
                  <lv-xl-label [field]="'MC_CON_VAR'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="exVariableConversion"
                    type="checkbox"
                    [attr.id]="view.chVariableConversionId"
                    [disabled]="view.isModelCustomizationDisabled"
                    class="k-checkbox"
                    [(ngModel)]="view.modelSettings.excludeVariableConversion"
                    (change)="onModelCustomizationChange()"/>
                  <label class="k-checkbox-label"
                    lvId="exVariableConversion"
                    [attr.for]="view.chVariableConversionId"></label>
                </div>
              </lv-flex-box>

              <!-- Current Conv. Averaging -->
              <lv-flex-box
                *ngIf="widgetState.excludeTerms.isCurrentConversionAveraging && (view.isCash || view.isAcquisitionSharesSettlement)"
                data-cy="DM-187"
                direction="row">
                <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="currentConversionAveragingTooltipTemplate"
                filter="span:not(lv-xl-label span)">
                <span [attr.averaging-data-tooltip-id]= "'currentConversionAveragingTooltip'">
                  Current Conv. Averaging
                </span>
                  <lv-xl-label [field]="'MC_CURR_CONV_AVG'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="currentConversionAveraging"
                    class="k-checkbox"
                    type="checkbox"
                    [disabled]="view.isModelCustomizationDisabled"
                    [attr.id]="view.chCurrentConversionAveragingId"
                    [(ngModel)]="view.modelSettings.currentConversionAveraging"
                    (change)="onModelCustomizationChange()"/>
                  <label class="k-checkbox-label"
                    lvId="currentConversionAveraging"
                    [attr.for]="view.chCurrentConversionAveragingId"></label>
                </div>
              </lv-flex-box>

              <!-- Current Reset Averaging -->
              <lv-flex-box
                *ngIf="widgetState.excludeTerms.isCurrentResetAveraging && view.isResettable"
                data-cy="DM-188"
                direction="row">
                <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="currentResetAveragingTooltipTemplate"
                filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'currentResetAveragingTooltip'">
                Current Reset Averaging
              </span>
                  <lv-xl-label [field]="'MC_CURR_RESET_AVG'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="currentResetAveraging"
                    class="k-checkbox"
                    type="checkbox"
                    [disabled]="view.isModelCustomizationDisabled"
                    [attr.id]="view.chCurrentResetAveragingId"
                    [(ngModel)]="view.modelSettings.currentResetAveraging"
                    (change)="onModelCustomizationChange()"/>
                  <label class="k-checkbox-label"
                    lvId="currentResetAveraging"
                    [attr.for]="view.chCurrentResetAveragingId"></label>
                </div>
              </lv-flex-box>
            </lv-flex-box>

            <lv-flex-box
            class="lv-model-customization-include-terms-section"
            *ngIf="widgetState.includeTerms.isIncludeTerms">

              <!-- INCLUDE TERMS -->
              <div
                *ngIf="view.shouldShowIncludeTerms"
                class="lv-label lv-label-field lv-label--title"
                lvId="lv-model-customization-include-terms-section">
                INCLUDE TERMS
              </div>

              <!-- Forward Conv. Averaging -->
              <lv-flex-box
                *ngIf="widgetState.includeTerms.isForwardConversionAveraging && (view.isCash || view.isAcquisitionSharesSettlement)"
                data-cy="DM-189"
                direction="row">
                <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="forwardConversionAveragingTooltipTemplate"
                filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'forwardConversionAveragingTooltip'">
                Forward Conv. Averaging
              </span>
                  <lv-xl-label [field]="'MC_FWD_CONV_AVG'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="forwardConversionAveraging"
                    class="k-checkbox"
                    type="checkbox"
                    [disabled]="view.isModelCustomizationDisabled"
                    [attr.id]="view.chForwardConversionAveragingId"
                    [(ngModel)]="view.modelSettings.forwardConversionAveraging"
                    (change)="onModelCustomizationChange()"/>
                  <label class="k-checkbox-label"
                    lvId="forwardConversionAveraging"
                    [attr.for]="view.chForwardConversionAveragingId"></label>
                </div>
              </lv-flex-box>

              <!-- Forward Reset Averaging -->
              <lv-flex-box
                *ngIf="widgetState.includeTerms.isForwardResetAveraging && view.isResettable"
                data-cy="DM-190"
                direction="row">
                <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="forwardResetAveragingTooltipTemplate"
                filter="span:not(lv-xl-label span)">
                <span [attr.averaging-data-tooltip-id]= "'forwardResetAveragingTooltip'">
                  Forward Reset Averaging
                </span>
                  <lv-xl-label [field]="'MC_FWD_RESET_AVG'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input name="forwardResetAveraging"
                    class="k-checkbox"
                    type="checkbox"
                    [disabled]="view.isModelCustomizationDisabled"
                    [attr.id]="view.chForwardResetAveragingId"
                    [(ngModel)]="view.modelSettings.forwardResetAveraging"
                    (change)="onModelCustomizationChange()"/>
                  <label class="k-checkbox-label"
                    lvId="forwardResetAveraging"
                    [attr.for]="view.chForwardResetAveragingId"></label>
                </div>
              </lv-flex-box>
            </lv-flex-box>
          </lv-flex-box>
          
          <lv-flex-box
            *ngIf="widgetState.modelType.isModelType"
            class="lv-model-customization-model-type-section">

            <!-- MODEL TYPE -->
            <div
              *ngIf="view.shouldShowModelType"
              class="lv-label lv-label-field lv-label--title"
              lvId="lv-model-customization-model-type-section">
              MODEL TYPE
            </div>

            <!-- Pricer Version -->
            <lv-flex-box
              *ngIf="widgetState.modelType.isPricerVersion"
              data-cy="DM-1067"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="modelCustomizationPricerVersionTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'modelCustomizationPricerVersionTooltip'">
                Pricer Version
              </span>
              <lv-xl-label [field]="'MC_PRICER_VERSION'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbPricerVersion"
                  [data]="view.pricerVersion.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.pricerVersion"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 150
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- Credit Model Type -->
            <lv-flex-box
              *ngIf="widgetState.modelType.isCreditModelType"
              data-cy="DM-165"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="creditModelTypeTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'creditModelTypeTooltip'">
                Credit Model Type
              </span>
                <lv-xl-label [field]="'MC_CREDIT_MOD'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbCreditModelType"
                  [data]="view.creditModelTypeLookup.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.creditModelSettings.creditModelType"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 150
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- Equity Model Type -->
            <lv-flex-box
              data-cy="DM-166"
              *ngIf="widgetState.modelType.isEquityModelType && !view.isExchangeable"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="equityModelTypeTooltipTemplate"
              filter="span:not(lv-xl-label span)">
                <span [attr.averaging-data-tooltip-id]= "'equityModelTypeTooltip'">
                  Equity Model Type
                </span>
                <lv-xl-label [field]="'MC_EQUITY_MD'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbEquityModelType"
                  [data]="view.equityModelTypeLookup.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.creditModelSettings.equityModelType"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 130
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- Underlying Credit Model -->
            <lv-flex-box
              *ngIf="widgetState.modelType.isUnderlyingCreditModel && view.isExchangeable"
              data-cy="DM-167"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="underlyingModelTypeTooltipTemplate"
              filter="span:not(lv-xl-label span)">
            <span [attr.averaging-data-tooltip-id]= "'underlyingModelTypeTooltip'">
              Und. Credit Model
            </span>
                <lv-xl-label [field]="'MC_UND_CREDIT_MOD'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbUndCreditModel"
                  [data]="view.underlyingModelTypeLookup.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.creditModelSettings.underlyingCreditModelType"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 150
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- Stochastic Credit -->
            <lv-flex-box
              data-cy="DM-168"
              *ngIf="widgetState.modelType.isStochasticCredit && view.isTwoFactorModel"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="stochasticCreditTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'underlyingModelTypeTooltip'">
                Stochastic Credit
              </span>
                <lv-xl-label [field]="'MC_STOCH_CREDIT'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbStochasticCredit"
                  [data]="view.stochasticCredit.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.creditModelSettings.stochasticCreditModelType"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 130
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- No. Credit Points -->
            <lv-flex-box
              data-cy="DM-169"
              *ngIf="widgetState.modelType.isNumberOfCreditPoints && view.isTwoFactorModel"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="noCreditPointsTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span [attr.averaging-data-tooltip-id]= "'noCreditPointsTooltip'">
               No. Credit Points
              </span>
                <lv-xl-label [field]="'MC_CREDIT_POINTS'"></lv-xl-label>
              </div>
              <lv-numeric-text-box
                name="cbNoCreditPoints"
                format="n0"
                decimals="0"
                [disabled]="view.isModelCustomizationDisabled"
                [(ngModel)]="view.modelSettings.creditModelSettings.numPointsStochasticCredit"
                (didKeyDownEnter)="onModelCustomizationInputKeyDown()"
                (didBlur)="onModelCustomizationInputBlur()">
              </lv-numeric-text-box>
            </lv-flex-box>

            <!-- Stochastic Credit BC -->
            <lv-flex-box
              data-cy="DM-170"
              *ngIf="widgetState.modelType.isStochasticCreditBC && view.isTwoFactorModel"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="stochasticCreditBCTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span [attr.averaging-data-tooltip-id]= "'stochasticCreditBCTooltip'">
               Stochastic Credit BC
              </span>
                <lv-xl-label [field]="'MC_STOCH_CREDIT_BC'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbStochasticCreditBC"
                  [data]="view.stochasticCreditBC.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.creditModelSettings.stochasticCreditBCType"
                  (ngModelChange)="onModelCustomizationChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

          </lv-flex-box>
        </lv-flex-box>

        <lv-flex-box

          [style.min-width]="view.callAveragingOtherMinWidth"
          class="lv-model-customization-call-averaging-other-section"
          [class.lv-model-customization-call-averaging-other-section--column]="view.excludeTermsModelTypeDirection === 'column'"
          [class.lv-model-customization-call-averaging-other-section--row]="view.excludeTermsModelTypeDirection === 'row'"
          #callAveragingOtherSection>

          <lv-flex-box
          *ngIf="widgetState.callAdjustments.isCallAdjustments && view.isCallable"
          class="lv-model-customization-call-averaging-section">

            <!-- CALL ADJUSTMENTS -->
            <div
              *ngIf="view.shouldShowCallAdjustments"
              class="lv-label lv-label-field lv-label--title"
              lvId="lv-model-customization-call-adjustments-section">
              CALL ADJUSTMENTS
            </div>

            <!-- Hard Call Model Type -->
            <lv-flex-box
              *ngIf="widgetState.callAdjustments.isHardCallModelType"
              data-cy="DM-171"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="hardCallModelTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span [attr.averaging-data-tooltip-id]= "'hardCallModelTooltip'">
                Call Model Type
              </span>
                <lv-xl-label [field]="'MC_HC_MODEL'"></lv-xl-label>
              </div>
              <div class= "lv-input-field lv-input-field--auto">
                <kendo-dropdownlist
                  name="caHardCallModelType"
                  [data]="view.hardCallModelType.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.callAdjustmentModelSettings.hardCallModelType"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 160
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- Hard Call Adjust Factor -->
            <lv-flex-box
              *ngIf="widgetState.callAdjustments.isHardCallAdjustFactor && view.isBarierModel"
              data-cy="DM-172"
              format="n3"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="hardCallAdjustTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span  [attr.averaging-data-tooltip-id]= "'hardCallModelTooltip'">
                Call Adjust Factor
              </span>
                <lv-xl-label [field]="'MC_HC_ADJUST'"></lv-xl-label>
              </div>
              <div class="lv-label-field">
              <lv-numeric-text-box
                name="caHardCallAdjustFactor"
                [disabled]="view.isModelCustomizationDisabled || !view.isBarierModel"
                format="n3"
                [(ngModel)]="view.modelSettings.callAdjustmentModelSettings.hardCallAdjustmentFactor"
                (didKeyDownEnter)="onModelCustomizationInputKeyDown()"
                (didBlur)="onModelCustomizationInputBlur()">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">%</span>
              </div>
               </lv-flex-box>

            <!-- Soft Call Model Type -->
            <lv-flex-box
              *ngIf="widgetState.callAdjustments.isSoftCallModelType"
              data-cy="DM-680"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="softCallModelTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span  [attr.averaging-data-tooltip-id]= "'softCallModelTooltip'">
                {{view.softCallModelTypeText}}
              </span>
                <lv-xl-label [field]="'MC_SC_MODEL'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="caSoftCallModelType"
                  [data]="view.softCallModelType.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.callAdjustmentModelSettings.softCallModelType"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 145
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- Soft Call Adjust Factor -->
            <lv-flex-box
              *ngIf="view.isSoftCallTrigger"
              data-cy="DM-173"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="softCallAdjustTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span  [attr.averaging-data-tooltip-id]= "'softCallAdjustTooltip'">
               {{view.softCallAdjModelTypeText}}
              </span>
                <lv-xl-label [field]="'MC_SC_ADJUST'"></lv-xl-label>
              </div>
              <div class="lv-label-field">
              <lv-numeric-text-box
                name="caSoftCallAdjustFactor"
                [disabled]="view.isModelCustomizationDisabled || !view.isSoftCallTrigger"
                format="n3"
                [(ngModel)]="view.modelSettings.callAdjustmentModelSettings.softCallAdjustmentFactor"
                (didKeyDownEnter)="onModelCustomizationInputKeyDown()"
                (didBlur)="onModelCustomizationInputBlur()">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">%</span>
               </div>
            </lv-flex-box>

            <!-- Not Callable (Date) -->
            <lv-flex-box
              *ngIf="widgetState.callAdjustments.isNotCallableDate"
              data-cy="DM-183"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="useNotCallableDateTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'useNotCallableDateTooltipTemplate'">
                Not Callable (Date)
              </span>
                <lv-xl-label [field]="'MC_NOT_CALLABLE_DT'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-flex-box">
                <div class="lv-field lv-field--checkbox">
                  <input name="isNotCallableDate"
                    type="checkbox"
                    [attr.id]="view.notCallableId"
                    class="k-checkbox"
                    [(ngModel)]="view.modelSettings.isNotCallableDate"
                    (change)="onNotCallableDateChange()" />
                  <label class="k-checkbox-label"
                    lvId="isNotCallableDate"
                    [attr.for]="view.notCallableId"></label>
                </div>
                <div *ngIf="view.modelSettings.isNotCallableDate">
                  <kendo-datepicker
                    placeholder=""
                    [disabled]="!view.isNotCallableEnabled"
                    [(ngModel)]="view.modelSettings.callAdjustmentModelSettings.notCallableDate"
                    (blur)="onModelCustomizationChange()"
                    (keydown.enter)="onModelCustomizationChange()"
                    name="caNotCallableDate">
                  </kendo-datepicker>
                </div>
              </div>
            </lv-flex-box>

            <!-- Not Callable (Parity) -->
            <lv-flex-box
              *ngIf="widgetState.callAdjustments.isNotCallableParity"
              data-cy="DM-184"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="useNotCallableParityTooltipTemplate"
              filter="span:not(lv-xl-label span)">
            <span [attr.averaging-data-tooltip-id]= "'useNotCallableParityTooltip'">
              Not Callable (Parity)
            </span>
                <lv-xl-label [field]="'MC_NOT_CALLABLE_PTY'"></lv-xl-label>
              </div>
              <lv-numeric-text-box
                name="caNotCallableParity"
                [disabled]="!view.isNotCallableEnabled"
                format="n3"
                [(ngModel)]="view.modelSettings.callAdjustmentModelSettings.notCallableParity"
                (didKeyDownEnter)="onModelCustomizationInputKeyDown()"
                (didBlur)="onModelCustomizationInputBlur()">
              </lv-numeric-text-box>
          </lv-flex-box>
          </lv-flex-box>
          <lv-flex-box
            *ngIf="widgetState.otherParameters.isOtherParameters"
            class="lv-model-customization-other-section">

            <!-- OTHER PARAMETERS -->
            <div
              *ngIf="view.shouldShowOtherParams"
              class="lv-label lv-label-field lv-label--title"
              lvId="lv-model-customization-other-parameters-section">
              OTHER PARAMETERS
            </div>

            <!-- Dividend Protection Model -->
            <lv-flex-box
              *ngIf="widgetState.otherParameters.isDividendProtectionModel && view.isDividendProtection"
              data-cy="DM-175"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="dividendProtectionModeTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span [attr.averaging-data-tooltip-id]= "'dividendProtectionModeTooltip'">
                  {{ dividentProtectionModel}}
             </span>
            <lv-xl-label [field]="'MC_DVD_PROT_MODEL'"></lv-xl-label>
               </div>
                <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbDividendProtectionMode"
                  [data]="view.dividendProtectionMode.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.dividendProtectionModel"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 140
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- PEPS Parity Convention -->
            <lv-flex-box data-cy="DM-176"
              *ngIf="widgetState.otherParameters.isPEPSParityConvention && view.isPeps"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="pepsParityConventionTooltipTemplate"
              filter="span:not(lv-xl-label span)">
               <span [attr.averaging-data-tooltip-id]= "'pepsParityConventionTooltip'">
                 PEPS Parity Convention
                </span>
               <lv-xl-label [field]="'MC_PEPS_PARITY'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--full">
                <kendo-dropdownlist
                  name="cbPepsParityConvention"
                  [data]="view.pepsParityConvention.items"
                  [disabled]="view.isModelCustomizationDisabled"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.modelSettings.pepsParityConvention"
                  (ngModelChange)="onModelCustomizationChange()"
                  [popupSettings]="{
                    width: 175
                  }">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>

            <!-- Expected Life Excl. Default -->
            <lv-flex-box
              *ngIf="widgetState.otherParameters.isExpectedLifeExcludingDefault"
              data-cy="DM-177"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="expectedLifeTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span  [attr.averaging-data-tooltip-id]= "'expectedLifeTooltip'">
               {{expectedLifeExclDefault}}
              </span>
                <lv-xl-label [field]="'MC_EXP_LIFE'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input name="expectedLifeExcluding"
                  class="k-checkbox"
                  type="checkbox"
                  [attr.id]="view.chExpectedLifeId"
                  [disabled]="view.isModelCustomizationDisabled"
                  [(ngModel)]="view.modelSettings.expLifeExcludesDefault"
                  (change)="onModelCustomizationChange()" />
                <label class="k-checkbox-label"
                  lvId="expectedLifeExcluding"
                  [attr.for]="view.chExpectedLifeId"></label>
              </div>
            </lv-flex-box>

            <!-- Exchangable as Convert. -->
            <lv-flex-box
              *ngIf="widgetState.otherParameters.isExchangeableAsConvertible && view.isExchangeable"
              data-cy="DM-178"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="exchangeableAsConvertTooltipTemplate"
              filter="span:not(lv-xl-label span)">
            <span [attr.averaging-data-tooltip-id]= "'exchangeableAsConvertTooltip'">
              Exchangeable as Convert.
            </span>
                <lv-xl-label [field]="'MC_EXCH_AS_CONVERT'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input name="priceExchangableAsConvertible"
                  type="checkbox"
                  [attr.id]="view.chPriceExchId"
                  [disabled]="view.isModelCustomizationDisabled"
                  class="k-checkbox"
                  [(ngModel)]="view.modelSettings.priceExchangeableAsConvertible"
                  (change)="onModelCustomizationChange()"/>
                <label class="k-checkbox-label"
                  lvId="priceExchangableAsConvertible"
                  [attr.for]="view.chPriceExchId"></label>
              </div>
            </lv-flex-box>

            <!-- Default on Low Parity -->
            <lv-flex-box
              *ngIf="widgetState.otherParameters.isDefaultOnLowParity && view.isPledgedShares"
              data-cy="DM-179"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="defaultOnLowParityTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'defaultOnLowParityTooltip'">
                Default on Low Parity
              </span>
                <lv-xl-label [field]="'MC_DFLT_LOW_PARITY'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input name="defaultOnLowParity"
                  type="checkbox"
                  [attr.id]="view.chDefaultOnLowParityId"
                  [disabled]="view.isModelCustomizationDisabled"
                  class="k-checkbox"
                  [(ngModel)]="view.modelSettings.defaultOnLowParity"
                  (change)="onModelCustomizationChange()"/>
                <label class="k-checkbox-label"
                  lvId="defaultOnLowParity"
                  [attr.for]="view.chDefaultOnLowParityId"></label>
              </div>
            </lv-flex-box>

            <!-- Incl. Cash Rebate in Parity -->
            <lv-flex-box data-cy="DM-180"
              *ngIf="widgetState.otherParameters.isIncludeCashRebateInParity &&
                    (view.isRebate || (view.isCashDistribution && view.isAtConversionCashDistribution))"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="includeCashInRebateTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span  [attr.averaging-data-tooltip-id]= "'includeCashInRebateTooltip'">
              Incl. Cash Rebate in Parity
             </span>
                <lv-xl-label [field]="'MC_CASH_REBATE'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input name="includeCashRebate"
                  class="k-checkbox"
                  type="checkbox"
                  [disabled]="view.isModelCustomizationDisabled"
                  [attr.id]="view.chIncCashRebateId"
                  [(ngModel)]="view.modelSettings.includeCashRebateInParity"
                  (change)="onModelCustomizationChange()"/>
                <label class="k-checkbox-label"
                  lvId="includeCashRebate"
                  [attr.for]="view.chIncCashRebateId"></label>
              </div>
            </lv-flex-box>

            <!-- Force Optional Reset -->
            <lv-flex-box
              *ngIf="widgetState.otherParameters.isForceOptionalReset && view.isOptionalReset"
              data-cy="DM-181"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="forceOptionalResetTooltipTemplate"
              filter="span:not(lv-xl-label span)">
             <span [attr.averaging-data-tooltip-id]= "'includeCashInRebateTooltip'">
              Force Optional Reset
             </span>
                <lv-xl-label [field]="'MC_FORCE_OPT_RESET'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input name="forceOptionalReset"
                  class="k-checkbox"
                  type="checkbox"
                  [disabled]="view.isModelCustomizationDisabled"
                  [attr.id]="view.chForceOptionalResetId"
                  [(ngModel)]="view.modelSettings.forceOptionalReset"
                  (change)="onModelCustomizationChange()"/>
                <label class="k-checkbox-label"
                  lvId="forceOptionalReset"
                  [attr.for]="view.chForceOptionalResetId"></label>
              </div>
            </lv-flex-box>

            <!-- Fwd. Divid. Protection Adjst -->
            <lv-flex-box
              *ngIf="widgetState.otherParameters.isFwdDividProtectionAdjst"
              data-cy="DM-182"
              direction="row">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="fwdDividProtectionAdjstTooltipTemplate"
                filter="span:not(lv-xl-label span)">
                <span [attr.averaging-data-tooltip-id]= "'fwdDividProtectionAdjstTooltip'">
                  {{ view.fwdDividProtectionAdjstText }}
                </span>
                <lv-xl-label [field]="'MC_FWD_DIV_PROT_ADJUST'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input name="fwdDividProtectionAdjst"
                  class="k-checkbox"
                  type="checkbox"
                  [disabled]="view.isModelCustomizationDisabled"
                  [attr.id]="view.chFwdDividProtectionAdjst"
                  [(ngModel)]="view.modelSettings.fwdDividProtectionAdjst"
                  (change)="onModelCustomizationChange()"/>
                <label class="k-checkbox-label"
                  lvId="fwdDividProtectionAdjst"
                  [attr.for]="view.chFwdDividProtectionAdjst"></label>
              </div>
            </lv-flex-box>

            <!-- Maturity Date Override -->
            <lv-flex-box
              data-cy="DM-185"
              *ngIf="widgetState.otherParameters.isMaturityDateOverride"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="maturityDateOverrideTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.averaging-data-tooltip-id]= "'maturityDateOverrideTooltip'">
                Maturity Date Override
              </span>
                <lv-xl-label [field]="'MC_MAT_OV'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-flex-box">
                <div class="lv-field lv-field--checkbox">
                  <input name="isMaturityDateOverride"
                    type="checkbox"
                    [attr.id]="view.maturityDateOverrideId"
                    class="k-checkbox"
                    [(ngModel)]="view.modelSettings.isMaturityDateOverride"
                    (change)="onMaturityDateOverrideChange()" />
                  <label class="k-checkbox-label"
                    lvId="isMaturityDateOverride"
                    [attr.for]="view.maturityDateOverrideId"></label>
                  </div>
                <div *ngIf="view.modelSettings.isMaturityDateOverride">
                  <kendo-datepicker
                    placeholder=""
                    [disabled]="view.isModelCustomizationDisabled"
                    [(ngModel)]="view.modelSettings.maturityDateOverride"
                    (blur)="onModelCustomizationChange()"
                    (keydown.enter)="onModelCustomizationChange()"
                    name="caMaturityDateOverride">
                  </kendo-datepicker>
                </div>
              </div>
            </lv-flex-box>

            <!-- Conversion Notice Given -->
            <lv-flex-box
            data-cy="DM-186"
            *ngIf="widgetState.otherParameters.isConversionNoticeGiven"
            direction="row">
            <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="conversionNoticeGivenTooltipTemplate"
            filter="span:not(lv-xl-label span)">
          <span [attr.averaging-data-tooltip-id]= "'conversionNoticeGivenTooltip'">
            Conversion Notice Given
          </span>
              <lv-xl-label [field]="'MC_EARLY_CONV_DT'"></lv-xl-label>
            </div>
            <div class="lv-input-field lv-flex-box">
              <div class="lv-field lv-field--checkbox">
                <input name="isConversionNoticeGiven"
                  type="checkbox"
                  [attr.id]="view.conversionNoticeGivenId"
                  class="k-checkbox"
                  [(ngModel)]="view.modelSettings.conversionNoticeGiven"
                  (change)="onConversionNoticeGivenChange()" />
                <label class="k-checkbox-label"
                  lvId="conversionNoticeGiven"
                  [attr.for]="view.conversionNoticeGivenId"></label>
                </div>
              <div *ngIf="view.modelSettings.conversionNoticeGiven">
                <kendo-datepicker
                  placeholder=""
                  [disabled]="view.isModelCustomizationDisabled"
                  [(ngModel)]="view.modelSettings.conversionNoticeDate"
                  (blur)="onModelCustomizationChange()"
                  (keydown.enter)="onModelCustomizationChange()"
                  name="caConversionNoticeDate">
                </kendo-datepicker>
              </div>
            </div>
            </lv-flex-box>
            <!-- Assume Dvd Until Maturity -->
            <lv-flex-box
              *ngIf="widgetState.otherParameters.isAssumeDividendYieldUntilMaturity"
              data-cy="DM-1005"
              direction="row">
              <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="assumeDvdUntilMaturityTooltipTemplate"
              filter="span:not(lv-xl-label span)">
            <span [attr.averaging-data-tooltip-id]= "'assumeDvdUntilMaturityTooltip'">
              {{view.assumeDvdUntilMaturity}}
            </span>
              <lv-xl-label [field]="'MC_DVD_EXTRAP'"></lv-xl-label>
            </div>
            <div class="lv-input-field lv-flex-box">
              <div class="lv-field lv-field--checkbox">
                <input name="assumeDvdUntilMaturity"
                  type="checkbox"
                  [attr.id]="view.assumeDvdUntilMaturityId"
                  class="k-checkbox"
                  [(ngModel)]="view.modelSettings.assumeDividendYieldUntilMaturity"
                  (change)="onModelCustomizationChange()" />
                <label class="k-checkbox-label"
                  lvId="isAssumeDvdUntilMaturity"
                  [attr.for]="view.assumeDvdUntilMaturityId"></label>
                </div>
            </div>
            </lv-flex-box>            
          </lv-flex-box>
        </lv-flex-box>

      </lv-flex-box>
    </div>

  </ng-container>

  <ng-container settings>
    <div *ngIf="!isOpenedFromExcel"
      class="lv-side-panel-link-item"
      lvId="lv-model-customization-display-default-view"
      (click)="onDisplayDefaultView()">
      <span>Display Default View</span>
      <i *ngIf="widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>

    <div *ngIf="!isOpenedFromExcel"
      class="lv-side-panel-link-item lv-side-panel-link-item--separator"
      lvId="lv-model-customization-display-custom-view"
      (click)="onDisplayCustomView()">
      <span>Display Custom View</span>
      <i *ngIf="!widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>

    <div class="lv-side-panel-link-item"
      lvId="lv-model-customization-edit-custom-view"
      (click)="onEditCustomView()">
      <span>{{editWidgetButtonText}}</span>
    </div>

    <div
      class="lv-side-panel-link-item"
      *ngIf="!widgetState.useDefaultView"
      lvId="lv-model-customization-save-default-view"
      (click)="onSaveAsDefaultView()">
      <span>{{saveWidgetButtonText}}</span>
    </div>
  </ng-container>
</lv-settings-widget>

<ng-template #creditModelTypeTooltipTemplate>
  <lv-tooltip dm="dM-165"></lv-tooltip>
</ng-template>
<ng-template #equityModelTypeTooltipTemplate>
  <lv-tooltip dm="dM-166"></lv-tooltip>
</ng-template>
<ng-template #underlyingModelTypeTooltipTemplate>
  <lv-tooltip dm="dM-167"></lv-tooltip>
</ng-template>
<ng-template #stochasticCreditTooltipTemplate>
  <lv-tooltip dm="dM-168"></lv-tooltip>
</ng-template>
<ng-template #noCreditPointsTooltipTemplate>
  <lv-tooltip dm="dM-169"></lv-tooltip>
</ng-template>
<ng-template #stochasticCreditBCTooltipTemplate>
  <lv-tooltip dm="dM-170"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionModeTooltipTemplate>
  <lv-tooltip dm="dM-175"></lv-tooltip>
</ng-template>
<ng-template #pepsParityConventionTooltipTemplate>
  <lv-tooltip dm="dM-176"></lv-tooltip>
</ng-template>
<ng-template #expectedLifeTooltipTemplate>
  <lv-tooltip dm="dM-177"></lv-tooltip>
</ng-template>
<ng-template #exchangeableAsConvertTooltipTemplate>
  <lv-tooltip dm="dM-178"></lv-tooltip>
</ng-template>
<ng-template #defaultOnLowParityTooltipTemplate>
  <lv-tooltip dm="dM-179"></lv-tooltip>
</ng-template>
<ng-template #includeCashInRebateTooltipTemplate>
  <lv-tooltip dm="dM-180"></lv-tooltip>
</ng-template>
<ng-template #callTooltipTemplate>
  <lv-tooltip dm="dM-734"></lv-tooltip>
</ng-template>
<ng-template #putTooltipTemplate>
  <lv-tooltip dm="dM-735"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionTooltipTemplate>
  <lv-tooltip dm="dM-736"></lv-tooltip>
</ng-template>
<ng-template #contigentConversionTooltipTemplate>
  <lv-tooltip dm="dM-740"></lv-tooltip>
</ng-template>
<ng-template #conversionPriceResetTooltipTemplate>
  <lv-tooltip dm="dM-737"></lv-tooltip>
</ng-template>
<ng-template #variableConversionTooltipTemplate>
  <lv-tooltip dm="dM-738"></lv-tooltip>
</ng-template>
<ng-template #forceOptionalResetTooltipTemplate>
  <lv-tooltip dm="dM-181"></lv-tooltip>
</ng-template>
<ng-template #currentConversionAveragingTooltipTemplate>
  <lv-tooltip dm="dM-187"></lv-tooltip>
</ng-template>
<ng-template #currentResetAveragingTooltipTemplate>
  <lv-tooltip dm="dM-188"></lv-tooltip>
</ng-template>
<ng-template #forwardConversionAveragingTooltipTemplate>
  <lv-tooltip dm="dM-189"></lv-tooltip>
</ng-template>
<ng-template #forwardResetAveragingTooltipTemplate>
  <lv-tooltip dm="dM-190"></lv-tooltip>
</ng-template>
<ng-template #fwdDividProtectionAdjstTooltipTemplate>
  <lv-tooltip dm="dM-182"></lv-tooltip>
</ng-template>
<ng-template #maturityDateOverrideTooltipTemplate>
  <lv-tooltip dm="dM-185"></lv-tooltip>
</ng-template>
<ng-template #conversionNoticeGivenTooltipTemplate>
  <lv-tooltip dm="dM-186"></lv-tooltip>
</ng-template>
<ng-template #hardCallAdjustTooltipTemplate>
  <lv-tooltip dm="dM-172"></lv-tooltip>
</ng-template>
<ng-template #hardCallModelTooltipTemplate>
  <lv-tooltip dm="dM-171"></lv-tooltip>
</ng-template>
<ng-template #softCallModelTooltipTemplate>
  <lv-tooltip dm="dM-680"></lv-tooltip>
</ng-template>
<ng-template #softCallAdjustTooltipTemplate>
  <lv-tooltip dm="dM-173"></lv-tooltip>
</ng-template>
<ng-template #useNotCallableDateTooltipTemplate>
  <lv-tooltip dm="dM-183"></lv-tooltip>
</ng-template>
<ng-template #useNotCallableParityTooltipTemplate>
  <lv-tooltip dm="dM-184"></lv-tooltip>
</ng-template>
<ng-template #assumeDvdUntilMaturityTooltipTemplate>
  <lv-tooltip dm="dM-1005"></lv-tooltip>
</ng-template>
<ng-template #modelCustomizationPricerVersionTooltipTemplate>
  <lv-tooltip dm="dM-1067"></lv-tooltip>
</ng-template>
