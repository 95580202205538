import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IEquityOptionBorrowSectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-borrow-settings/market-data-equity-option-borrow-section-settings';
import { BorrowSourceDescription } from '@lv-analytics/models/market-data/borrow/borrow-enum';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-equity-option-borrow-section-settings',
  templateUrl: './lv-equity-option-borrow-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEquityOptionBorrowSectionSettingsComponent {
  @Input() settings: IEquityOptionBorrowSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  borrowSourceEnum: LvLookupEnum;

  constructor() {
    this.borrowSourceEnum = new LvLookupEnum(BorrowSourceDescription);

    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--row')
  get isFlexColumnComponent() {
    return true;
  }
}
