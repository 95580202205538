import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { InternalComponentService } from '@lv-views/internal';
import { SettingsConfirmDialogComponent } from '../settings-confirm-dialog/settings-confirm-dialog.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { Router } from '@angular/router';
import { SettingsItemType } from '../models/settings-item-type';
import { LvPermissionService } from '@lv-core-ui/services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'settings',
  templateUrl: './settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DiscardChangesService]
})
export class SettingsComponent implements OnInit {

  get listOfMenuItems(): string[] {
    const menuItems = [
      SettingsItemType.Instruments,
      SettingsItemType.Valuation,
      SettingsItemType.Notifications,
      SettingsItemType.Environments,
      SettingsItemType.Other
    ];
  
    if (this.hasBetaPolicy) {
      menuItems.push(SettingsItemType.LeversysDataSyncing);
    }
  
    return menuItems;
  }

  get hasBetaPolicy(): boolean {
    return this._permissionService.hasPermission('BETA', 'VIEW');
  }

  get listOfInstruments(): string[] {
    const items = [SettingsItemType.Convertible,
      SettingsItemType.Equity,
      SettingsItemType.Bond,
      SettingsItemType.EquityOption,
      SettingsItemType.AssetSwap];

    return items;
  }

  get isAdditionalMenuVisible(): boolean {
    return this.selectedMenuItem === SettingsItemType.Instruments;
  }

  get selectedMenuItemTitle(): string {
    if (this.selectedMenuItem === SettingsItemType.Convertible || this.selectedMenuItem === SettingsItemType.Equity ||
      this.selectedMenuItem === SettingsItemType.AssetSwap || this.selectedMenuItem === SettingsItemType.Bond ||
      this.selectedMenuItem === SettingsItemType.EquityOption) {
      return `Instruments - ${this.selectedMenuItem}`;
    }
    else if (this.selectedMenuItem === SettingsItemType.LeversysDataSyncing) {
      return `${this.selectedMenuItem} - Equity`;
    }

    return this.selectedMenuItem;
  }

  get currentComponentName(): string {
    if (this.selectedMenuItem === SettingsItemType.Convertible) {
      return `${this.selectedMenuItem} - ${this.convertibleTabstrip.tabs.find(x => x.selected).title}`
    }
    else if (this.selectedMenuItem === SettingsItemType.Equity) {
      return `${this.selectedMenuItem} - ${this.equityTabstrip.tabs.find(x => x.selected).title}`
    }
    else if (this.selectedMenuItem === SettingsItemType.Bond) {
      return `${this.selectedMenuItem} - ${this.bondTabstrip.tabs.find(x => x.selected).title}`
    }
    else if (this.selectedMenuItem === SettingsItemType.EquityOption) {
      return `${this.selectedMenuItem} - ${this.equityOptionTabstrip.tabs.find(x => x.selected).title}`
    }
    else if (this.selectedMenuItem === SettingsItemType.AssetSwap) {
      return `${this.selectedMenuItem} - ${this.assetSwapTabstrip.tabs.find(x => x.selected).title}`
    }

    return this.selectedMenuItem;
  }

  get isLoading(): boolean {
    return this.loadingStack.length > 0;
  }

  @ViewChild('convertibleTabstrip') convertibleTabstrip: TabStripComponent;
  @ViewChild('equityTabstrip') equityTabstrip: TabStripComponent;
  @ViewChild('bondTabstrip') bondTabstrip: TabStripComponent;
  @ViewChild('assetSwapTabstrip') assetSwapTabstrip: TabStripComponent;
  @ViewChild('equityOptionTabstrip') equityOptionTabstrip: TabStripComponent;


  selectedMenuItem: string;
  previousSelectedMenuItem: string;
  loadingStack: boolean[];

  constructor(
    private _internalService: InternalComponentService,
    private _dialogService: DialogService,
    private _discardChangesService: DiscardChangesService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _permissionService: LvPermissionService,
    private _router: Router,
  ) {
    this.selectedMenuItem = SettingsItemType.Instruments;
    this.previousSelectedMenuItem = SettingsItemType.Instruments;
    this.loadingStack = [];
  }

  ngOnInit() {
    this._internalService.toggleMenu(false);
  }

  /**
   * Do cancel, check if something on first form is changed.
   */
  doCancel() {
    if (this.previousSelectedMenuItem === SettingsItemType.Convertible) {
      this.selectItem(SettingsItemType.Instruments);
    }
    else if (this.previousSelectedMenuItem === SettingsItemType.Equity) {
      this.selectItem(SettingsItemType.Instruments);
    }
    else if (this.previousSelectedMenuItem === SettingsItemType.Bond) {
      this.selectItem(SettingsItemType.Instruments);
    }
    else if (this.previousSelectedMenuItem === SettingsItemType.EquityOption) {
      this.selectItem(SettingsItemType.Instruments);
    }
    else if (this.previousSelectedMenuItem === SettingsItemType.AssetSwap) {
      this.selectItem(SettingsItemType.Instruments);
    }
    else {
      this.selectItem(this.previousSelectedMenuItem);
    }
  }

  /**
   * Check if desired item is selected.
   * @param item Item name.
   * @returns Flag that describes whether is item is selected.
   */
  isItemSelected(item: string): boolean {
    if (item === SettingsItemType.Instruments && !this.listOfMenuItems.find(x => x === this.selectedMenuItem)) {
      return true;
    }

    return item === this.selectedMenuItem;
  }

  /**
   * Should display section.
   * @param section Section name.
   * @returns Flag that describes whether section should be displayed.
   */
  shouldDisplaySection(section: string): boolean {
    return section === this.selectedMenuItem;
  }

  /**
   * Select one settings menu item.
   * @param menuItem Menu item name.
   * @returns 
   */
  selectItem(menuItem: string): void {
    if (menuItem === this.selectedMenuItem) {
      return;
    }

    this.previousSelectedMenuItem = this.selectedMenuItem;

    if (this.selectedMenuItem === SettingsItemType.Instruments || this.selectedMenuItem === SettingsItemType.Environments) {
      this.selectedMenuItem = menuItem;
      return;
    }

    let isChanged = false;

    isChanged = this.isCurrentComponentChanged()

    if (isChanged) {
      this.openDialogForMenuItems(menuItem);
    }
    else {
      this.selectedMenuItem = menuItem;
    }
  }

  /**
   * Check if instrument type is odd or even row.
   * @param instrumentType Instrument type name.
   * @returns Is odd or even row flag.
   */
  isOddInstrumentElement(instrumentType: string): boolean {
    return this.listOfInstruments.findIndex(x => x === instrumentType) % 2 === 0;
  }

  /**
   * On exit settings.
   */
  onExitSettings() {
    const isChanged = this.isCurrentComponentChanged()

    if (isChanged) {
      const dialogRef = this._dialogService.open({
        title: 'Unsaved Changes',
        content: SettingsConfirmDialogComponent
      });

      const dialog = dialogRef.content.instance as SettingsConfirmDialogComponent;
      dialogRef.dialog.location.nativeElement.classList.add('settings-confirm-dialog-wrapper');
      dialog.componentName = this.currentComponentName;

      dialog.didContinueEditing.subscribe(() => {
        dialogRef.close();
      });

      dialog.didDiscardChanges.subscribe(() => {
        this._router.navigate(['dashboard']);
        dialogRef.close();
      });
    }
    else {
      this._router.navigate(['dashboard']);
    }
  }

  /**
   * On tab select.
   * @param event Tab select event.
   */
  onTabSelect(event: SelectEvent) {
    this.openDialogForTabs(event);
  }

  /**
   * Get current selected component and relevant tabstrip element if there is one.
   * @returns Current selected component and relevant tabstrip element if there is one.
   */
  getCurrentComponentAndTabstrip(): [IDiscardable, TabStripComponent] {
    if (this.selectedMenuItem === SettingsItemType.Convertible) {
      const currentTab = this.convertibleTabstrip.tabs.find(tab => tab.selected).title.replace(' ', '');

      return [this._discardChangesService.getComponent(currentTab), this.convertibleTabstrip];
    }
    else if (this.selectedMenuItem === SettingsItemType.Equity) {
      const currentTab = this.equityTabstrip.tabs.find(tab => tab.selected).title.replace(' ', '');

      return [this._discardChangesService.getComponent(currentTab), this.equityTabstrip];
    }
    else if (this.selectedMenuItem === SettingsItemType.Bond) {
      const currentTab = this.bondTabstrip.tabs.find(tab => tab.selected).title.replace(' ', '');

      return [this._discardChangesService.getComponent(currentTab), this.bondTabstrip];
    }
    else if (this.selectedMenuItem === SettingsItemType.EquityOption) {
      const currentTab = this.equityOptionTabstrip.tabs.find(tab => tab.selected).title.replace(' ', '');

      return [this._discardChangesService.getComponent(currentTab), this.equityOptionTabstrip];
    }
    else if (this.selectedMenuItem === SettingsItemType.AssetSwap) {
      const currentTab = this.assetSwapTabstrip.tabs.find(tab => tab.selected).title.replace(' ', '');

      return [this._discardChangesService.getComponent(currentTab), this.assetSwapTabstrip];
    }
    return null;
  }

  /**
   * Get current selected component.
   * @returns Selected component.
   */
  getCurrentComponent(): IDiscardable {
    return this._discardChangesService.getComponent(this.selectedMenuItem);
  }

  /**
   * Open confirm dialog for tabs.
   * @param event Select tab event.
   * @returns 
   */
  openDialogForTabs(event: SelectEvent) {
    const currentObjects = this.getCurrentComponentAndTabstrip();

    if (currentObjects[0].isChanged()) {
      event.preventDefault();

      if (currentObjects[1]?.tabs.toArray().findIndex(x => x.selected) === event.index) {
        return;
      }

      const dialogRef = this._dialogService.open({
        title: 'Unsaved Changes',
        content: SettingsConfirmDialogComponent
      });

      const dialog = dialogRef.content.instance as SettingsConfirmDialogComponent;
      dialogRef.dialog.location.nativeElement.classList.add('settings-confirm-dialog-wrapper');
      dialog.componentName = this.currentComponentName;

      dialog.didContinueEditing.subscribe(() => {
        dialogRef.close();
      });

      dialog.didDiscardChanges.subscribe(() => {
        currentObjects[0].discardChanges();
        currentObjects[1].selectTab(event.index);
        this._changeDetectorRef.detectChanges();
        dialogRef.close();
      });
    }
  }

  /**
   * Open dialog for simple menu item.
   * @param menuItem Menu item name.
   */
  openDialogForMenuItems(menuItem: string) {
    const dialogRef = this._dialogService.open({
      title: 'Unsaved Changes',
      content: SettingsConfirmDialogComponent
    });

    const dialog = dialogRef.content.instance as SettingsConfirmDialogComponent;
    dialogRef.dialog.location.nativeElement.classList.add('settings-confirm-dialog-wrapper');
    dialog.componentName = this.currentComponentName;

    dialog.didContinueEditing.subscribe(() => {
      dialogRef.close();
    });

    dialog.didDiscardChanges.subscribe(() => {
      const currentComponent = this.getCurrentComponent();

      if (this.selectedMenuItem === SettingsItemType.Convertible) {
        this.convertibleTabstrip.tabs.forEach(tab => {
          const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
          if (tabComponent.isChanged()) {
            tabComponent.discardChanges();
          }
        });
      }
      else if (this.selectedMenuItem === SettingsItemType.Equity) {
        this.equityTabstrip.tabs.forEach(tab => {
          const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
          if (tabComponent.isChanged()) {
            tabComponent.discardChanges();
          }
        });
      }
      else if (this.selectedMenuItem === SettingsItemType.Bond) {
        this.bondTabstrip.tabs.forEach(tab => {
          const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
          if (tabComponent.isChanged()) {
            tabComponent.discardChanges();
          }
        });
      }
      else if (this.selectedMenuItem === SettingsItemType.EquityOption) {
        this.equityOptionTabstrip.tabs.forEach(tab => {
          const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
          if (tabComponent.isChanged()) {
            tabComponent.discardChanges();
          }
        });
      }
      else if (this.selectedMenuItem === SettingsItemType.AssetSwap) {
        this.assetSwapTabstrip.tabs.forEach(tab => {
          const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
          if (tabComponent.isChanged()) {
            tabComponent.discardChanges();
          }
        });
      }
      else {
        currentComponent.discardChanges();
      }

      this._changeDetectorRef.detectChanges();
      this.previousSelectedMenuItem = this.selectedMenuItem;
      this.selectedMenuItem = menuItem;

      dialogRef.close();
    });
  }

  /**
   * Start loading hover.
   */
  startLoading() {
    this.loadingStack.push(true);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * End loading hover.
   */
  endLoading() {
    this.loadingStack.pop();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Get dm for menu item.
   * @param menuItem 
   * @returns 
   */
  getDm(menuItem: string): string {
    switch (menuItem) {
      case 'Environments':
        return 'DM-1276';
      case 'Instruments':
        return 'DM-4718';
      case 'Valuation':
        return 'DM-1242';
      case 'Notifications':
        return 'DM-1256';
      case 'Other':
        return 'DM-1259';
      default:
        return '';
    }
  }

  /**
   * Get dm for additional menu item.
   * @param menuItem 
   * @returns 
   */
  getAdditionalMenuDm(menuItem: string): string {
    switch (menuItem) {
      case 'Convertible':
        return 'DM-4719';
      case 'Instruments':
        return 'DM-4718';
      case 'Instruments':
        return 'DM-4797';
      default:
        return '';
    }
  }

  private isCurrentComponentChanged(): boolean {
    let isChanged = false;

    if (this.selectedMenuItem === SettingsItemType.Convertible) {
      this.convertibleTabstrip.tabs.forEach(tab => {
        const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
        if (tabComponent.isChanged()) {
          isChanged = true;
        }
      });
    }

    else if (this.selectedMenuItem === SettingsItemType.Equity) {
      this.equityTabstrip.tabs.forEach(tab => {
        const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
        if (tabComponent.isChanged()) {
          isChanged = true;
        }
      });
    }
    else if (this.selectedMenuItem === SettingsItemType.Bond) {
      this.bondTabstrip.tabs.forEach(tab => {
        const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
        if (tabComponent.isChanged()) {
          isChanged = true;
        }
      });
    }
    else if (this.selectedMenuItem === SettingsItemType.AssetSwap) {
      this.assetSwapTabstrip.tabs.forEach(tab => {
        const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
        if (tabComponent.isChanged()) {
          isChanged = true;
        }
      });
    }
    else if (this.selectedMenuItem === SettingsItemType.EquityOption) {
      this.equityOptionTabstrip.tabs.forEach(tab => {
        const tabComponent = this._discardChangesService.getComponent(tab.title.replace(' ', ''));
        if (tabComponent.isChanged()) {
          isChanged = true;
        }
      });
    }
    else {
      isChanged = this.getCurrentComponent()?.isChanged();
    }

    return isChanged;
  }
}
