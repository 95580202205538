<lv-flex-box
  direction="row"
  data-cy="DM-1475">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="dividendSourceSettingsTooltipTemplate"
    filter="span">
    <span> Dividend Source </span>
  </div>

  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="dividendSourceSettings"
      data-cy = "DM-1475"
      valueField="id"
      textField="text"
      [data]="dividendSource.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.dividendsSource"
      [disabled]="!overrideSystemDefaults">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1476">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="dividendFrequencyTooltipTemplate"
    filter="span">
    <span> Dividend Frequency </span>
  </div>

  <div class="lv-input-field">
    <kendo-dropdownlist
      name="dividendFrequencySettings"
      data-cy = "DM-1476"
      valueField="id"
      textField="text"
      [data]="dividendFrequency.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.dividendsFrequency"
      [disabled]="isFrequencyDisabled">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<label class="lv-label-field lv-label lv-label--title">Dividend Yield Override</label>

<lv-flex-box 
  direction="row" 
  data-cy="DM-1477">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="overrideDividendFrequencySettingsTooltipTemplate"
    filter="span">
    <span> Dividend Frequency </span>
  </div>

  <div class="lv-input-field">
    <kendo-dropdownlist
      name="dividendsYieldFrequencySettings"
      data-cy = "DM-1477"
      valueField="id"
      textField="text"
      [data]="dividendFrequency.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.dividendsYieldFrequency"
      [disabled]="!overrideSystemDefaults">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  direction="row" 
  data-cy="DM-1478">
  
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="firstDividendStartDateSettingsTooltipTemplate"
    filter="span">
    <span> First Dividend Date Start </span>
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="firstDividendStarDateSettings"
      data-cy = "DM-1478"
      [format]="numberFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="settings.firstDividendDateStart"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">days</span>
  </div>
</lv-flex-box>

<ng-template #dividendSourceSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1475"></lv-tooltip>
</ng-template>
<ng-template #dividendFrequencyTooltipTemplate>
  <lv-tooltip dm="dM-1476"></lv-tooltip>
</ng-template>
<ng-template #overrideDividendFrequencySettingsTooltipTemplate>
  <lv-tooltip dm="dM-1477"></lv-tooltip>
</ng-template>
<ng-template #firstDividendStartDateSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1478"></lv-tooltip>
</ng-template>

