<div class="lv-dialog lv-model-customization-selection-dialog"
     [attr.data-cy]="modelCustomizationDialogScreenDm">
  <lv-flex-box direction="row" class="lv-dialog-content">

    <lv-flex-box class="lv-model-customization-selection-left-content">
      <!-- EXCLUDE TERMS -->
      <lv-flex-box direction="row" 
        class="lv-model-customization-selection-section"
        data-cy="DM-5759">
        <div class="lv-label lv-label-field lv-label--title">EXCLUDE TERMS</div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationExcludeTermsId"
            name="modelCustomizationExcludeTermsCheckBox"
            [(ngModel)]="modelCustomizationState.excludeTerms.isExcludeTerms"
            (ngModelChange)="onExcludeTermsChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationExcludeTermsCheckBox"
              [attr.for]="modelCustomizationExcludeTermsId"></label>
        </div>
      </lv-flex-box>

      <!-- Call -->
      <lv-flex-box direction="row"
        data-cy="DM-5760">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="callTooltipTemplate"
          filter="span">
          <span>Call</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationCallId"
            name="modelCustomizationCallCheckBox"
            [(ngModel)]="modelCustomizationState.excludeTerms.isCall"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationCallCheckBox"
              [attr.for]="modelCustomizationCallId"></label>
        </div>
      </lv-flex-box>

      <!-- Put -->
      <lv-flex-box direction="row"
        data-cy="DM-5761">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="putTooltipTemplate"
          filter="span">
          <span>Put</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationPutId"
            name="modelCustomizationPutCheckBox"
            [(ngModel)]="modelCustomizationState.excludeTerms.isPut"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationPutCheckBox"
              [attr.for]="modelCustomizationPutId"></label>
        </div>
      </lv-flex-box>

      <!-- DividendProtection -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5762">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="dividendProtectionTooltipTemplate"
          filter="span">
          <span>Dividend Protection</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationDividendProtectionId"
            name="modelCustomizationDividendProtectionCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.excludeTerms.isDividendProtection"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationDividendProtectionCheckBox"
              [attr.for]="modelCustomizationDividendProtectionId"></label>
        </div>
      </lv-flex-box>

      <!-- ContingentConversion -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5763">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="contigentConversionTooltipTemplate"
          filter="span">
         <span>Contingent Conversion</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationContingentConversionId"
            name="modelCustomizationContingentConversionCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.excludeTerms.isContingentConversion"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationContingentConversionCheckBox"
              [attr.for]="modelCustomizationContingentConversionId"></label>
        </div>
      </lv-flex-box>

      <!-- ConversionPriceReset -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5764">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionPriceResetTooltipTemplate"
          filter="span">
          <span>Conversion Price Reset</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationConversionPriceResetId"
            name="modelCustomizationConversionPriceResetCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.excludeTerms.isConversionPriceReset"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationConversionPriceResetCheckBox"
              [attr.for]="modelCustomizationConversionPriceResetId"></label>
        </div>
      </lv-flex-box>

      <!-- VariableConversion -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5765">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="variableConversionTooltipTemplate"
          filter="span">
         <span>Variable Conversion</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationVariableConversionId"
            name="modelCustomizationVariableConversionCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.excludeTerms.isVariableConversion"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationVariableConversionCheckBox"
              [attr.for]="modelCustomizationVariableConversionId"></label>
        </div>
      </lv-flex-box>

      <!-- Current Conv. Averaging -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5766">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="currentConversionAveragingTooltipTemplate"
          filter="span">
          <span>Current Conv. Averaging</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationCurrentConvAveragingId"
            name="modelCustomizationCurrentConvAveragingCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.excludeTerms.isCurrentConversionAveraging"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationCurrentConvAveragingCheckBox"
              [attr.for]="modelCustomizationCurrentConvAveragingId"></label>
        </div>
      </lv-flex-box>

      <!-- Current Reset Averaging -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5767">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="currentResetAveragingTooltipTemplate"
          filter="span">
          <span>Current Reset Averaging</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationCurrentResetAveragingId"
            name="modelCustomizationCurrentResetAveragingCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.excludeTerms.isCurrentResetAveraging"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationCurrentResetAveragingCheckBox"
              [attr.for]="modelCustomizationCurrentResetAveragingId"></label>
        </div>
      </lv-flex-box>

      <!-- INCLUDE TERMS -->
      <lv-flex-box direction="row" 
        class="lv-model-customization-selection-section" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5768">
        <div class="lv-label lv-label-field lv-label--title">INCLUDE TERMS</div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationIncludeTermsId"
            name="modelCustomizationIncludeTermsCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.includeTerms.isIncludeTerms"
            (ngModelChange)="onIncludeTermsChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationIncludeTermsCheckBox"
              [attr.for]="modelCustomizationIncludeTermsId"></label>
        </div>
      </lv-flex-box>

      <!-- Forward Conv. Averaging -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5769">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="forwardConversionAveragingTooltipTemplate"
          filter="span">
          <span>Forward Conv. Averaging</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationForwardConvAveragingId"
            name="modelCustomizationForwardConvAveragingCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.includeTerms.isForwardConversionAveraging"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationForwardConvAveragingCheckBox"
              [attr.for]="modelCustomizationForwardConvAveragingId"></label>
        </div>
      </lv-flex-box>

      <!-- Forward Reset Averaging -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5770">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="forwardResetAveragingTooltipTemplate"
          filter="span">
          <span>Forward Reset Averaging</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationForwardResetAveragingId"
            name="modelCustomizationForwardResetAveragingCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.includeTerms.isForwardResetAveraging"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationForwardResetAveragingCheckBox"
              [attr.for]="modelCustomizationForwardResetAveragingId"></label>
        </div>
      </lv-flex-box>

      <!-- MODEL TYPE -->
      <lv-flex-box direction="row" 
        class="lv-model-customization-selection-section"
        data-cy="DM-5771">
        <div class="lv-label lv-label-field lv-label--title">MODEL TYPE</div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationModelTypeId"
            name="modelCustomizationModelTypeCheckBox"
            [(ngModel)]="modelCustomizationState.modelType.isModelType"
            (ngModelChange)="onModelTypeChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationModelTypeCheckBox"
              [attr.for]="modelCustomizationModelTypeId"></label>
        </div>
      </lv-flex-box>

      <!-- Pricer Version -->
      <lv-flex-box direction="row"
        data-cy="DM-5772">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="modelCustomizationPricerVersionTooltipTemplate"
          filter="span">
          <span>Pricer Version</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationPricerVersionId"
            name="modelCustomizationPricerVersionCheckBox"
            [(ngModel)]="modelCustomizationState.modelType.isPricerVersion"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationPricerVersionCheckBox"
              [attr.for]="modelCustomizationPricerVersionId"></label>
        </div>
      </lv-flex-box>

      <!-- Credit Model Type -->
      <lv-flex-box direction="row"
        data-cy="DM-5773">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="creditModelTypeTooltipTemplate"
          filter="span">
          <span>Credit Model Type</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationCreditModelTypeId"
            name="modelCustomizationCreditModelTypeCheckBox"
            [(ngModel)]="modelCustomizationState.modelType.isCreditModelType"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationCreditModelTypeCheckBox"
              [attr.for]="modelCustomizationCreditModelTypeId"></label>
        </div>
      </lv-flex-box>

      <!-- Equity Model Type -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5774">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="equityModelTypeTooltipTemplate"
          filter="span">
          <span>Equity Model Type</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationEquityModelTypeId"
            name="modelCustomizationEquityModelTypeCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.modelType.isEquityModelType"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationEquityModelTypeCheckBox"
              [attr.for]="modelCustomizationEquityModelTypeId"></label>
        </div>
      </lv-flex-box>

      <!-- Und. Credit Model -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5775">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="underlyingModelTypeTooltipTemplate"
          filter="span">
          <span>Und. Credit Model</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationUnderlyingCreditModelId"
            name="modelCustomizationUnderlyingCreditModelCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.modelType.isUnderlyingCreditModel"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationUnderlyingCreditModelCheckBox"
              [attr.for]="modelCustomizationUnderlyingCreditModelId"></label>
        </div>
      </lv-flex-box>

      <!-- Stochastic Credit -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5776">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="stochasticCreditTooltipTemplate"
          filter="span">
          <span>Stochastic Credit</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationStochasticCreditId"
            name="modelCustomizationStochasticCreditCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.modelType.isStochasticCredit"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationStochasticCreditCheckBox"
              [attr.for]="modelCustomizationStochasticCreditId"></label>
        </div>
      </lv-flex-box>

      <!-- No. Credit Points -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5777">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="noCreditPointsTooltipTemplate"
          filter="span">
          <span>No. Credit Points</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationNumberOfCreditPointsId"
            name="modelCustomizationNumberOfCreditPointsCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.modelType.isNumberOfCreditPoints"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationNumberOfCreditPointsCheckBox"
              [attr.for]="modelCustomizationNumberOfCreditPointsId"></label>
        </div>
      </lv-flex-box>

      <!-- Stochastic Credit BC -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5778">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="stochasticCreditBCTooltipTemplate"
          filter="span">
         <span>Stochastic Credit BC</span>
      </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationStochasticCreditBCId"
            name="modelCustomizationStochasticCreditBCCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.modelType.isStochasticCreditBC"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationStochasticCreditBCCheckBox"
              [attr.for]="modelCustomizationStochasticCreditBCId"></label>
        </div>
      </lv-flex-box>
    </lv-flex-box>

    <lv-flex-box>
      <!-- CALL ADJUSTMENTS -->
      <lv-flex-box direction="row" 
        class="lv-model-customization-selection-section"
        data-cy="DM-5779">
        <div class="lv-label lv-label-field lv-label--title">CALL ADJUSTMENTS</div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationCallAdjustmentsId"
            name="modelCustomizationCallAdjustmentsCheckBox"
            [(ngModel)]="modelCustomizationState.callAdjustments.isCallAdjustments"
            (ngModelChange)="onCallAdjustmentChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationCallAdjustmentsCheckBox"
              [attr.for]="modelCustomizationCallAdjustmentsId"></label>
        </div>
      </lv-flex-box>

      <!-- Hard Call Model Type -->
      <lv-flex-box direction="row"
        data-cy="DM-5780">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="hardCallModelTooltipTemplate"
          filter="span">
          <span>Call Model Type</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationHardCallModelTypeId"
            name="modelCustomizationHardCallModelTypeCheckBox"
            [(ngModel)]="modelCustomizationState.callAdjustments.isHardCallModelType"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationHardCallModelTypeCheckBox"
              [attr.for]="modelCustomizationHardCallModelTypeId"></label>
        </div>
      </lv-flex-box>

      <!-- Soft Call Model Type -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5782">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="softCallModelTooltipTemplate"
          filter="span">
          <span>Soft Call (Trigger) Model Type</span>
      </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationSoftCallModelTypeId"
            name="modelCustomizationSoftCallModelTypeCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.callAdjustments.isSoftCallModelType"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationSoftCallModelTypeCheckBox"
              [attr.for]="modelCustomizationSoftCallModelTypeId"></label>
        </div>
      </lv-flex-box>

       <!-- Not Callable (Date) -->
       <lv-flex-box direction="row"
        data-cy="DM-5783">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="useNotCallableDateTooltipTemplate"
          filter="span">
          <span>Not Callable (Date)</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationNotCallableDateId"
            name="modelCustomizationNotCallableDateCheckBox"
            [(ngModel)]="modelCustomizationState.callAdjustments.isNotCallableDate"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationNotCallableDateCheckBox"
              [attr.for]="modelCustomizationNotCallableDateId"></label>
        </div>
      </lv-flex-box>

      <!-- Not Callable (Parity) -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5784">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="useNotCallableParityTooltipTemplate"
          filter="span">
          <span>Not Callable (Parity)</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationNotCallableParityId"
            name="modelCustomizationNotCallableParityCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.callAdjustments.isNotCallableParity"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationNotCallableParityCheckBox"
              [attr.for]="modelCustomizationNotCallableDateId"></label>
        </div>
      </lv-flex-box>

      <!-- OTHER PARAMETERS -->
      <lv-flex-box direction="row" 
        class="lv-model-customization-selection-section"
        data-cy="DM-5785">
        <div class="lv-label lv-label-field lv-label--title">OTHER PARAMETERS</div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationOtherParametersId"
            name="modelCustomizationOtherParametersCheckBox"
            [(ngModel)]="modelCustomizationState.otherParameters.isOtherParameters"
            (ngModelChange)="onOtherParametersChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationOtherParametersCheckBox"
              [attr.for]="modelCustomizationOtherParametersId"></label>
        </div>
      </lv-flex-box>

      <!-- Dividend Protection Model -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5786">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="dividendProtectionModeTooltipTemplate"
          filter="span">
          <span>Dividend Protection Model</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationDividendProtectionModelId"
            name="modelCustomizationDividendProtectionModelCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.otherParameters.isDividendProtectionModel"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationDividendProtectionModelCheckBox"
              [attr.for]="modelCustomizationDividendProtectionModelId"></label>
        </div>
      </lv-flex-box>

      <!-- PEPS Parity Convention -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5787">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="pepsParityConventionTooltipTemplate"
          filter="span">
          <span>PEPS Parity Convention</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationPEPSParityConventionId"
            name="modelCustomizationPEPSParityConventionCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.otherParameters.isPEPSParityConvention"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationPEPSParityConventionCheckBox"
              [attr.for]="modelCustomizationPEPSParityConventionId"></label>
        </div>
      </lv-flex-box>

      <!-- Expected Life Excl. Default -->
      <lv-flex-box direction="row"
        data-cy="DM-5788">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="expectedLifeTooltipTemplate"
          filter="span">
          <span>Expected Life Excl. Default</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationExchangeableAsConvertibleId"
            name="modelCustomizationExchangeableAsConvertibleCheckBox"
            [(ngModel)]="modelCustomizationState.otherParameters.isExpectedLifeExcludingDefault"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationExpectedLifeExcludingDefaultCheckBox"
              [attr.for]="modelCustomizationExchangeableAsConvertibleId"></label>
        </div>
      </lv-flex-box>

      <!-- Exchangeable as Convert. -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5789">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="exchangeableAsConvertTooltipTemplate"
          filter="span">
          <span>Exchangeable as Convert.</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationExpectedLifeExcludingDefaultId"
            name="modelCustomizationExpectedLifeExcludingDefaultCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.otherParameters.isExchangeableAsConvertible"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationExchangeableAsConvertibleCheckBox"
              [attr.for]="modelCustomizationExpectedLifeExcludingDefaultId"></label>
        </div>
      </lv-flex-box>

      <!-- Default on Low Parity -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5790">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="defaultOnLowParityTooltipTemplate"
          filter="span">
          <span>Default on Low Parity</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationDefaultOnLowParityId"
            name="modelCustomizationDefaultOnLowParityCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.otherParameters.isDefaultOnLowParity"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationDefaultOnLowParityCheckBox"
              [attr.for]="modelCustomizationDefaultOnLowParityId"></label>
        </div>
      </lv-flex-box>

      <!-- Incl. Cash Rebate in Parity -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5791">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="includeCashInRebateTooltipTemplate"
          filter="span">
          <span>Incl. Cash Rebate in Parity</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationIncludeCashRebateInParityId"
            name="modelCustomizationIncludeCashRebateInParityCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.otherParameters.isIncludeCashRebateInParity"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationIncludeCashRebateInParityCheckBox"
              [attr.for]="modelCustomizationIncludeCashRebateInParityId"></label>
        </div>
      </lv-flex-box>

      <!-- Force Optional Reset -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5792">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="forceOptionalResetTooltipTemplate"
          filter="span">
          <span>Force Optional Reset</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationForceOptionalResetId"
            name="modelCustomizationForceOptionalResetCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.otherParameters.isForceOptionalReset"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationForceOptionalResetCheckBox"
              [attr.for]="modelCustomizationForceOptionalResetId"></label>
        </div>
      </lv-flex-box>

      <!-- Fwd. Divid. Protection Adjst -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5793">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="fwdDividProtectionAdjstTooltipTemplate"
          filter="span">
          <span>Fwd. Divid. Protection Adjst</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationFwdDividProtectioAdjstId"
            name="modelCustomizationFwdDividProtectioAdjstCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.otherParameters.isFwdDividProtectionAdjst"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationFwdDividProtectioAdjstCheckBox"
              [attr.for]="modelCustomizationFwdDividProtectioAdjstId"></label>
        </div>
      </lv-flex-box>

      <!-- Maturity Date Override -->
      <lv-flex-box direction="row"
        data-cy="DM-5794">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="maturityDateOverrideTooltipTemplate"
          filter="span">
          <span>Maturity Date Override</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationMaturityDateOverride"
            name="modelCustomizationMaturityDateOverrideCheckBox"
            [(ngModel)]="modelCustomizationState.otherParameters.isMaturityDateOverride"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationMaturityDateOverrideCheckBox"
              [attr.for]="modelCustomizationMaturityDateOverride"></label>
        </div>
      </lv-flex-box>

      <!-- Conversion Notice Given -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5795">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionNoticeGivenTooltipTemplate"
          filter="span">
          <span>Conversion Notice Given</span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="modelCustomizationConversionNoticeGivenId"
            name="modelCustomizationConversionNoticeGivenCheckBox"
            [(ngModel)]="convertibleBondModelCustomizationState.otherParameters.isConversionNoticeGiven"
            (ngModelChange)="onCheckChange()" />
            <label class="k-checkbox-label"
              lvId="modelCustomizationConversionNoticeGivenCheckBox"
              [attr.for]="modelCustomizationConversionNoticeGivenId"></label>
        </div>
      </lv-flex-box>
         <!-- Assume dvd. until maturity -->
      <lv-flex-box direction="row" 
        *ngIf="isConvertibleBond"
        data-cy="DM-5796">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="assumeDvdUntilMaturityTooltipTemplate"
            filter="span">
            <span>Assume Dvd Until Maturity</span>
          </div>
          <div class="lv-field lv-field--checkbox">
            <input class="k-checkbox"
              type="checkbox"
              [attr.id]="modelCustomizationAssumeDvdUntilMaturityId"
              name="modelCustomizationAssumeDvdUntilMaturityCheckBox"
              [(ngModel)]="convertibleBondModelCustomizationState.otherParameters.isAssumeDividendYieldUntilMaturity"
              (ngModelChange)="onCheckChange()" />
              <label class="k-checkbox-label"
                lvId="modelCustomizationAssumeDvdUntilMaturityCheckBox"
                [attr.for]="modelCustomizationAssumeDvdUntilMaturityId"></label>
          </div>
       </lv-flex-box>
    </lv-flex-box>
      
  </lv-flex-box>
  <div class="lv-dialog-actions">
      <button kendoButton
        [disabled]="isLoading"
        lvId="toolsCustomSelectionCancel"
        (click)="onCancel()">Cancel</button>
      <button kendoButton
        [primary]="true"
        [disabled]="isLoading"
        lvId="toolsCustomSelectionSave"
        (click)="onSave()">Save</button>
    </div>
</div>

<ng-template #callTooltipTemplate>
  <lv-tooltip dm="dM-734"></lv-tooltip>
</ng-template>
<ng-template #creditModelTypeTooltipTemplate>
  <lv-tooltip dm="dM-165"></lv-tooltip>
</ng-template>
<ng-template #equityModelTypeTooltipTemplate>
  <lv-tooltip dm="dM-166"></lv-tooltip>
</ng-template>
<ng-template #underlyingModelTypeTooltipTemplate>
  <lv-tooltip dm="dM-167"></lv-tooltip>
</ng-template>
<ng-template #stochasticCreditTooltipTemplate>
  <lv-tooltip dm="dM-168"></lv-tooltip>
</ng-template>
<ng-template #noCreditPointsTooltipTemplate>
  <lv-tooltip dm="dM-169"></lv-tooltip>
</ng-template>
<ng-template #stochasticCreditBCTooltipTemplate>
  <lv-tooltip dm="dM-170"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionModeTooltipTemplate>
  <lv-tooltip dm="dM-175"></lv-tooltip>
</ng-template>
<ng-template #pepsParityConventionTooltipTemplate>
  <lv-tooltip dm="dM-176"></lv-tooltip>
</ng-template>
<ng-template #expectedLifeTooltipTemplate>
  <lv-tooltip dm="dM-177"></lv-tooltip>
</ng-template>
<ng-template #exchangeableAsConvertTooltipTemplate>
  <lv-tooltip dm="dM-178"></lv-tooltip>
</ng-template>
<ng-template #defaultOnLowParityTooltipTemplate>
  <lv-tooltip dm="dM-179"></lv-tooltip>
</ng-template>
<ng-template #includeCashInRebateTooltipTemplate>
  <lv-tooltip dm="dM-180"></lv-tooltip>
</ng-template>
<ng-template #putTooltipTemplate>
  <lv-tooltip dm="dM-735"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionTooltipTemplate>
  <lv-tooltip dm="dM-736"></lv-tooltip>
</ng-template>
<ng-template #contigentConversionTooltipTemplate>
  <lv-tooltip dm="dM-740"></lv-tooltip>
</ng-template>
<ng-template #conversionPriceResetTooltipTemplate>
  <lv-tooltip dm="dM-737"></lv-tooltip>
</ng-template>
<ng-template #variableConversionTooltipTemplate>
  <lv-tooltip dm="dM-738"></lv-tooltip>
</ng-template>
<ng-template #forceOptionalResetTooltipTemplate>
  <lv-tooltip dm="dM-181"></lv-tooltip>
</ng-template>
<ng-template #currentConversionAveragingTooltipTemplate>
  <lv-tooltip dm="dM-187"></lv-tooltip>
</ng-template>
<ng-template #currentResetAveragingTooltipTemplate>
  <lv-tooltip dm="dM-188"></lv-tooltip>
</ng-template>
<ng-template #forwardConversionAveragingTooltipTemplate>
  <lv-tooltip dm="dM-189"></lv-tooltip>
</ng-template>
<ng-template #forwardResetAveragingTooltipTemplate>
  <lv-tooltip dm="dM-190"></lv-tooltip>
</ng-template>
<ng-template #fwdDividProtectionAdjstTooltipTemplate>
  <lv-tooltip dm="dM-182"></lv-tooltip>
</ng-template>
<ng-template #maturityDateOverrideTooltipTemplate>
  <lv-tooltip dm="dM-185"></lv-tooltip>
</ng-template>
<ng-template #conversionNoticeGivenTooltipTemplate>
  <lv-tooltip dm="dM-186"></lv-tooltip>
</ng-template>
<ng-template #hardCallAdjustTooltipTemplate>
  <lv-tooltip dm="dM-172"></lv-tooltip>
</ng-template>
<ng-template #hardCallModelTooltipTemplate>
  <lv-tooltip dm="dM-171"></lv-tooltip>
</ng-template>
<ng-template #softCallModelTooltipTemplate>
  <lv-tooltip dm="dM-680"></lv-tooltip>
</ng-template>
<ng-template #softCallAdjustTooltipTemplate>
  <lv-tooltip dm="dM-173"></lv-tooltip>
</ng-template>
<ng-template #useNotCallableDateTooltipTemplate>
  <lv-tooltip dm="dM-183"></lv-tooltip>
</ng-template>
<ng-template #useNotCallableParityTooltipTemplate>
  <lv-tooltip dm="dM-184"></lv-tooltip>
</ng-template>
<ng-template #assumeDvdUntilMaturityTooltipTemplate>
  <lv-tooltip dm="dM-1005"></lv-tooltip>
</ng-template>
<ng-template #modelCustomizationPricerVersionTooltipTemplate>
  <lv-tooltip dm="dM-1067"></lv-tooltip>
</ng-template>