<lv-flex-box 
  direction="row"
  data-cy="DM-1472">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="volatilitySourceSettingsTooltipTemplate"
    filter="span">
    <span> Volatility Source </span>
  </div>

  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="volatilitySettingsSource"
      valueField="id"
      textField="text"
      [data]="volTypeEnum.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.volType"
      [disabled]="!overrideSystemDefaults">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<ng-template #volatilitySourceSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1472"></lv-tooltip>
</ng-template>