import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IEquityOptionVolatilitySectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-volatility-settings/market-data-equity-option-volatility-section-settings';
import { IEquityOptionVolatilitySettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-volatility-settings/market-data-equtiy-option-volatility-settings';

@Component({
  selector: 'lv-equity-option-volatility-settings',
  templateUrl: './lv-equity-option-volatility-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEquityOptionVolatilitySettingsComponent {
  @Input() settings: IEquityOptionVolatilitySettings;

  constructor() {
    this.settings = {
      systemDefaults: {} as IEquityOptionVolatilitySectionSettings,
      environments: []
    } as IEquityOptionVolatilitySettings;
  }

  @HostBinding('class.lv-volatility-settings')
  get isLvInterestRatesSettingsComponent() {
    return true;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
