import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import {  IDividendProtectionSectionSettings } from '@lv-convertible-bond/models';
import { IBaseEquityOptionTermsSettings } from '../../../models/base-equity-option-terms-settings';

@Component({
  selector: 'lv-terms-equity-option-dividend-protection-settings',
  templateUrl: './lv-terms-equity-option-dividend-protection-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvTermsEquityOptionDividendProtectionSettingsComponent {

  @Input() systemDefaults: IBaseEquityOptionTermsSettings<IDividendProtectionSectionSettings>;
  @Input() myDefaults: IBaseEquityOptionTermsSettings<IDividendProtectionSectionSettings>;

  constructor() {}

  @HostBinding('class.lv-dividend-protection-settings')
  get isLvDividendProtectionSettingsComponent() {
    return true;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
