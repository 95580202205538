import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IBasicTerms } from '@lv-analytics/models';
import { ISaveBondInstrumentEvironmentSectionSettingsRequest as ISaveBondInstrumentEnvironmentSectionSettingsRequest } from '@lv-analytics/models/bond/instrument-environment-settings/save-bond-instrument-environment-settings-request';
import { IBondMarketData } from '@lv-analytics/models/bond/market-data/bond-market-data';
import { IBondCredit } from '@lv-analytics/models/bond/market-data/credit/bond-credit';
import { BondCreditMapper } from '@lv-analytics/models/bond/market-data/credit/credit-mapper';
import { IBondInterestRate } from '@lv-analytics/models/bond/market-data/interest-rates/bond-interest-rates';
import { BondInterestRatesMapper } from '@lv-analytics/models/bond/market-data/interest-rates/interest-rates-mapper';
import { SaveBondMarketDataRequest } from '@lv-analytics/models/bond/market-data/save-bond-market-data-request';
import { IBondWithholdingTax } from '@lv-analytics/models/bond/market-data/withholding-tax/bond-withholding-tax';
import { ILoadPricingResponse } from '@lv-analytics/models/bond/pricing/bond-load-pricing-response';
import { IBondValuationInputs } from '@lv-analytics/models/bond/pricing/bond-valuation-inputs';
import { ISaveBondPricingRequest } from '@lv-analytics/models/bond/pricing/save-bond-pricing-request';
import { IPricingEnvironmentOverrides } from '@lv-analytics/models/company-and-user-settings/pricing-environment-overrides';
import { LvAnalyticsError } from '@lv-analytics/models/errors';
import { IBondModelCustomization } from '@lv-analytics/models/model-customization/bond/bond-model-customization';
import { BondModelCustomizationMapper } from '@lv-analytics/models/model-customization/bond/bond-model-customization-mapper';
import { ISaveBondModelCustomizationRequest } from '@lv-analytics/models/model-customization/bond/bond-model-customization-requests';
import { ISaveMarketDataResponse } from '@lv-analytics/models/response/save-market-data-response';
import { ISaveSectionRequest } from '@lv-analytics/models/save-section-request';
import { IValuationResult } from '@lv-analytics/models/valuation-session';
import { IBondValuationSession } from '@lv-analytics/models/valuation-session/bond-valuation-session';
import { HttpClientBase } from '@lv-core-ui/api';
import { ILvError, LvDataMaster, LvErrorType } from '@lv-core-ui/models';
import { LvDateService } from '@lv-core-ui/services';
import { DateExtensions } from '@lv-core-ui/util/extensions/date/date';
import { IDataEntitlement } from '@lv-shared/interfaces/data-entitlement';
import { BondTermsDocument } from 'src/app/modules/instrument/bond-terms/models';
import { BondTermsService } from 'src/app/modules/instrument/bond-terms/services/bond-terms.services';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';

@Injectable()
export class BondService extends HttpClientBase { 

  get instanceId(): string {
    return this._svcInstanceId;
  }
  private _svcInstanceId: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
    private _bondCreditMapper: BondCreditMapper,
    private _bondInterestRatesMapper: BondInterestRatesMapper,
    private _modelCustomizationMapper: BondModelCustomizationMapper,
    private _bondTermsService: BondTermsService
  ) {
    super(lvDateService, http, '/analytics/bonds');
    this._svcInstanceId = v4();
  }

  /**
   * Create valuation session if there isn't one   
   * @param {string} pricingEnvironmentCode pricing environment
   * @param {IPricingEnvironmentOverrides} pricingEnvironmentOverrides Override
   * @param {boolean} isOpenedFromExcel is application opened from excel
   * @param {string} leversysLocalId is leversys local identifier.
   * @returns promise based IValuationSession
   */
    async createValuationSessionForBond(
    pricingEnvironmentCode: string = '',
    pricingEnvironmentOverrides: IPricingEnvironmentOverrides = null,
    isOpenedFromExcel: boolean,
    leversysLocalId: string,
    ): Promise<IBondValuationSession> {
    try {
      const result = await this.postAsync<IBondValuationSession>({  
        pricingEnvironmentCode: pricingEnvironmentCode,
        pricingEnvironmentOverrides: pricingEnvironmentOverrides,
        isOpenedFromExcel: isOpenedFromExcel,
        leversysLocalId: leversysLocalId,
        instrumentType: "Bond",
      }, '/createSession');

      if (!result) {
        throw new LvAnalyticsError(LvDataMaster.getError('dM-3025'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Create valuation session if there isn't one   
   * @param {string} pricingEnvironmentCode pricing environment
   * @param {IPricingEnvironmentOverrides} pricingEnvironmentOverrides Override
   * @param {boolean} isOpenedFromExcel is application opened from excel
   * @param {string} leversysLocalId is leversys local identifier.
   * @returns promise based IValuationSession
   */
  async loadBondInSession(    
    leversysLocalId: string,
    sessionId: string
    ): Promise<IBondValuationSession> {
    try {
      const result = await this.postAsync<IBondValuationSession>({         
        leversysLocalId: leversysLocalId,
        instrumentType: "Bond",
        sessionId: sessionId,
      }, '/loadInstrummentInSession');

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Load draft in session.
   * @param terms Draft terms.
   * @param sessionId Current session identifier.
   * @returns Populated session object.
   */
  async loadDraftInSession(   
    terms: BondTermsDocument,
    sessionId: string,
    ): Promise<IBondValuationSession> {
    try {
      const result = await this.postAsync<IBondValuationSession>({         
        terms: terms,
        sessionId: sessionId,
      }, '/loadDraftInSession');

      if (!result) {
        throw new LvAnalyticsError(LvDataMaster.getError('dM-3025'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  async overrideSession(session: IBondValuationSession): Promise<void> {
    try {

      if (session.marketData?.credit) {
        session.marketData.credit = this._bondCreditMapper.mapCreditToApi(session.marketData.credit);
      }

      if (session.marketData?.interestRates) {
        session.marketData.interestRates = this._bondInterestRatesMapper.mapInterstRatesToApi(session.marketData.interestRates);
      }

      if (session.modelCustomization) {
        session.modelCustomization = this._modelCustomizationMapper.mapModelCustomizationToApi(session.modelCustomization)
      }

      if (session.instrumentYieldCurve) {
        session.instrumentYieldCurve = this._bondInterestRatesMapper.mapInstrumentTermsStructureToApi(session.instrumentYieldCurve);
      }
      
      if (session.terms) {
        this._bondTermsService.mapToApi(session.terms);
      }

      await this.postAsync(session, '/overrideSession')
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  async loadBondPricing(sessionId: string, leversysLocalId: string, environmentId: string) : Promise<ILoadPricingResponse> {
    return await this.postAsync<ILoadPricingResponse>(null, '/loadPricing', {
      sessionId: sessionId,
      leversysLocalId: leversysLocalId,
      environmentId: environmentId
    });
  }
  
  /**
   * Saves bond pricing.
   * @param request ISaveBondPricingRequest object.
   */
  async saveBondPricing(request: ISaveBondPricingRequest) {
    try {
      await this.postAsync<ISaveBondPricingRequest>(request, '/savePricing');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  async loadBondCredit(sessionId: string, leversysLocalId: string, environmentId: string) : Promise<IBondCredit> {
    return await this.postAsync<IBondCredit>(null, '/loadCredit', {
      sessionId: sessionId,
      leversysLocalId: leversysLocalId,
      environmentId: environmentId
    });
  }

  async loadBondWitholdingTax(sessionId: string, leversysLocalId: string, environmentId: any) : Promise<IBondWithholdingTax> {
    return await this.postAsync<IBondWithholdingTax>(null, '/loadWitholdingTax', {
      sessionId: sessionId,
      leversysLocalId: leversysLocalId,
      environmentId: environmentId
    });
  }

  async loadBondInterestRates(sessionId: string, leversysLocalId: string, environmentId: any) : Promise<IBondInterestRate> {
    return await this.postAsync<IBondInterestRate>(null, '/loadInterestRates', {
      sessionId: sessionId,
      leversysLocalId: leversysLocalId,
      environmentId: environmentId
    });
  }

  /**
   * Load bond market data.
   * @param sessionId Session identifier.
   * @returns Bond market data.
   */
  async loadBondMarketData(sessionId: string) :Promise<IBondMarketData> {
    return await this.postAsync<IBondMarketData>(null, '/reloadMarketData', {
      sessionId: sessionId,    
    });
  }

  /**
   * Saves bond market data.
   * @param request SaveMarketDataRequest object.
   * @returns ISaveMarketDataResponse object.
   */
  async saveBondMarketData(request: SaveBondMarketDataRequest): Promise<ISaveMarketDataResponse> {
    try {
      this.mapBondMarketDataRequestToApi(request);
      const response = await this.postAsync<ISaveMarketDataResponse>(request, '/saveMarketData');
      this.applyMarketDataBondTransformations(response.marketData);

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * send calculation request to backend
   * @param logRequestXml 
   * @param valuationQueryNotes 
   * @param model 
   * @param sessionId 
   */
  async calculateValuation(logRequestXml: boolean, valuationQueryNotes: string, model: IBondValuationInputs, sessionId: string) {
    try {
      
      const request = {
        logRequestXml: logRequestXml,
        valuationQueryNotes: valuationQueryNotes,
        price: model.price,
        valuationDate: DateExtensions.getTimeWithOffset(model.valuationDate as Date),
        sessionId: sessionId,
        correlationId: (window as any).Cypress !== undefined || environment.playwrightVariable ? 'cypress' + v4() : '',
        FlatCreditSpread: model.flatCreditSpread,
      };
      const valuation = await this.postAsync<IValuationResult>(request, '/calculateBond');
      return valuation;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }
  
  /**
   * Saves bond market data.
   * @param request SaveMarketDataRequest object.
   */
  async saveBondModelCustomization(request: ISaveBondModelCustomizationRequest): Promise<void> {
    try {
      await this.postAsync(request, '/saveModelCustomization');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Load bond model customization.
   * @param sessionId Session identifier.
   * @param leversysLocalId Leversys local identifier.
   * @param environmentId Environment identifier.
   * @returns Bond model customization.
   */
  async loadModelCustomization(
    sessionId: string,
    leversysLocalId: string,
    environmentId: any) :Promise<IBondModelCustomization> {
    return await this.postAsync<IBondModelCustomization>(null, '/loadModelCustomization', {
      sessionId: sessionId,
      leversysLocalId: leversysLocalId,
      environmentId: environmentId
    });
  }

  /**
   * Get bond instrument info data.
   * @param leversysLocalId Leversys local identifier.
   * @returns Bond instrument info data.
   */
  async getBondInstrumentInfo(leversysLocalId: string, sessionId: string): Promise<IBasicTerms> {
    return await this.getAsync<IBasicTerms>(
      {
        leversysLocalId: leversysLocalId,
        sessionId: sessionId,
      },
      '/instrumentInfo');
  }

  /**
   * Saves instrument environment section settings.
   * @param request ISaveBondInstrumentEnvironmentSectionSettingsRequest object.
   */
  async saveInstrumentEnvironmentSectionSettings(request: ISaveBondInstrumentEnvironmentSectionSettingsRequest): Promise<void> {
    try {
      request.sourceId = this._svcInstanceId;
      await this.postAsync<void>(request, '/saveInstrumentEnvironmentSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Handles unauthorizes error.
   * @param e ILvError object.
   * @returns LvAnalyticsError object.
   */
  private handleUnauthorizedError(e: ILvError): LvAnalyticsError {
    const error = new LvAnalyticsError(e.message);

    if (e.type === LvErrorType.AUTHORIZATION) {
      error.type = e.type;
    }

    return error;
  }
  
  /**
   * Applies market data transformations.
   * @param settings IMarketData object.
   */
  private applyMarketDataBondTransformations(settings: IBondMarketData) {
    settings.credit = this._bondCreditMapper.mapCreditToUi(settings.credit);
    settings.interestRates = this._bondInterestRatesMapper.mapInterstRatesToUI(settings.interestRates);
  }

  /**
   * Maps save bond market data request to API.
   * @param request SaveBondMarketDataRequest object.
   */
  private mapBondMarketDataRequestToApi(request: SaveBondMarketDataRequest) {
    request.credit.credit = this._bondCreditMapper.mapSaveRequestToApi(request.credit);
    request.interestRates.interestRates = this._bondInterestRatesMapper.mapSaveRequestToApi(request.interestRates);
  }

  /**
   * Applies data entitlement.
   * @param request ISaveSectionRequest or IDataEntitlement object.
   */
  private applyDataEntitlement(request: ISaveSectionRequest | IDataEntitlement) {
    request.sourceId = this._svcInstanceId;
  }
}
