import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { getDefaultDividendProtectionSectionSettings } from '@lv-convertible-bond/models/terms-settings/default-terms-settings/default-dividend-protection-section-settings';
import { IDividendProtectionSectionSettings } from '@lv-convertible-bond/models/terms-settings/dividend-protection-settings/dividend-protection-section-settings';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { EquityOptionSettingsService } from '@lv-custom-instruments/services/equity-opiton-settings/equity-option-settings-service';
import _ from 'lodash';
import { IBaseEquityOptionTermsSettings } from 'src/app/modules/instrument/equity-option-terms/models/base-equity-option-terms-settings';
import { IEquityOptionTermsGeneralSectionSettings } from 'src/app/modules/instrument/equity-option-terms/models/equity-option-terms-general-section-settings';
import { IEquityOptionTermsSettings } from 'src/app/modules/instrument/equity-option-terms/models/equity-option-terms-settings';

@Component({
  selector: 'lv-equity-option-terms-settings',
  templateUrl: './lv-equity-option-terms-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class LvEquityOptionTermsSettingsComponent implements OnInit, IDiscardable{  
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  
  settings: IEquityOptionTermsSettings;
  originalSettings: IEquityOptionTermsSettings;
  constructor(private _errorService: LvErrorService,
              private _equityOptionTermsSettingsService: EquityOptionSettingsService, 
              private _changeDetectorRef: ChangeDetectorRef,               
              private _discardChangesService: DiscardChangesService)
  {
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      my: {
        generalSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IBaseEquityOptionTermsSettings<IEquityOptionTermsGeneralSectionSettings>,
         dividendProtectionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultDividendProtectionSectionSettings()
        } as IBaseEquityOptionTermsSettings<IDividendProtectionSectionSettings>,
      },
      system: {
        generalSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IBaseEquityOptionTermsSettings<IEquityOptionTermsGeneralSectionSettings>,
        dividendProtectionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultDividendProtectionSectionSettings()
        } as IBaseEquityOptionTermsSettings<IDividendProtectionSectionSettings>,
      },
    } as IEquityOptionTermsSettings

    this.originalSettings = {
      my: {
        generalSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IBaseEquityOptionTermsSettings<IEquityOptionTermsGeneralSectionSettings>,
        dividendProtectionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultDividendProtectionSectionSettings()
        } as IBaseEquityOptionTermsSettings<IDividendProtectionSectionSettings>,
      },
      system: {
        generalSettings: {
          overrideSystemDefaults: false,
          settings: {}
        } as IBaseEquityOptionTermsSettings<IEquityOptionTermsGeneralSectionSettings>,
        dividendProtectionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultDividendProtectionSectionSettings()
        } as IBaseEquityOptionTermsSettings<IDividendProtectionSectionSettings>,
      },
    } as IEquityOptionTermsSettings
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }
  
  @HostBinding('class.lv-equity-option-settings')
  get isLvEquityOptionTermsSettingsComponent() {
    return true;
  }

  ngOnInit(): void
  {
    this.setSettings();

    this._discardChangesService.register('Terms', this);
  }
  
  /**
   * Gets Real equity option terms settings from backend.
   */
  async setSettings()
  {
    try{
      this.startLoading.next();
      this.settings = (await this._equityOptionTermsSettingsService.getEquityOptionTermsSettings());
      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves equity option term settings.
   */
  async onSave()
  {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next()
      await this._equityOptionTermsSettingsService.saveEquityOptionTermsSettings(this.settings);
      this.originalSettings = _.cloneDeep(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
      {'settings_section': 'Terms'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  async onCancel()
  {
    this.didCancel.next();
  }

  /**
   * 
   * @returns Method checks if any changes in state happened.
   */
  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  /**
   * Resets equity option settings to default.
   */
  async onResetToDefault()
  {
    try {
      this.startLoading.next();
      this.settings = (await this._equityOptionTermsSettingsService.resetEquityOptionTermsSettings());
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
      {'settings_section': 'Terms'}));
    } 
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Method discards current changes in state that are not saved.
   */
  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }

}
