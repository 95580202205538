import * as _ from 'lodash';

// tslint:disable-next-line:max-line-length
import {
  Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnInit,
  OnDestroy, AfterViewInit, ViewRef, Input, Optional, DestroyRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';

import { LvSubscriberPanelComponent, LvSidePanelComponent } from '@lv-core-ui/components';
import { IInstrumentSelectedEvent, LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService, LvLoggerService } from '@lv-core-ui/services';
import { LvUtil } from '@lv-core-ui/util';
import { RestrictedResourceType } from '@lv-core-ui/models/authorization/restricted-resource-type-enum';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';
import { IResource } from '@lv-core-ui/models/authorization/resource';
import { LvPermissionService } from '@lv-core-ui/services';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';

import { LvSensitivityAnalysisPanelComponent, ILvSensitivityAnalysisPanelState } from '@lv-analytics/components';
import { AnalyticsHubService } from '@lv-analytics/hubs';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { PricingService, ModelCustomizationService, MarketDataService } from '@lv-analytics/services';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { RouteCacheService } from '@lv-cache/route-cache';
import { NotificationPanelComponent } from '@lv-common/views/notification-panel.component';
import { QuickAndFullTermsDocument } from '@lv-convertible-bond/models';
import { IInstrumentLookup } from '@lv-instrument-monitor/models';
import { LvCustomInstrumentComponent, PrivateInstrumentEvent } from '@lv-custom-instruments/components';
import { CustomInstrumentHubService } from '@lv-custom-instruments/hubs/custom-instrument-hub.service';
import { CustomInstrumentsService } from '@lv-custom-instruments/services/custom-instruments.service';
import { LvTermsSummaryComponent } from '@lv-reference-data/components';
import { ReferenceDataHubService } from '@lv-reference-data/hubs';
import { DefaultWidgetType } from '@lv-shared/models';
import { LvSharedDefaultWidgetStateService } from '@lv-shared/services';
import { AppComponent, ApplicationMode } from 'src/app/app.component';
import { IInstrumentComponentState, IInstrumentWidgetVisibility, IInstrumentWidgetState } from '.';
import { AnalyticsComponent } from './analytics/analytics.component';
import { DefaultComponentStateService } from './instrument.state';
import { ResizeHandlerService } from 'src/app/leversys-core-ui/services/resize-handler/resize-handler.service';
import { InstrumentUtil } from './instrument.util';
import { EnvironmentSettingsService } from 'src/app/modules/analytics/services/environment-settings/environment-settings.service';
import { CompanyAndUserSettingsService } from 'src/app/modules/analytics/services/company-settings/company-settings.service';
import { InstrumentView } from './instrument.view';
import { IFullTermsDataEvent } from '@lv-custom-instruments/models/full-terms-data-event';
import { LvExcelService } from '@lv-excel/services';
import { LvTermsGeneralIssueInformationComponent } from '@lv-convertible-bond/components/lv-convertible-bond-terms/lv-terms-general/lv-terms-general-issue-information/lv-terms-general-issue-information.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LvGoldenLayoutService } from '@lv-golden-layout/services';
import { UnderlyingComponent } from './underlying/underlying.component';
import { AnalyticsSettingsEvents } from '@lv-analytics/models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'instrument',
  templateUrl: './instrument.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PricingService,
    LvAnalyticsPresenter,
    WidgetStateManagerService,
    ModelCustomizationService,
    MarketDataService,
    EnvironmentSettingsService
  ]
})
// tslint:disable-next-line:max-line-length
export class InstrumentComponent extends NotificationPanelComponent<IInstrumentComponentState> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(LvSubscriberPanelComponent, { static: true }) notificationPanel: LvSubscriberPanelComponent;
  @ViewChild(LvSidePanelComponent) settingsPanel: LvSidePanelComponent;
  @ViewChild(LvCustomInstrumentComponent) privateInstrumentComponent: LvCustomInstrumentComponent;
  @ViewChild(LvSensitivityAnalysisPanelComponent) sensitivityPanelComponent: LvSensitivityAnalysisPanelComponent;
  @ViewChild(LvTermsSummaryComponent) termsSummaryComponent: LvTermsSummaryComponent;
  @ViewChild(AnalyticsComponent) analyticsComponent: AnalyticsComponent;
  @ViewChild('tabStrip') tabStrip: TabStripComponent;
  @ViewChild('equityTabStrip') equityTabStrip: TabStripComponent;
  @ViewChild(LvTermsGeneralIssueInformationComponent, { static: true }) generalIssueInformationComponent: LvTermsGeneralIssueInformationComponent;
  @ViewChild(UnderlyingComponent) underlyingComponent: UnderlyingComponent;

  @Input() isOpenedFromExcel: boolean;
  @Input() defaultStateOnly: boolean;

  get instrumentReloadVisible(): boolean {
    return this._hubEventOccured;
  }

  get hubReloadEventMessage(): string {
    return this._hubEventMessage;
  }

  get showDocuments(): boolean {
    return !this.isDraftFromExcel && (this.showAnalyticsWidgets && this._permissionService.hasPermission('DOCUMENTS', 'LIST_DOCUMENTS'));
  }

  get editWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Edit Excel View' : 'Edit Custom View';
  }

  get saveWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Save as Excel View' : 'Save as Default View';
  }

  get isPermissionChecked(): boolean {
    return this._isPermissionChecked;
  }

  get shouldShowEditAccessScopeLink(): boolean {
    return this.state.isPrivateInstrument && this._presenter.cHelper.convertible &&
      (this._presenter.cHelper.accessScope === AccessScopeType.User && this._presenter.cHelper.convertible.isOwner);
  }

  get isDraftFromExcel(): boolean {
    return this.isOpenedFromExcel && !!this._excelService?.isIdentifierDraft();
  }

  get isConvertibleBond(): boolean {
    return this.state.instrumentType === 'ConvertibleBond'
  }

  get isBond(): boolean {
    return this.state.instrumentType === 'Bond'
  }

  get isEquity(): boolean {
    return this.state.instrumentType === 'Equity'
  }

  get underlyingEquityName(): string {
    return this._presenter.cHelper?.convertible?.underlying?.name;
  }

  get draftId(): boolean {
    return !!this._presenter.draftId;
  }

  get isAssetSwap(): boolean {
    return this.state.instrumentType === 'ASW'
  }

  get isUseDummyEquitySelected(): boolean {
    return !this.privateInstrumentComponent?.convertibleBondTermsComponent?.useDummyEquity;
  }

  initWidgetsOnTabSelect: boolean;
  keepTabContent: boolean;
  isLoading: boolean;
  view: InstrumentView;

  // Layout Configuration
  model: {
    editCustomView: boolean;
  };

  notAuthorized: boolean;
  init: boolean;
  isPrivateInstrumentInit: boolean;
  instrumentUtil: InstrumentUtil;

  hasFullTermsAccess: boolean;
  showAnalyticsWidgets: boolean;
  isEditingExcelView: boolean;

  private _hubEventOccured: boolean;
  private _hubEventMessage: string;
  private _resizeHandlerService: ResizeHandlerService;
  private _subscriptions: Subscription[];
  private _isPermissionChecked: boolean;
  private _openEquitySubscription: Subscription | null = null;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    applicationSettingsService: ApplicationSettingsService,
    private _router: Router,
    private _logger: LvLoggerService,
    private _errorService: LvErrorService,
    private _routeCacheService: RouteCacheService,
    private _pricingService: PricingService,
    private _analyticsHubService: AnalyticsHubService,
    private _referenceDataHubService: ReferenceDataHubService,
    public _presenter: LvAnalyticsPresenter,
    private _defaultWidgetStateService: LvSharedDefaultWidgetStateService,
    private _instrumentStateService: DefaultComponentStateService,
    private _widgetStateManagerService: WidgetStateManagerService,    
    private _marketDataService: MarketDataService,
    private _privateInstrumentHubService: CustomInstrumentHubService,
    private _modelCustomizationService: ModelCustomizationService,
    private _appComponent: AppComponent,
    private _environmentSettingsService: EnvironmentSettingsService,
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _authorizationService: AuthorizationService,
    private _permissionService: LvPermissionService,
    private authorizationService: AuthorizationService,
    public _destroyRef: DestroyRef,
    private _lvGoldenLayoutService: LvGoldenLayoutService,
    @Optional() private _excelService: LvExcelService
  ) {
    super(changeDetectorRef, applicationSettingsService, _destroyRef);

    this.view = new InstrumentView(null);
    this.keepTabContent = false;
    this.init = true;

    this.isLoading = false;
    this._hubEventOccured = false;
    this._hubEventMessage = null;

    if (_excelService.isInitialized()) {
      this.state = this._instrumentStateService.getDefaultInstrumentComponentState('ConvertibleBond');
    }
    else {
      this.state = { ... this._instrumentStateService.getDefaultInstrumentComponentState(this.state?.instrumentType)};
    }

    this.view.init(this.state.widgets, this.state.instrumentType);

    this._widgetStateManagerService.setDashboardState(this.state, 'analytics');

    this.model = {
      editCustomView: false
    };

    this.defaultStateOnly = false;
    this.notAuthorized = false;
    this.instrumentUtil = {} as InstrumentUtil;
    this._resizeHandlerService = new ResizeHandlerService();

    this.hasFullTermsAccess = false;
    this.showAnalyticsWidgets = false;   
    this.isOpenedFromExcel = false;
    this.isEditingExcelView = false;
    this._isPermissionChecked = false;
    this._openEquitySubscription = null;
  }

  async doOnInit() {
    this.createSubscriptions();
    this.checkAnalyticsValuationAuthorization();

    /**
     * to do check this and see if there any better solution for this.
     */
    if (!this.state.instrumentType
      && this.state.widgets.length > 0
      && !this.state.widgets.find(x => x.id.includes('bond_'))) {
      this.state.instrumentType = 'ConvertibleBond';
    }

    if (this.state.widgets.length === 1 && this.state.widgets[0].id === 'underlying') {
      this.state.instrumentType = 'Equity'
    }

    if (this.state.useDefaultView) {
      const dfState = this._instrumentStateService.getDefaultInstrumentComponentState(this.state.instrumentType);
      this.view.init(dfState.widgets, this.state.instrumentType);
    }
    else {
      this.view.init(this.state.widgets, this.state.instrumentType);
    }

    this._widgetStateManagerService.setDashboardState(this.state, 'analytics');
    this._widgetStateManagerService.setStateId(this.state.id);

    if (this._appComponent.mode === ApplicationMode.Normal) {
      try {
        await this.loadInstrument(this.state.instrumentName);
      }
      catch (error) {
        this.processLoadInstrumentError(error);
      }
      finally {
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  public disableFieldsInEditCustomView(widgetId: string): boolean {
    if (widgetId === 'terms' || (widgetId === 'documents' && !!this._excelService?.isIdentifierDraft())) {
      return true;
    }
    return false;
  }

  private createSubscriptions() {
    this._subscriptions = [
      this._routeCacheService.workspaceReatached.subscribe(wsId => {
        const regex = new RegExp(`\\b${wsId}\\b`, 'ig');
        if (this._router.url.match(regex) && this.applicationSettingsService.isWidgetInWorkspace(wsId, this.stateId)) {
          if (this.sensitivityPanelComponent) {
            this.sensitivityPanelComponent.rerenderChart();
          }
        }
      }),

      this._widgetStateManagerService.onCustomViewUsed.subscribe(s => {
        this.state.useDefaultView = false;
        this.changeDetectorRef.markForCheck();
        this.updateComponentState();
      }),

      this._analyticsHubService.valuationSettingsUpdatedEvent.subscribe(evt => {
        if (this._presenter.convertibleBondSession !== null &&
          (this._pricingService.instanceId !== evt.sourceId &&
            this._marketDataService.instanceId !== evt.sourceId &&
            this._modelCustomizationService.instanceId !== evt.sourceId) &&
          this._presenter.sessionId !== evt.sourceId &&
          this._presenter.asHelper.currentLeversysLocalId === evt.leversysLocalId) {
          this._hubEventOccured = true;
          this._hubEventMessage = 'Instrument Inputs have been changed';
          this._presenter.notifySettingsChanged();
          this.changeDetectorRef.markForCheck();
        }

        if (this._presenter.getSourceIdForEquityRequests !== evt.sourceId &&
          this._presenter.equitySession?.leversysLocalId === evt.leversysLocalId) {
            this.handleEquityUpdateEvents();
        }

        if (this._presenter.bondSession?.sessionId !== evt.sourceId &&
          this._presenter.bondSession?.leversysLocalId === evt.leversysLocalId) {
            this.handleEquityUpdateEvents();
        }
      }),

      this._analyticsHubService.identifiersSavedEventEvent.subscribe(evt => {
        if (this._presenter.getSourceIdForEquityRequests !== evt.sourceId &&
          this._presenter.equitySession?.leversysLocalId === evt.leversysLocalId) {
            this.handleEquityUpdateEvents();
        }
      }),

      this._analyticsHubService.instrumentUpdatedEvent.subscribe(evt => {
        if (this._presenter.getSourceIdForEquityRequests !== evt.sourceId &&
          this._presenter.equitySession?.leversysLocalId === evt.leversysLocalId) {
          this.handleEquityUpdateEvents();
        }
      }),

      this._analyticsHubService.customYieldCurveUpdatedEvent.subscribe(evt => {

        if ((this._pricingService.instanceId !== evt.sourceId && this._marketDataService.instanceId !== evt.sourceId) &&
          (this._presenter.cHelper.currencyCode === evt.customYieldCurveCcy ||
            this._presenter.cHelper.underlyingCurrencyCode === evt.customYieldCurveCcy)) {
          this._hubEventOccured = true;
          this._hubEventMessage = 'custom yield curve have been changed';
          this._presenter.notifySettingsChanged();
          this.changeDetectorRef.markForCheck();
        }
      }),
      this._analyticsHubService.instrumentEnvironmentSettingsUpdatedEvent.subscribe(evt => {

        if (this._presenter.asHelper.currentLeversysLocalId === evt.leversysLocalId &&
          this._environmentSettingsService.svcInstanceId !== evt.sourceId) {
          this._hubEventOccured = true;
          this._hubEventMessage = 'Environment order have been changed';
          this.changeDetectorRef.markForCheck();
        }
      }),

      this._analyticsHubService.otherSettingsUpdatedEvent.subscribe(async evt => {
        if (this._companyAndUserSettingsService.instanceId === evt.sourceId) {
          await this._presenter.loadOtherSettings();

          this.changeDetectorRef.markForCheck();
        }
      }),

      this._referenceDataHubService.convertibleTermsUpdatedEvent.subscribe(evt => {

        if (this._presenter.asHelper.currentLeversysLocalId === evt.leversysId) {
          this._hubEventOccured = true;
          this._hubEventMessage = 'Convertible Terms have been changed';
          this.changeDetectorRef.markForCheck();
        }
      }),

      this._privateInstrumentHubService.privateInstrumentDeletedEvent.subscribe(evt => {
        // todo change this with instrument deleted event and move subscribe logic to Instruments module.
        if (this._presenter.asHelper.currentLeversysLocalId === (evt as any).leversysLocalId) {
          this.onPrivateInstrumentDeleted();
          this.changeDetectorRef.markForCheck();
        }
      }),
    ];

    this.manageOpenEquitySubscription();
  }

  setStateId(stateId: string) {
    super.setStateId(stateId);
    this._widgetStateManagerService.setStateId(stateId);
  }

  onStateChange(changedState: IInstrumentComponentState) {
    super.onStateChange(changedState);
    this._widgetStateManagerService.setDashboardState({ ... this.state }, 'analytics');
  }

  ngAfterViewInit() {
    let selectedTab = 0;
    this.tabStrip?.tabs.forEach((a, i) => {
      if ((this.state.isPrivateInstrument && a.cssClass?.includes('terms-class')) || (this.state.instrumentType === 'Bond')) {
        selectedTab = i;
      }
    });
    this.view.setSelectedTab(selectedTab);

    this.instrumentUtil = new InstrumentUtil(this._resizeHandlerService, this.tabStrip, this.changeDetectorRef);
    this.instrumentUtil.startListening();
  }

  async onPrivateInstrumentChanged(event: PrivateInstrumentEvent) {
    try {
      this.setLoadingState(true);
      this.setDraftId();

      switch (event) {
        case PrivateInstrumentEvent.InstrumentSaved: {
          this.state.isPrivateInstrument = true;
          this.state.leversysId = this.privateInstrumentComponent.leversysId;
          await this.loadInstrument();
          this.changeDetectorRef.detectChanges();
          break;
        }
        case PrivateInstrumentEvent.DraftSaved: {
          if (this._presenter.isConvertibleModelLoaded()) {
            this.applyInstrumentName(this._presenter.cHelper.convertible.name, this.state.instrumentType);
          }

          this.changeDetectorRef.detectChanges();
          break;
        }
      }

      this._presenter.updateModel({
        eventId: AnalyticsSettingsEvents.ConvertibleBondInstrumentSaved,
        data: null
      });
    }
    catch (error) {
      this.processLoadInstrumentError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  onPrivateInstrumentLoaded(termsDocument: QuickAndFullTermsDocument) {
    if (this.setGoldenLayoutContainerTitle) {
      this.setGoldenLayoutContainerTitle(termsDocument.fullTerms.issueAndRedemption.name || 'Instrument');
    }

    if (this.state.instrumentName !== termsDocument.fullTerms.issueAndRedemption.name) {
      this.state.instrumentName = termsDocument.fullTerms.issueAndRedemption.name || 'Instrument';
    }

    this.manageOpenEquitySubscription();
  }

  onPrivateInstrumentReloaded() {

    if (!!this._excelService?.isInitialized()) {
      this.loadInstrument();
      return;
    }

    this.state.instrumentId = null;
    this.state.instrumentName = null;
    this.state.instrumentLwsIdentifier = null;
    this.state.instrumentIsin = null;

    if (this.state.isPrivateInstrument) {
      this.state.region = null;
    }

    if (this.setGoldenLayoutContainerTitle) {
      this.setGoldenLayoutContainerTitle('Instrument');
    }

    this.loadInstrument();
  }

  /**
   * Set draft changed when draft is reloaded. Because when draft is
   * reloaded classic reload event is not sent to instrument component from custom instrument component.
   */

  onPrivateInstrumentDeleted() {
    this.resetState();
    this.state.isPrivateInstrument = false;
    this.loadInstrument();
  }

  /**
   * Reload instrument when underlying terms are saved.
   *
   * @memberof InstrumentComponent
   */
  async onEquitySaved() {
    if (this.state.leversysId && this.privateInstrumentComponent) {
      await this.privateInstrumentComponent.loadTerms();
    }
    await this.loadInstrument();
    this.changeDetectorRef.detectChanges();
  }

  // Implementations from parent class
  async didReceiveMessage(monitor: IInstrumentSelectedEvent) {
    let instrumentTypeChanged = false;

    if (!!monitor.instrumentType &&
      this.state.instrumentType !== monitor.instrumentType) {
      this.state.useDefaultView = true;
      instrumentTypeChanged = true;
    }

    this.state.instrumentId = monitor.id || null;
    this.state.instrumentName = monitor.name || null;
    this.state.instrumentLwsIdentifier = monitor.lwsIdentifier || null;
    this.state.instrumentIsin = monitor.isin || null;
    this.state.isPrivateInstrument = monitor.isPrivateInstrument;
    this.state.region = monitor.region;
    this.state.instrumentType = monitor.instrumentType;
    this.state.leversysId = monitor.leversysId;

    if (instrumentTypeChanged) {
      await this.loadInstrumentAndRerenderForm(monitor.name);
    }
    else {
      await this.loadInstrumentData(monitor.name);
    }
  }

  didlvGlOnDestroy() {
    if (this.state.sessionId) {
      this._presenter.closeSession();
    }

    this.notificationPanel.unregister();
  }

  /**
   * Populate instrument state and load instrument when instrument was
   * found through instrument text search
   * @param info instrument basic data retrieved from instrument lookup
   */
  async onInstrumentSelect(info?: IInstrumentLookup) {
    let instrumentTypeChanged = false;
    if (!!info.instrumentType &&
      this.state.instrumentType !== info.instrumentType) {
      this.state.useDefaultView = true;
      instrumentTypeChanged = true;
    }

    this.state.instrumentId = info.id || null;
    this.state.instrumentName = info.text || null;
    this.state.instrumentLwsIdentifier = info.lwsIdentifier || null;
    this.state.instrumentIsin = info.isin || null;
    this.state.isPrivateInstrument = info.isPrivateInstrument;
    this.state.region = info.region;
    if (!!info.text) {
      this.state.instrumentType = info.instrumentType;
    }
    this.state.leversysId = info.leversysId;

    if (instrumentTypeChanged) {
      await this.loadInstrumentAndRerenderForm(info.text);
    }
    else {
      await this.loadInstrumentData(info.text);
    }
  }

  onSensitivityAnalysisStateUpdated(state: ILvSensitivityAnalysisPanelState) {
    this.state.sensitivityAnalysisState.scenarioTemplateId = state.scenarioTemplateId;
    this.state.sensitivityAnalysisState.isSystem = state.isSystem;
    this.updateComponentState();
  }

  async onTabSelect(event: SelectEvent) {
    if (!this.init || this.state.isPrivateInstrument) {
      this.keepTabContent = true;
    }
    else {
      this.init = false;
    }

    if (event.title === 'Underlying') {
      this.underlyingComponent?.equityComponent?.equityTermsComponent.loadEquityTerms();
    }

    // We want to update draft (if draft is changed) after we change tab from terms to something other.
    // Then draft will be updated and analytics convertible will be refreshed from database.
    if (this.view.currentlySelectedTab === 'terms' && !!this.privateInstrumentComponent) {
      const draftSaved = await this.privateInstrumentComponent.updateTermsInSession();
      this.changeDetectorRef.detectChanges();
    }

    this.view.setSelectedTab(event.index);
    this.changeDetectorRef.detectChanges();
  }

  onShowSettings() {
    this.settingsPanel.togglePanel();
  }

  onDismissInstrumentReload() {
    this._hubEventOccured = false;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Triggered when we approve notification for update Instrument from LWS
   */
  async onReloadInstrument(): Promise<void> {
    try {
      this.setLoadingState(true);

      if (this.state.instrumentType === 'Equity') {
        await this.loadInstrument(this.state.instrumentName, null);
      }
      else {
        await this.loadInstrument(null, null);
      }

      /* When lws notification event has been received, then reload of instrument must pull fresh data for leversys convertlbe not draft */
      if (this.hasFullTermsAccess && this.privateInstrumentComponent) {
        this.privateInstrumentComponent.onReload(true);
      }

      if (this.termsSummaryComponent) {
        this.termsSummaryComponent.loadSections();
      }

      this._hubEventOccured = false;
    }
    catch (error) {
      this.processLoadInstrumentError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  // Layout Configuration
  onDisplayDefaultView() {
    const dfState = this._instrumentStateService.getDefaultInstrumentComponentState(this.state.instrumentType);
    this.view.init(dfState.widgets, this.state.instrumentType);
    this.state.useDefaultView = true;
    this.changeDetectorRef.markForCheck();
    this.updateComponentState();
    this._widgetStateManagerService.setAllWidgetsToUseDefaultView();
  }

  onDisplayCustomView() {
    this.view.init(this.state.widgets, this.state.instrumentType);
    this.state.useDefaultView = false;
    this.changeDetectorRef.markForCheck();
    this.updateComponentState();
  }

  onEditCustomView(event: MouseEvent) {
    this.state.useDefaultView = false;
    event.preventDefault();
    event.stopPropagation();
    this.toggleEditCustomView();
  }

  onEditInstrumentAccessRights() {
    this.privateInstrumentComponent.onSaveAccessRights(this._presenter.cHelper.accessScope, 'Edit Instrument Access Rights');
  }

  getSidePanelWidth(editCustomView: boolean): number {
    return editCustomView ? 232 : 225;
  }

  /**
   * Save default instrument widget state for excel or non excel instrument
   */
  async onSaveAsDefaultView() {
    try {
      this.setLoadingState(true);

      await this._defaultWidgetStateService.saveDefaultWidgetState({
        widgetType: DefaultWidgetType.Instrument,
        state: this.state.widgets.map(w => ({ ...w, state: null })),
        isOpenedFromExcel: this.isOpenedFromExcel,
        instrumentType: this.state.instrumentType,
      });

      this._widgetStateManagerService.saveAsDefaultViewAll(this.state.instrumentType);
      this.state.useDefaultView = true;

      this.updateComponentState();

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1831'), DefaultWidgetType.Instrument);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  onCancelEditCustomView(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleEditCustomView();

    // On cancel restore previous state of widgets
    this.view.init(this.state.widgets, this.state.instrumentType);
  }

  onApplyEditCustomView(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.toggleEditCustomView();

    // instrument apply changes shouldn't affect widget inner state
    this.state.widgets = this.view.widgets.map(a => {
      const tempState = _.cloneDeep(this.state.widgets.find(x => x.id === a.id).state);
      const wgt = _.cloneDeep(a);
      wgt.state = tempState;
      return wgt;
    });

    this.updateComponentState();
  }

  onSetWidgetVisibility(vo: IInstrumentWidgetVisibility, widget: IInstrumentWidgetState) {
    let shouldActivateFirstTab = false;
    this.view.setWidgetVisibility(vo, widget, (activateFirstTab) => {
      shouldActivateFirstTab = activateFirstTab;
    });
    this.changeDetectorRef.markForCheck();

    if (shouldActivateFirstTab) {
      setTimeout(() => {
        if (this.tabStrip) {
          this.tabStrip?.selectTab(0);
          this.changeDetectorRef.detectChanges();
        }
      }, 50);
    }
  }
  // End Layout Configuration

  ngOnDestroy() {
    this.unsubscribeFromWorkspaceRemoved();
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  
  /**
   * Handle equity updated events.
   *
   * @private
   * @memberof InstrumentComponent
   */
  private handleEquityUpdateEvents() {
    this._hubEventOccured = true;
    this._hubEventMessage = 'Instrument Inputs have been changed';
    this._presenter.notifySettingsChanged();
    this.changeDetectorRef.markForCheck();
  }
  
  /**
   * Manage open equity as golden layout tab subscriptions.
   *
   * @private
   * @memberof InstrumentComponent
   */
  private manageOpenEquitySubscription() {
    this._openEquitySubscription?.unsubscribe();

    this._openEquitySubscription = this.privateInstrumentComponent?.convertibleBondTermsComponent?.doOpenEquity
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(equityLeversysLocalId => {
        const equitySettings = this._instrumentStateService.getDefaultInstrumentComponentState('Equity');
        equitySettings.leversysId = equityLeversysLocalId;

        this._lvGoldenLayoutService.addComponentToTab(this.state.id, 'Instrument', 'equity', equitySettings);
      });
  }

  /**
   * Load instrument data and rerender widgets.
   * @param instrumentName Instrument name.
   */
  private async loadInstrumentAndRerenderForm(instrumentName: string) {
    try {
      this.keepTabContent = false;
      this.setLoadingState(true);

      if (this.privateInstrumentComponent && this.state.instrumentLwsIdentifier) {
        this._presenter.setDraftId(null);
      }

      if (this.state.useDefaultView) {
        const dfState = this._instrumentStateService.getDefaultInstrumentComponentState(this.state.instrumentType);
        this.view.init(dfState.widgets, this.state.instrumentType);
        this.state.widgets = dfState.widgets;
      }
      else {
        this.view.init(this.state.widgets, this.state.instrumentType);
      }

      this._widgetStateManagerService.setDashboardState(this.state, 'analytics');
      this._widgetStateManagerService.setStateId(this.state.id);

      await this.loadInstrument(instrumentName || 'Instrument', null);

      this._hubEventOccured = false;
    }
    catch (error) {
      this.processLoadInstrumentError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  private async loadInstrumentData(instrumentName: string) {
    try {
      this.keepTabContent = false;
      this.setLoadingState(true);

      if (this.privateInstrumentComponent && this.state.instrumentLwsIdentifier) {
        this._presenter.setDraftId(null);
      }

      await this.loadInstrument(instrumentName, null);

      this._hubEventOccured = false;
    }
    catch (error) {
      this.processLoadInstrumentError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  // Layout Configuration
  private toggleEditCustomView() {
    this.model.editCustomView = !this.model.editCustomView;
    if (this.isOpenedFromExcel) {
      this.isEditingExcelView = !this.isEditingExcelView;
    }
    this.changeDetectorRef.markForCheck();
  }

  private resetState() {
    this.state.instrumentId = null;
    this.state.instrumentName = null;
    this.state.instrumentLwsIdentifier = null;
    this.state.instrumentIsin = null;
    this.state.region = null;
    this.state.leversysId = null;

    if (this.sensitivityPanelComponent) {
      this.sensitivityPanelComponent.currentScenario.onClear();
    }

    if (!!this.setGoldenLayoutContainerTitle) {
      this.setGoldenLayoutContainerTitle('Instrument');
    }

    this.changeDetectorRef.markForCheck();
  }

  async onTermsDetailedSaved(fullTermsEventData: IFullTermsDataEvent) {
    this.state.instrumentLwsIdentifier = fullTermsEventData.lwsIdentified;
    this.state.isPrivateInstrument = true;

    await this.loadInstrument(fullTermsEventData.instrumentName);
  }

  /**
   * Load instrument in session.
   * @param instrumentName 
   * @param ignoreDraft 
   * @param region 
   * @param changeCustomInstrumentIdentifier 
   */
  public async loadInstrument(
    instrumentName?: string,
    region: string = null
  ): Promise<void> {
    try {

      this._isPermissionChecked = false;
      this.notAuthorized = false;

      if (!this.state.isPrivateInstrument) {
        this.hasFullTermsAccess = await this._authorizationService.hasResourcePermission('TERMS', 'VIEW_FULL_TERMS',
          { identifier: this.state.leversysId, region: region ?? this.state.region } as IResource);

        this._isPermissionChecked = true;
      }
      else {
        this.hasFullTermsAccess = await this._permissionService.hasPermission('PRIVATE_INSTRUMENTS', 'CREATE_PRIVATE_INSTRUMENT');
      }

      // load only selected tab content on load instrument
      this.init = true;
      this.changeDetectorRef.markForCheck();

      await this._logger.log('instrument loaded ' + this.state.leversysId);
      
      let loadSessionResult = null;

      this.checkAnalyticsValuationAuthorization();

      if (this.showAnalyticsWidgets) {

        if (this.state.instrumentType === 'ConvertibleBond') {
          // Case when draft is created and it is not saved. On next load of instrument data should be erased.
          if (this.state.instrumentName !== null && !this.state.leversysId) {
            this.resetState();
            this.state.sessionId = null;
            instrumentName = '';
          }
        }

        loadSessionResult = await this._presenter.load({
          identifier: this.state.instrumentLwsIdentifier,
          isPrivate: this.state.isPrivateInstrument,
          sessionId: this.state.sessionId,
          instrumentType: this.state.instrumentType,
          leversysId: this.state.leversysId,
        });
      }

      if (loadSessionResult) {
        if (!loadSessionResult.convertible && !this.hasFullTermsAccess) {
          this.resetState();
          instrumentName = null;
        }

        if (!this.state.sessionId) {
          this.state.sessionId = loadSessionResult.sessionId;
        }
        this.state.accessScope = loadSessionResult.convertible ? loadSessionResult.convertible.accessScope : null;
      }

      this.applyChildComponentsState();

      instrumentName = this.applyInstrumentName(instrumentName, this.state.instrumentType);

      if (this.state.instrumentType === 'Bond' && !instrumentName) {
        instrumentName = this._presenter.bondSession?.terms?.general.name;
        this.applyInstrumentName(instrumentName, this.state.instrumentType);
      }

      if (this.state.instrumentType === 'Equity' && !instrumentName) {
        instrumentName = this._presenter.equitySession?.terms?.name;
        this.applyInstrumentName(instrumentName, this.state.instrumentType);
      }

      this.selectedTabTermsSetup();

      if (!this.hasFullTermsAccess && !this.showAnalyticsWidgets) {
        this.notAuthorized = true;
        this.changeDetectorRef.detectChanges();
      }

      this.updateComponentState();
    }
    catch (error) {
      throw error;
    }
    finally {
      if (!(this.changeDetectorRef as ViewRef).destroyed) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  private selectedTabTermsSetup() {

    if (this.state.instrumentType === 'Bond' && !this.state.instrumentName) {
      const activeTabIndex = this.tabStrip?.tabs.toArray().findIndex(x => x.cssClass?.includes('terms-class'));
      this.tabStrip?.selectTab(activeTabIndex);
      this.keepTabContent = true;
      this.changeDetectorRef.detectChanges();
    }
    else {
      const tabIndex = 0;
      if (this.state.isPrivateInstrument && (!this._presenter.asHelper.instrumentLoaded || this._excelService.isInitialized())) {

        const selectedTabIndex = this.tabStrip?.tabs.toArray().findIndex(x => x.selected);
        const activeTabIndex = this.tabStrip?.tabs.toArray().findIndex(x => x.cssClass?.includes('terms-class'));

        if (activeTabIndex !== selectedTabIndex) {
          if (activeTabIndex >= 0) {
            this.tabStrip?.tabs.forEach(a => a.selected = false);
            this.tabStrip?.selectTab(activeTabIndex);
            this.changeDetectorRef.detectChanges();
          }
          else {
            this.tabStrip?.selectTab(tabIndex);
            this.changeDetectorRef.detectChanges();
          }
        }
      }
      else {
        if (this.state.isPrivateInstrument) { // SYSTEM-1949-Issue with first opening of private instrument
          this.keepTabContent = true;
        }
        const activeTabIndex = this.tabStrip?.tabs.toArray().findIndex(x => x.selected);
        let tabLength = this.tabStrip?.tabs.length;
        if (activeTabIndex >= 0 && tabLength > 1) {
          this.tabStrip?.tabs.forEach(a => a.selected = false);
          tabLength = this.tabStrip?.tabs.length;
          tabLength > 1 ? this.tabStrip?.selectTab(activeTabIndex) : this.tabStrip?.selectTab(0);
          this.changeDetectorRef.detectChanges();
        }
        else {
          this.keepTabContent = true;
          setTimeout(() => {
            if (this.tabStrip) {
              this.tabStrip?.selectTab(tabIndex);
              this.changeDetectorRef.detectChanges();
            }
          }, 10);
        }
      }
    }
  }
  
  /**
   * Setup instrument name when bond draft is ready.
   * @param instrumentName Instrument name.
   */
  onConvertibleBondDraftSet(instrumentName: string) {
    this.applyInstrumentName(instrumentName, 'ConvertibleBond')
  }

  /**
   * Setup instrument name when bond draft is ready.
   * @param instrumentName Instrument name.
   */
  onBondDraftSet(instrumentName: string) {
    this.applyInstrumentName(instrumentName, 'Bond')
  }

  /**
   * Set Equity name on instrument form upon saving equity.
   * @param instrumentName 
   */
  onEquityNameSet(instrumentName: string) {
    this.applyInstrumentName(instrumentName, 'Equity')
  }

  /**
   * Apply instrument name in state.
   * We added (Draft) on instrument name only if draftId exist (only if we change some terms fields before we save Custom instrument)
   * @param instrumentName
   */
  private applyInstrumentName(instrumentName: string, instrumentType: string) {
    if (this._presenter.isConvertibleModelLoaded()) {
      instrumentName = this._presenter.cHelper.convertible?.name;
      if (this.privateInstrumentComponent &&
          this.privateInstrumentComponent.isDraft &&
          !this.privateInstrumentComponent.isCustomInstrument) {
        instrumentName += ' (Draft)';
      }

      if (!instrumentName && instrumentType === 'Equity') {
        instrumentName = this._presenter.equitySession.terms?.name;
      }

      this.state.instrumentName = instrumentName;
    }
    else if (instrumentName && instrumentType === 'Bond') {
      if (this._presenter.bondSession?.terms) {
        this.state.instrumentName = instrumentName;
        this.state.leversysId = this._presenter.bondSession.leversysLocalId;
      }
      else {
        instrumentName = '';
        this.state.instrumentName = '';
      }
    }
    else if (instrumentName && instrumentType === 'Equity') {
      this.state.instrumentName = instrumentName;
      this.state.leversysId = this._presenter.equitySession.leversysLocalId;
    }
    // Condition is extended with check if instrument form is part of Golden Layout.
    // Instrument form for external instrument is not part of this layout hence the additional check.
    if (instrumentName && !this.defaultStateOnly && !!this.setGoldenLayoutContainerTitle) {
      this.setGoldenLayoutContainerTitle(instrumentName);
    }
    // Condition is extended with check if instrument form is part of Golden Layout.
    // Instrument form for external instrument is not part of this layout hence the additional check.
    else if (!this._excelService?.isInitialized() && !!this.setGoldenLayoutContainerTitle && !this.state.instrumentName) {
      this.setGoldenLayoutContainerTitle('Instrument');
    }

    this.updateComponentState();

    return instrumentName;
  }

  private setDraftId(): void {
    if (this._presenter.getModelData() && this._presenter.getModelData().convertible) {
      const isDraft = !!this.privateInstrumentComponent ? this.privateInstrumentComponent.isDraft : false;

      this._presenter.getModelData().convertible.isDraft = isDraft;
      if (isDraft) {
        this._presenter.setDraftId(this.privateInstrumentComponent.draftId);
      }
    }
  }

  private applyChildComponentsState() {
    this.applySensitivityAnalysisState();
  }

  private applySensitivityAnalysisState() {
    this.state.sensitivityAnalysisState = LvUtil.clone(this.state.sensitivityAnalysisState);
  }

  private updateComponentState() {
    this.applicationSettingsService.updateWidget(this.stateId, wgt => {
      wgt.state = { ... this.state };
    });
  }

  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.changeDetectorRef.markForCheck();
  }

  private processLoadInstrumentError(error: LvError) {
    this._errorService.handleError(error, e => {
      this.notAuthorized = e.type === LvErrorType.AUTHORIZATION;
      if (this.notAuthorized) {
        if (this.hasFullTermsAccess) {
          this.showTerms();
        }
        else {
          this.view.clearTabSelection();
          if (!this.notAuthorized) {
            this._errorService.toastrService.error(e.message, e.name);
          }
        }
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  private showTerms() {
    this.checkAnalyticsValuationAuthorization();
    this.notAuthorized = false;

    setTimeout(() => {
      if (this.tabStrip) {
        this.tabStrip?.selectTab(0);
        this.changeDetectorRef.detectChanges();
      }
    }, 400);
  }

  private checkAnalyticsValuationAuthorization() {
    this.showAnalyticsWidgets = this.authorizationService.authorize('ANALYTICS', 'VALUATION',
      {
        restrictedResourceType: RestrictedResourceType.Instrument,
        identifier: this.state.leversysId,
      } as IResource);
  }
}
