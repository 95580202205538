import {
  Component, ViewEncapsulation, ChangeDetectionStrategy,
  ChangeDetectorRef, ViewChild, ElementRef, Optional,
  DestroyRef
} from '@angular/core';

import { filter, Subscription } from 'rxjs';

import { ModelCustomizationView } from './lv-model-customization.view';
import { LvEnvironmentSettingsComponent, IEnvironmentSettingsItem } from '../../lv-environment-settings/lv-environment-settings.component';
import { LvAnalyticsPresenter } from '../../../lv-analytics.presenter';
import { DialogService } from '@progress/kendo-angular-dialog';
// tslint:disable-next-line:max-line-length
import { LvModelCustomizationCustomSelectionComponent, IModelCustomizationState } from '../lv-model-customization-custom-selection/lv-model-customization-custom-selection.component';
import * as _ from 'lodash';
import { LvDateService, LvErrorService, ResizeHandlerService } from '@lv-core-ui/services';
import { LvBaseWidgetComponent } from '@lv-analytics/lv-base-widget.component';
import { ModelCustomizationService, ValuationSessionService } from '@lv-analytics/services';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { DefaultWidgetType } from '@lv-shared/models';
import { AnalyticsCommands, AnalyticsSettingsEvents, PricingEnvironmentSections } from '@lv-analytics/models';
import { LvExcelService } from '@lv-excel/services';
import { LvExcelSaveModalComponent } from '@lv-excel/components/lv-excel-save-modal/lv-excel-save-modal.component';
import { LvDataMaster } from '@lv-core-ui/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TermsChangedEvent } from '@lv-analytics/models/events/terms-changed-event';

/**
 * Model customization component.
 */
@Component({
  selector: 'lv-model-customization',
  templateUrl: './lv-model-customization.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvModelCustomizationComponent extends LvBaseWidgetComponent<IModelCustomizationState> {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild('modelCustomizationContent', { static: true }) modelCustomizationContent: ElementRef;

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc && this._excelSvc.isInitialized();
  }

  get dividentProtectionModel(): string {
    return this.isOpenedFromExcel ? 'Dvd. Protection Model' : 'Dividend Protection Model';
  }

  get expectedLifeExclDefault(): string {
    return this.isOpenedFromExcel ? 'Exp Life Excl. Default' : 'Expected Life Excl. Default';
  }

  get editWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Edit Excel View' : 'Edit Custom View';
  }

  get saveWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Save as Excel View' : 'Save as Default View';
  }

  isLoading: boolean;
  view: ModelCustomizationView;
  modelCustomizationSection = PricingEnvironmentSections.ModelSettings;

  private _subscriptions: Subscription[];

  constructor(
    public _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _valuationSessionService: ValuationSessionService,
    private _presenter: LvAnalyticsPresenter,
    private _resizeHandlerService: ResizeHandlerService,
    private _dialogService: DialogService,
    public _widgetStateManagerService: WidgetStateManagerService,
    private _modelCustomizationSettingsService: ModelCustomizationService,
    private _lvDateService: LvDateService,
    private _destroyRef: DestroyRef,
    @Optional() private _excelSvc: LvExcelService
  ) {
    super(
      _changeDetectorRef,
      _widgetStateManagerService,
      getInitialState(!!_excelSvc?.isInitialized()),
      DefaultWidgetType.ModelCustomization
    );
    this._subscriptions = [];
    this.view = new ModelCustomizationView(this.widgetState, this._excelSvc, _lvDateService);
    this.view.init();
  }

  /**
   * Does initialization.
   */
  onInit() {
    this._subscriptions = [
      this._presenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._presenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        if (evt) {
          this.view.init(this._presenter.getModelData());
        }
        else {
          this.view.init();
        }
        this._changeDetectorRef.detectChanges();
      }),
    ];

    this._presenter.termsChanged
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(async evt => {
        if (evt === TermsChangedEvent.PriorityFields) {
          this.view.init(this._presenter.getModelData());
        }
        else {
          this.view.cHelper.init(this._presenter.getModelData().convertible);
        }
        this._changeDetectorRef.detectChanges();
      });
      
    this._presenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => event?.eventId === AnalyticsSettingsEvents.CreditUpdated
        || event?.eventId === AnalyticsSettingsEvents.MarketDataUpdated
        || event?.eventId === AnalyticsSettingsEvents.ValuationSettingsModelUpdated
      ))
      .subscribe(async evt => {
        this.view.init(this._presenter.getModelData());
        this._changeDetectorRef.detectChanges();
      });

    if (this._presenter.isConvertibleModelLoaded()) {
      this.view.init(this._presenter.getModelData());
      this._changeDetectorRef.detectChanges();
    }

    this._resizeHandlerService.listenTo(this.modelCustomizationContent, elementRect => {
      if (elementRect.width > 0) {
        this.view.onResize(elementRect.width, this.widgetState);
      }
      this._changeDetectorRef.detectChanges();
    });
  }

  /**
   * Occurs on model customization change and updates model customization.
   */
  async onModelCustomizationChange() {
    this.updateModelCustomization();
  }

  /**
   * Occurs on model customization input key down and updates model customization.
   */
  async onModelCustomizationInputKeyDown() {
    this.updateModelCustomization();
  }

  /**
   * Occurs on model customization shange input blur and updates model customization.
   */
  async onModelCustomizationInputBlur() {
    this.updateModelCustomization();
  }

  /**
   * Updates model customization.
   */
  async updateModelCustomization() {
    try {
      this.setLoadingState(true);

      await this._valuationSessionService.overrideModelCustomization(
        this.view.getOverrideRequest(this._modelCustomizationSettingsService.instanceId)
      );

      this.updateModel();
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on save section.
   */
  async onSaveSection() {
    try {
      this.setLoadingState(true);
      this._presenter.validateBeforeSave();

      const env = this.envSettings.getSelectedEnvironment();

      // tslint:disable-next-line: max-line-length
      await this._modelCustomizationSettingsService.saveModelCustomization(this.view.getSaveRequest(env.id, this._modelCustomizationSettingsService.instanceId));

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1822'), 'Model Customization Settings');
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }
  /**
   * Saves data to Excel.
   */
  saveDataToExcel() {
    const fields = this._excelSvc.getMappedFields('Model Customization');

    if (this._excelSvc.isAnyV3version()) {

      this._excelSvc.saveDataToExcelV2(fields,
        this._presenter.sessionId,
        this._presenter.getInstrumentIdentifier(),
        'Model Customization',
        null);
    }
    else {
      this._presenter.overwriteModelCustomizationExcelInitialData();
      this._excelSvc.saveDataToExcel(this.view.modelCustomizationMapper.reverseMap(fields, 'Model Customization'));
    }
  }

  /**
   * Saves section to Excel.
   */
  onSaveSectionToExcel() {
    const dialogRef = this._dialogService.open({
      title: 'Save Destination',
      content: LvExcelSaveModalComponent,
      width: 240,
      height: this._presenter.isInstrumentDraft ? 90 : 180
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-save-excel');

    const dialog = dialogRef.content.instance as LvExcelSaveModalComponent;
    dialog.isTermsSave = this._presenter.isInstrumentDraft;

    dialog.doSaveToApplication.subscribe(() => {
      this.onSaveSection();
    });

    dialog.doSaveToAll.subscribe(() => {
      this.onSaveSection();
      this.saveDataToExcel();
    });

    dialog.doSaveToExcel.subscribe(() => {
      this.saveDataToExcel();
    });
  }

  /**
   * Occurs on reload section.
   */
  async onReloadSection() {
    try {
      this.setLoadingState(true);

      await this._presenter.reloadModelCustomization();
      this.loadModelCustomization();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on change environment.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangeEnvironment(environment: IEnvironmentSettingsItem) {
    this._presenter.setModelCustomizationEnv(environment);
    this.onReloadSection();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  onDestroy(): void {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Occurs on edit custom view.
   */
  onEditCustomView() {
    const dialogRef = this._dialogService.open({
      title: 'Model Customization Custom Selection',
      content: LvModelCustomizationCustomSelectionComponent,
      width: 486
    });

    const dialog = (dialogRef.content.instance as LvModelCustomizationCustomSelectionComponent);
    this.widgetState.instrumentType = 'ConvertibleBond';
    dialog.modelCustomizationState = _.cloneDeep(this.widgetState);

    dialog.didSave.subscribe(
      (updatedState) => {
        this.widgetState = updatedState as IModelCustomizationState;
        this.widgetState.useDefaultView = false;
        this._widgetStateManagerService.saveWidgetState(this.widgetType, this.widgetState);
        this._widgetStateManagerService.useCustomView(this.widgetType);
        this.view.updateModelCustomizationState(this.widgetState);
        this._changeDetectorRef.detectChanges();
      }
    );
  }

  /**
   *  method checks two conditions: if instrument is opened in Excel and if corresponding checkbox is unchecked;
   *  if conditions are fullfiled, maturitiDate will be set to null;
   *  after saving parameters, Excel should recive empty filed for given date
   */
  onMaturityDateOverrideChange() {
    if (this.isOpenedFromExcel && this.view.modelSettings.isMaturityDateOverride === false) {
        this.view.modelSettings.maturityDateOverride = null;
     }
    this.onModelCustomizationChange();
  }

  /**
   *  method checks two conditions: if instrument is opened in Excel and if corresponding checkbox is unchecked;
   *  if conditions are fullfiled, notCallableDate will be set to null;
   *  after saving parameters, Excel should recive empty filed for given date
   */
  onNotCallableDateChange() {
    if (this.isOpenedFromExcel && this.view.modelSettings.isNotCallableDate === false) {
      this.view.modelSettings.callAdjustmentModelSettings.notCallableDate = null;
   }
    this.onModelCustomizationChange();
  }

  /**
   *  method checks two conditions: if instrument is opened in Excel and if corresponding checkbox is unchecked;
   *  if conditions are fullfiled, conversionNoticeDate will be set to null;
   *  after saving parameters, Excel should recive empty filed for given date
   */
  onConversionNoticeGivenChange() {
    if (this.isOpenedFromExcel && this.view.modelSettings.conversionNoticeGiven === false) {
      this.view.modelSettings.conversionNoticeDate = null;
   }
    this.onModelCustomizationChange();
  }

  /**
   * Loads model customization.
   */
  private async loadModelCustomization() {
    try {
      this.setLoadingState(true);

      const settings = this._presenter.modelCustomization;
      this.view.updateModelSettings(settings);
      this.updateModel();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Loads state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.envSettings.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Updates model.
   */
  private updateModel() {
    this._presenter.updateModel({
      eventId: AnalyticsSettingsEvents.ModelCustomizationUpdated,
      data: this.view.modelSettings
    });
  }
}

/**
 * Get initial state.
 * @param isOpenedFromExcel A flag indicating if instrument is opened from Excel.
 * @returns IModelCustomizationState object.
 */
function getInitialState(isOpenedFromExcel: boolean): IModelCustomizationState {
  return {
    useDefaultView: true,
    excludeTerms: {
      isExcludeTerms: true,
      isCall: true,
      isPut: true,
      isDividendProtection: true,
      isContingentConversion: true,
      isConversionPriceReset: false,
      isVariableConversion: false,
      isCurrentConversionAveraging: false,
      isCurrentResetAveraging: false
    },
    includeTerms: {
      isIncludeTerms: false,
      isForwardConversionAveraging: false,
      isForwardResetAveraging: false
    },
    modelType: {
      isModelType: true,
      isCreditModelType: true,
      isEquityModelType: false,
      isUnderlyingCreditModel: false,
      isStochasticCredit: true,
      isNumberOfCreditPoints: true,
      isStochasticCreditBC: true,
    },
    callAdjustments: {
      isCallAdjustments: true,
      isHardCallModelType: true,
      isHardCallAdjustFactor: true,
      isSoftCallModelType: true,
      isSoftCallAdjustFactor: false,
      isUseCallNotice: false,
      isTriggerMonitoring: false,
      isNotCallableDate: false,
      isNotCallableParity: false,
    },
    otherParameters: {
      isOtherParameters: true,
      isDividendProtectionModel: true,
      isPEPSParityConvention: false,
      isExpectedLifeExcludingDefault: false,
      isDefaultOnLowParity: false,
      isExchangeableAsConvertible: true,
      isIncludeCashRebateInParity: true,
      isForceOptionalReset: true,
      isMaturityDateOverride: false,
      isFwdDividProtectionAdjst: true,
      isConversionNoticeGiven: false,
      isAssumeDividendYieldUntilMaturity : true
    },
    isOpenedFromExcel: isOpenedFromExcel
  } as IModelCustomizationState;
}

