<lv-flex-box class="credit-settings-column">

    <lv-flex-box
      direction="row"
      data-cy="DM-1462">
  
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditSourceEquityOptionSettingsTooltipTemplate"
        filter="span">
        <span> Credit Source </span>
      </div>
  
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="creditSource"
          lvId="equity-option-credit-settings-credit-source"
          valueField="id"
          textField="text"
          [data]="creditSource.items"
          [valuePrimitive]="true"
          [(ngModel)]="settings.creditSource"
          [disabled]="!overrideSystemDefaults"
          [popupSettings]="{
            width: 226
          }">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
  
    <lv-flex-box
      direction="row"
      data-cy="DM-1466">
  
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditRecoveryCDSEquityOptionSettingsTooltipTemplate"
        filter="span">
        <span> Recovery (CDS) </span>
      </div>
  
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="recoveryCds"
          lvId="credit-settings-recovery-cds"
          [format]="numberFormat"
          [decimals]="numberOfDecimals"
          [(ngModel)]="settings.recoveryCds"
          [disabled]="!overrideSystemDefaults">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
  
    <lv-flex-box
      direction="row"
      data-cy="DM-1467">
  
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditBadDayConventionEquityOptionSettingsTooltipTemplate"
        filter="span">
        <span>  Bad Day Convention </span>
      </div>
  
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="badDayConvention"
          lvId="credit-settings-bad-day-convention"
          valueField="id"
          textField="text"
          [data]="badDayConvention.items"
          [valuePrimitive]="true"
          [(ngModel)]="settings.badDayConvention"
          [disabled]="!overrideSystemDefaults"
          [popupSettings]="{
            width: 137
          }">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
  
    <lv-flex-box
      direction="row"
      data-cy="DM-1468">
  
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditAccruedOnDefaultEquityOptionSettingsTooltipTemplate"
        filter="span">
        <span> Accrued on Default </span>
      </div>
  
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="accruedOnDefault"
          lvId="credit-settings-accrued-on-default"
          valueField="id"
          textField="text"
          [data]="accruedOnDefault.items"
          [valuePrimitive]="true"
          [(ngModel)]="settings.accruedOnDefault"
          [disabled]="!overrideSystemDefaults">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
  </lv-flex-box>
  
  <ng-template #creditSourceEquityOptionSettingsTooltipTemplate>
    <lv-tooltip dm="dM-1462"></lv-tooltip>
  </ng-template>
  <ng-template #creditRecoveryCDSEquityOptionSettingsTooltipTemplate>
    <lv-tooltip dm="dM-1466"></lv-tooltip>
  </ng-template>
  <ng-template #creditBadDayConventionEquityOptionSettingsTooltipTemplate>
    <lv-tooltip dm="dM-1467"></lv-tooltip>
  </ng-template>
  <ng-template #creditAccruedOnDefaultEquityOptionSettingsTooltipTemplate>
    <lv-tooltip dm="dM-1468"></lv-tooltip>
  </ng-template>