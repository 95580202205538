import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IEquityOptionDividendsSectionSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-dividend-settings/market-data-equity-option-dividends-section-settings';
import { IEquityOptionDividendsSettings } from '@lv-analytics/models/equity-option/market-data/market-data-equity-option-settings/market-data-equity-option-dividend-settings/market-data-equity-option-dividends-settings';

@Component({
  selector: 'lv-equity-option-dividends-settings',
  templateUrl: './lv-equity-option-dividends-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvEquityOptionDividendsSettingsComponent {
  @Input() instrumentType: string;
  @Input() settings: IEquityOptionDividendsSettings;

  constructor() {
    this.settings = {
      systemDefaults: {} as IEquityOptionDividendsSectionSettings,
      environments: []
    } as IEquityOptionDividendsSettings;
  }

  @HostBinding('class.lv-dividends-settings')
  get isLvDividendsSettingsComponent() {
    return true;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}

