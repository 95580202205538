// tslint:disable-next-line: max-line-length
import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { v4 } from 'uuid';
import * as _ from 'lodash';
import { IBondModelCustomizationState } from '@lv-analytics/models/model-customization/bond/bond-model-customization-state';

// TODO: Create class in proper package after SYSTEM=412 mrge.
/**
 * Model customization state.
 */
export class IModelCustomizationState {
  useDefaultView: boolean;
  instrumentType: string;
  excludeTerms: {
    isExcludeTerms: boolean;
    isCall: boolean;
    isPut: boolean;
    isDividendProtection: boolean;
    isContingentConversion: boolean;
    isConversionPriceReset: boolean;
    isVariableConversion: boolean;
    isCurrentConversionAveraging: boolean;
    isCurrentResetAveraging: boolean;
  };
  includeTerms: {
    isIncludeTerms: boolean;
    isForwardConversionAveraging: boolean;
    isForwardResetAveraging: boolean;
  };
  modelType: {
    isModelType: boolean;
    isCreditModelType: boolean;
    isEquityModelType: boolean;
    isUnderlyingCreditModel: boolean;
    isStochasticCredit: boolean;
    isNumberOfCreditPoints: boolean;
    isStochasticCreditBC: boolean;
    isPricerVersion: boolean;
  };
  callAdjustments: {
    isCallAdjustments: boolean;
    isHardCallModelType: boolean;
    isHardCallAdjustFactor: boolean;
    isSoftCallModelType: boolean;
    isSoftCallAdjustFactor: boolean;
    isUseCallNotice: boolean;
    isTriggerMonitoring: boolean;
    isNotCallableDate: boolean;
    isNotCallableParity: boolean;
  };
  otherParameters: {
    isOtherParameters: boolean;
    isDividendProtectionModel: boolean;
    isPEPSParityConvention: boolean;
    isExpectedLifeExcludingDefault: boolean;
    isExchangeableAsConvertible: boolean;
    isDefaultOnLowParity: boolean;
    isIncludeCashRebateInParity: boolean;
    isForceOptionalReset: boolean;
    isMaturityDateOverride: boolean;
    isFwdDividProtectionAdjst: boolean;
    isConversionNoticeGiven: boolean;
    isAssumeDividendYieldUntilMaturity: boolean;
  };
  isOpenedFromExcel: boolean;
}

/**
 * Model customization custom selection component.
 */
@Component({
  selector: 'lv-model-customization-custom-selection',
  templateUrl: './lv-model-customization-custom-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvModelCustomizationCustomSelectionComponent implements OnInit {

  @Output()
  didSave: EventEmitter<IModelCustomizationState | IBondModelCustomizationState>;

  @Input()
  set modelCustomizationState(value: IModelCustomizationState | IBondModelCustomizationState) {
    if (value) {
      this._modelCustomizationState = _.cloneDeep(value);
      this._originalModelCustomizationState = value;
    }
  }
  get modelCustomizationState(): IModelCustomizationState | IBondModelCustomizationState {
    return this._modelCustomizationState;
  }

  get isConvertibleBond(): boolean {
    return this._modelCustomizationState.instrumentType === 'ConvertibleBond';
  }

  get convertibleBondModelCustomizationState(): IModelCustomizationState {
    return this.modelCustomizationState as IModelCustomizationState;
  }

  _originalModelCustomizationState: IModelCustomizationState | IBondModelCustomizationState;
  _modelCustomizationState: IModelCustomizationState | IBondModelCustomizationState;

  isLoading: boolean;

  modelCustomizationExcludeTermsId: string;
  modelCustomizationCallId: string;
  modelCustomizationPutId: string;
  modelCustomizationDividendProtectionId: string;
  modelCustomizationContingentConversionId: string;
  modelCustomizationConversionPriceResetId: string;
  modelCustomizationVariableConversionId: string;
  modelCustomizationCurrentConvAveragingId: string;
  modelCustomizationCurrentResetAveragingId: string;
  modelCustomizationIncludeTermsId: string;
  modelCustomizationForwardConvAveragingId: string;
  modelCustomizationForwardResetAveragingId: string;
  modelCustomizationModelTypeId: string;
  modelCustomizationCreditModelTypeId: string;
  modelCustomizationEquityModelTypeId: string;
  modelCustomizationUnderlyingCreditModelId: string;
  modelCustomizationStochasticCreditId: string;
  modelCustomizationNumberOfCreditPointsId: string;
  modelCustomizationStochasticCreditBCId: string;
  modelCustomizationCallAdjustmentsId: string;
  modelCustomizationHardCallModelTypeId: string;
  modelCustomizationHardCallAdjustFactorId: string;
  modelCustomizationSoftCallModelTypeId: string;
  modelCustomizationSoftCallAdjustFactorId: string;
  modelCustomizationUseCallNoticeId: string;
  modelCustomizationTriggerMonitoringId: string;
  modelCustomizationOtherParametersId: string;
  modelCustomizationDividendProtectionModelId: string;
  modelCustomizationPEPSParityConventionId: string;
  modelCustomizationExpectedLifeExcludingDefaultId: string;
  modelCustomizationDefaultOnLowParityId: string;
  modelCustomizationExchangeableAsConvertibleId: string;
  modelCustomizationIncludeCashRebateInParityId: string;
  modelCustomizationForceOptionalResetId: string;
  modelCustomizationApplyConversionAveragingId: string;
  modelCustomizationNotCallableDateId: string;
  modelCustomizationNotCallableParityId: string;
  modelCustomizationMaturityDateOverride: string;
  modelCustomizationFwdDividProtectioAdjstId: string;
  modelCustomizationConversionNoticeGivenId: string;
  modelCustomizationAssumeDvdUntilMaturityId: string;
  modelCustomizationPricerVersionId: string;
  modelCustomizationDialogScreenDm: string;

  constructor(
    private dialogRef: DialogRef,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.didSave = new EventEmitter<IModelCustomizationState>();
    this._modelCustomizationState = {} as IModelCustomizationState;
    this._originalModelCustomizationState = {} as IModelCustomizationState;
    this.isLoading = false;

    this.modelCustomizationExcludeTermsId = v4();
    this.modelCustomizationCallId = v4();
    this.modelCustomizationPutId = v4();
    this.modelCustomizationDividendProtectionId = v4();
    this.modelCustomizationContingentConversionId = v4();
    this.modelCustomizationConversionPriceResetId = v4();
    this.modelCustomizationVariableConversionId = v4();
    this.modelCustomizationCurrentConvAveragingId = v4();
    this.modelCustomizationModelTypeId = v4();
    this.modelCustomizationCreditModelTypeId = v4();
    this.modelCustomizationEquityModelTypeId = v4();
    this.modelCustomizationUnderlyingCreditModelId = v4();
    this.modelCustomizationStochasticCreditId = v4();
    this.modelCustomizationNumberOfCreditPointsId = v4();
    this.modelCustomizationStochasticCreditBCId = v4();
    this.modelCustomizationCallAdjustmentsId = v4();
    this.modelCustomizationHardCallModelTypeId = v4();
    this.modelCustomizationHardCallAdjustFactorId = v4();
    this.modelCustomizationSoftCallModelTypeId = v4();
    this.modelCustomizationSoftCallAdjustFactorId = v4();
    this.modelCustomizationUseCallNoticeId = v4();
    this.modelCustomizationTriggerMonitoringId = v4();
    this.modelCustomizationOtherParametersId = v4();
    this.modelCustomizationDividendProtectionModelId = v4();
    this.modelCustomizationPEPSParityConventionId = v4();
    this.modelCustomizationExpectedLifeExcludingDefaultId = v4();
    this.modelCustomizationDefaultOnLowParityId = v4();
    this.modelCustomizationExchangeableAsConvertibleId = v4();
    this.modelCustomizationIncludeCashRebateInParityId = v4();
    this.modelCustomizationForceOptionalResetId = v4();
    this.modelCustomizationApplyConversionAveragingId = v4();
    this.modelCustomizationNotCallableDateId = v4();
    this.modelCustomizationNotCallableParityId = v4();
    this.modelCustomizationMaturityDateOverride = v4();
    this.modelCustomizationFwdDividProtectioAdjstId = v4();
    this.modelCustomizationIncludeTermsId = v4();
    this.modelCustomizationForwardConvAveragingId = v4();
    this.modelCustomizationCurrentResetAveragingId = v4();
    this.modelCustomizationForwardResetAveragingId = v4();
    this.modelCustomizationConversionNoticeGivenId = v4();
    this.modelCustomizationAssumeDvdUntilMaturityId = v4();
    this.modelCustomizationPricerVersionId = v4();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.modelCustomizationDialogScreenDm = 'DM-4438';
    if(!this.isConvertibleBond) {
      this.modelCustomizationDialogScreenDm = 'DM-5450';
    }
  }

  /**
   * Closes dialog.
   */
  onCancel() {
    this.dialogRef.close();
  }

  /**
   * Saves model customization state.
   */
  onSave() {
    this.isLoading = true;
    Object.keys(this._modelCustomizationState).forEach(key => {
      this._originalModelCustomizationState[key] = this._modelCustomizationState[key];
    });
    this.didSave.emit(this._originalModelCustomizationState);
    this.isLoading = false;
    this.dialogRef.close();
  }

  /**
   * Occurs on exclude terms change and sets subsection fields state.
   */
  onExcludeTermsChange() {
    this.setSubsectionFieldsState(
      this.modelCustomizationState.excludeTerms,
      this.modelCustomizationState.excludeTerms.isExcludeTerms
    );
  }

  /**
   * Occurs on include terms change and sets subsection fields state.
   */
  onIncludeTermsChange() {
    this.setSubsectionFieldsState(
      (this.modelCustomizationState as IModelCustomizationState).includeTerms,
      (this.modelCustomizationState as IModelCustomizationState).includeTerms?.isIncludeTerms
    );
  }

  /**
   * Occurs on model type change and sets subsection fields state.
   */
  onModelTypeChange() {
    this.setSubsectionFieldsState(
      this.modelCustomizationState.modelType,
      this.modelCustomizationState.modelType.isModelType
    );
  }

  /**
   * Occurs on call adjustment change and sets subsection fields state.
   */
  onCallAdjustmentChange() {
    this.setSubsectionFieldsState(
      this.modelCustomizationState.callAdjustments,
      this.modelCustomizationState.callAdjustments.isCallAdjustments
    );
  }

  /**
   * Occurs on other parameters change and sets subsection fields state.
   */
  onOtherParametersChange() {
    this.setSubsectionFieldsState(
      this.modelCustomizationState.otherParameters,
      this.modelCustomizationState.otherParameters.isOtherParameters
    );
  }

  /**
   * Occurs on check changes.
   */
  onCheckChange() {
    this.checkGroupCheckState(this.modelCustomizationState.excludeTerms, 'isExcludeTerms');
    if (this.isConvertibleBond) {
      this.checkGroupCheckState((this.modelCustomizationState as IModelCustomizationState)?.includeTerms, 'isIncludeTerms');
    }
    this.checkGroupCheckState(this.modelCustomizationState.modelType, 'isModelType');
    this.checkGroupCheckState(this.modelCustomizationState.callAdjustments, 'isCallAdjustments');
    this.checkGroupCheckState(this.modelCustomizationState.otherParameters, 'isOtherParameters');
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Sets subsection fields state.
   * @param sectionState Section state.
   * @param status Status.
   */
  private setSubsectionFieldsState(sectionState: any, status: boolean) {
    Object.keys(sectionState).forEach(key => {
      sectionState[key] = status;
    });
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Checks group and state.
   * @param group Group.
   * @param groupPropertyName Group property name.
   */
  private checkGroupCheckState(group: any, groupPropertyName: string) {
    const isAnyGroupItemChecked = Object.keys(group)
      .filter(a => a !== groupPropertyName)
      .filter(a => group[a])
      .length !== 0;

    group[groupPropertyName] = isAnyGroupItemChecked;
  }
}
